import { Adventure, Script, ScriptItem, Image, FAQ, Package, ScheduleRule, RequestCustomField, ReserveCustomField, AdventureBonus, EmailAutomation, NoteAutomation } from '../../models';
import { createNewScript, 
    createNewScriptItem, 
    createAdventure,
    createImage,
    createPackage,
    createNewFAQ,
    createScheduleRule,
    createRequestCustomField,
    createReserveCustomField 
} from '../actions'
import { all, put, select } from 'redux-saga/effects';
import { createAdventureBonusEntity } from '../entities/adventureBonus/actions';
import { createEmailAutomationEntity } from '../entities/emailAutomation/actions';
import { createNoteAutomationEntity } from '../entities/noteAutomation/actions';

/**
 * Função par normalizar aventura disparando actions dentro da saga
 * @param {Object} adv json com os dados da aventura não normalizados
 */
export function* normalizeAdventureOnSaga(adv) {
    const adv_m = new Adventure(adv);

    // Normaliza roteiro
    const scriptsIds = []
    yield all(adv_m.scripts.map(function*(script){
        const script_m = new Script(script)
        script_m.adventure_id = adv_m.id

        const scriptItemsIds = []
        yield all(script.items.map(function*(item){
            const item_m = new ScriptItem(item)
            item_m.script_id = script_m.id

            yield put(createNewScriptItem(item_m))
            scriptItemsIds.push(item_m.id)
        }))
        script_m.items = scriptItemsIds

        yield put(createNewScript(script_m))
        scriptsIds.push(script_m.id)
    }))
    adv_m.scripts = scriptsIds

    // Normaliza FAQ
    const FAQIds = []
    yield all(adv_m.FAQ.map(function*(question){
        const faq_m = new FAQ(question)
        faq_m.adventure_id = adv_m.id

        yield put(createNewFAQ(faq_m))
        FAQIds.push(faq_m.id)
    }))
    adv_m.FAQ = FAQIds

    // Normaliza bonus
    const bonusIds = []
    yield all(adv_m.adventureBonuses.map(function*(bonus){
        const bonus_m = new AdventureBonus(bonus)
        bonus_m.adventure_id = adv_m.id

        yield put(createAdventureBonusEntity(bonus_m))
        bonusIds.push(bonus_m.id)
    }))
    adv_m.adventureBonuses = bonusIds

    // Normaliza emails automaticos
    const emailAutomationIds = []
    yield all(adv_m.emailAutomations.map(function*(automation){
        const automation_m = new EmailAutomation(automation)
        automation_m.adventure_id = adv_m.id

        yield put(createEmailAutomationEntity(automation_m))
        emailAutomationIds.push(automation_m.id)
    }))
    adv_m.emailAutomations = emailAutomationIds

    // Normaliza notas automaticas
    const noteAutomationIds = []
    yield all(adv_m.noteAutomations.map(function*(automation){
        const automation_m = new NoteAutomation(automation)
        automation_m.adventure_id = adv_m.id

        yield put(createNoteAutomationEntity(automation_m))
        noteAutomationIds.push(automation_m.id)
    }))
    adv_m.noteAutomations = noteAutomationIds

    // Normaliza thumbnail
    if (adv.image) {
        const image = new Image(adv.image)
        image.adventure_id = adv_m.id
        yield put(createImage(image))
        adv_m.image = image.id
    }

    // Normaliza banner
    if (adv.banner) {
        const banner = new Image(adv.banner)
        banner.adventure_id = adv_m.id
        yield put(createImage(banner))
        adv_m.banner = banner.id
    }

    // Normaliza pacotes
    const packagesIds = []
    yield all(adv_m.packages.map(function*(pack){
        const pack_m = new Package(pack)
        pack_m.adventure_id = adv_m.id

        yield put(createPackage(pack_m))
        packagesIds.push(pack_m.id)
    }))
    adv_m.packages = packagesIds

    // Normaliza horarios da agenda
    const scheduleRulesIds = []
    yield all(adv_m.scheduleRules.map(function*(rule){
        const packages = yield select(state => state.entities.package)
        const packages_ids = rule.avaiablePackages.map(pack => packagesIds.find(id => packages.byId[id].pk_package === pack.pk_package))

        const rule_m = new ScheduleRule(rule)
        rule_m.adventure_id = adv_m.id
        rule_m.avaiablePackages = packages_ids.map(id => ({ ...packages.byId[id] }))
        
        yield put(createScheduleRule(rule_m))
        scheduleRulesIds.push(rule_m.id)
    }))
    adv_m.scheduleRules = scheduleRulesIds

    // Normaliza campos adicionais do evento
    const requestCustomFieldsIds = []
    yield all(adv_m.requestCustomFields.map(function*(rqcf){
        const rqcf_m = new RequestCustomField(rqcf)
        rqcf_m.adventure_id = adv_m.id

        yield put(createRequestCustomField(rqcf_m))
        requestCustomFieldsIds.push(rqcf_m.id)
    }))
    adv_m.requestCustomFields = requestCustomFieldsIds

    // Normaliza campos adicionais da reserva
    const reserveCustomFieldsIds = []
    yield all(adv_m.reserveCustomFields.map(function*(rscf){
        const rscf_m = new ReserveCustomField(rscf)
        rscf_m.adventure_id = adv_m.id

        yield put(createReserveCustomField(rscf_m))
        reserveCustomFieldsIds.push(rscf_m.id)
    }))
    adv_m.reserveCustomFields = reserveCustomFieldsIds

    // Normaliza galeria
    const imagesIds = []
    yield all(adv_m.images.map(function*(image){
        const image_m = new Image(image)
        image_m.adventure_id = adv_m.id

        yield put(createImage(image_m))
        imagesIds.push(image_m.id)
    }))
    adv_m.images = imagesIds

    adv_m.tags = adv_m.tags.map(tag => ({ value: tag.fk_tag }) )
    
    // Cria aventura
    yield put(createAdventure(adv_m))
    return adv_m
}