import { v4 as uuid4 } from 'uuid'

export class Payment {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_payment = json.pk_payment || null

        this.payment_status = json.payment_status || ''
        this.payment_id = json.payment_id || ''
        this.method = json.method || ''
        this.price = json.price || ''
    }
}

export const PAYMENT_LABELS = {
    STATUS: {
        AUTHORIZED: 'Confirmado',
        PAID: 'Confirmado',
        CONFIRMED: "Confirmado",
        SETTLED: "Confirmado",
        PENDING: "Aguardando",
        FREE: "Aguardando",
        WAITING: "Aguardando",
        CANCELLED: "Cancelado",
        CUSTOMER_PAID_BACK: "Estornado",
    },
    METHODS: {
        CREDIT_CARD: "Cartão de crédito",
        BOLETO: "Boleto",
        LOCAL: "Pagamento direto",
        MERCADO_PAGO: "Mercado Pago",
        PAGSEGURO: "PagSeguro",
        GETNET: "GetNet",
        DEPOSIT: "Depóstio bancário",
        PIX: "PIX"
    }
}