export const FETCH_FINANCES_BALANCES               = 'FETCH_FINANCES_BALANCES';
export const FETCH_FINANCES_BALANCES_SUCCESS       = 'FETCH_FINANCES_BALANCES_SUCCESS';
export const FETCH_FINANCES_BALANCES_ERROR         = 'FETCH_FINANCES_BALANCES_ERROR';

export const FETCH_FINANCES_EXTRACT_MONTH          = 'FETCH_FINANCES_EXTRACT_MONTH';
export const FETCH_FINANCES_EXTRACT_MONTH_SUCCESS  = 'FETCH_FINANCES_EXTRACT_MONTH_SUCCESS';
export const FETCH_FINANCES_EXTRACT_MONTH_ERROR    = 'FETCH_FINANCES_EXTRACT_MONTH_ERROR';

export const FETCH_FINANCES_FUTURE_EXTRACT          = 'FETCH_FINANCES_FUTURE_EXTRACT';
export const FETCH_FINANCES_FUTURE_EXTRACT_SUCCESS  = 'FETCH_FINANCES_FUTURE_EXTRACT_SUCCESS';
export const FETCH_FINANCES_FUTURE_EXTRACT_ERROR    = 'FETCH_FINANCES_FUTURE_EXTRACT_ERROR';

export const FETCH_FINANCES_TRANSFER               = 'FETCH_FINANCES_TRANSFER';
export const FETCH_FINANCES_TRANSFER_SUCCESS       = 'FETCH_FINANCES_TRANSFER_SUCCESS';
export const FETCH_FINANCES_TRANSFER_ERROR         = 'FETCH_FINANCES_TRANSFER_ERROR';

export const CLEAR_TRANSFERENCE                    = 'CLEAR_TRANSFERENCE';