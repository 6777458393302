import { v4 as uuid4 } from 'uuid'

export class RequestCustomField {
    constructor(json = {}) {
        this.id = json.id || uuid4()
        this.pk_request_custom_fields = json.pk_request_custom_fields || null
        this.fk_adventure = json.fk_adventure || null
        this.adventure_id = json.adventure_id || null
        this.title = json.title || ''
        this.type = json.type || ''
        this.options = json.options || ''
        this.icon = json.icon || ''
    }
}

export const requestCustomFieldsTypes = [
    { value: 'text', label: 'Texto' }, { value: 'date', label: 'Data' }, { value: 'select', label: 'Seleção' }, { value: 'number', label: 'Númerico' }, { value: 'phone', label: 'Telefone' }
]