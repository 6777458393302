import { v4 as uuuid4 } from 'uuid'

export class NoteAutomation {
    constructor(json = {}) {
        this.id = json.id || uuuid4()
        this.adventure_id = json.adventure_id || null
        this.pk_automation = json.pk_automation || null
        this.fk_adventure = json.fk_adventure || null
        this.fk_company = json.fk_company || null
        this.title = json.title || ""
        this.description = json.description || ""
        this.interval = json.interval || 0
        this.is_before_event = json.is_before_event || false
        // this.request_info = json.request_info || false
        this.request_info = true
    }
}