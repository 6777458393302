import {
    CREATE_PACKAGE,
    UPDATE_PACKAGE,
    DELETE_PACKAGE
} from '../../constants'
import { Package } from '../../../models'


export const createPackage = data => ({
    type: CREATE_PACKAGE,
    payload: new Package(data)
})

export const updatePackage = data => ({
    type: UPDATE_PACKAGE,
    payload: data
})

export const deletePackage = id => ({
    type: DELETE_PACKAGE,
    payload: { id }
})