import { v4 as uuid4 } from 'uuid';
import { Script, ScriptItem, FAQ } from './index'
import { Package } from './package';
import { ScheduleRule } from './scheduleRule';
import { Image } from './image';
import { RequestCustomField } from './requestCustomField';
import { ReserveCustomField } from './reserveCustomField';
import { Tag } from './tag';
import { CANCEL_RULES_TYPES } from '../redux/constants';
import { getRandomColorFromNumber } from '../helpers/utils';
import moment from 'moment';
import { AdventureBonus } from './adventureBonus';
import { EmailAutomation } from './emailAutomation';
import { NoteAutomation } from './noteAutomation';

export class Adventure {
    constructor(json = {}){
        this.id = json.id || uuid4();
        this.pk_adventure = json.pk_adventure || null
        this.color = json.color ? json.color : getRandomColorFromNumber(json.pk_adventure)
        this.image = json.image || ''
        this.banner = json.banner || ''
        this.title = json.title || ''
        this.description = json.description || ''
        this.price = json.price || ''
        this.duration = json.duration || '00:00'
        this.long_description = json.long_description || ''
        this.min = json.min || ''
        this.max = json.max || ''
        this.url_name = json.url_name || ''
        this.lat = json.lat || ''
        this.lng = json.lng || ''
        this.street = json.street || ''
        this.number = json.number || ''
        this.complement = json.complement || ''
        this.city = json.city || ''
        this.neighborhood = json.neighborhood || ''
        this.cep = json.cep || ''
        this.state = json.state || ''
        this.formatted_address = `${this.street}${this.number ? "," + this.number : ""} - ${this.neighborhood}, ${this.city} - ${this.state}, ${this.cep}, ${this.country}`
        this.fk_company = json.fk_company || ''
        this.only_public = json.only_public === undefined ? true : json.only_public 
        this.status = json.status || ''
        this.online_scheduling = json.online_scheduling === undefined ? true : json.online_scheduling
        this.same_locale_and_meeting_point = json.same_locale_and_meeting_point === null ? true : !!json.same_locale_and_meeting_point
        this.meeting_point_lat = json.meeting_point_lat || ''
        this.meeting_point_lng = json.meeting_point_lng || ''
        this.meeting_point_cep = json.meeting_point_cep || ''
        this.meeting_point_complement = json.meeting_point_complement || ''
        this.meeting_point_street = json.meeting_point_street || ''
        this.meeting_point_number = json.meeting_point_number || ''
        this.meeting_point_city = json.meeting_point_city || ''
        this.meeting_point_state = json.meeting_point_state || ''
        this.meeting_point_neighborhood = json.meeting_point_neighborhood || ''
        this.meeting_point_district = json.meeting_point_district || ''
        this.meeting_point_formatted_address = `${this.meeting_point_street}${this.meeting_point_number ? "," + this.meeting_point_number : ""} - ${this.meeting_point_neighborhood}, ${this.meeting_point_city} - ${this.meeting_point_state}, ${this.meeting_point_cep}, ${this.meeting_point_country}`
        this.google_calendar_block_before = json.google_calendar_block_before || 0
        this.google_calendar_block_after = json.google_calendar_block_after || 0
        this.block_request_hours_before = json.block_request_hours_before === null || json.block_request_hours_before === undefined ? 72 : json.block_request_hours_before
        this.block_participation_hours_before = json.block_participation_hours_before === null || json.block_participation_hours_before === undefined ? 72 : json.block_participation_hours_before
        this.google_calendar_id = json.google_calendar_id || ''
        this.fk_category = json.fk_category || null
        this.fk_region = json.fk_region || null
        this.voucher_enabled = json.voucher_enabled || false
        this.terms = json.terms || ''
        this.termsFile = json.termsFile
        this.voucher_expiration_days = json.voucher_expiration_days || 90
        this.roca_carta = json.roca_carta || ""
        this.reserve_roca_enabled = json.reserve_roca_enabled || false
        this.reserve_observation_enabled = json.reserve_observation_enabled || false
        this.request_observation_enabled = json.request_observation_enabled || false
        this.local_payment_enabled = json.local_payment_enabled || false
        this.local_payment_title = json.local_payment_title || ''
        this.local_payment_description = json.local_payment_description || ''
        this.local_payment_block_days = json.local_payment_block_days || 0
        this.local_payment_block_message = json.local_payment_block_message || ''
        this.credit_card_enabled = json.credit_card_enabled === undefined ? true : json.credit_card_enabled
        this.credit_card_block_days = json.credit_card_block_days || 0
        this.credit_card_block_message = json.credit_card_block_message || ''
        this.boleto_enabled = json.boleto_enabled === undefined ? false : json.boleto_enabled
        this.boleto_block_days = json.boleto_block_days || 0
        this.boleto_block_message = json.boleto_block_message || ''
        this.pix_enabled = json.pix_enabled === undefined ? false : json.pix_enabled
        this.picpay_enabled = json.picpay_enabled === undefined ? false : json.picpay_enabled
        this.picpay_block_days = json.picpay_block_days || 0
        this.picpay_block_message = json.picpay_block_message || ''
        this.pagseguro_enabled = json.pagseguro_enabled === undefined ? false : json.pagseguro_enabled
        this.pagseguro_block_days = json.pagseguro_block_days || 0
        this.pagseguro_block_message = json.pagseguro_block_message || ''
        this.mercado_pago_enabled = json.mercado_pago_enabled === undefined ? false : json.mercado_pago_enabled
        this.mercado_pago_block_days = json.mercado_pago_block_days || 0
        this.mercado_pago_block_message = json.mercado_pago_block_message || ''
        this.getnet_enabled = json.getnet_enabled || false
        this.cancel_rules = json.cancel_rules || CANCEL_RULES_TYPES.COMPLETE
        this.fill_all_reserves = json.fill_all_reserves || false
        this.video = json.video || ''
        this.tags = json.tags || []
        this.scripts = json.scripts || []
        this.FAQ = json.FAQ || []
        this.adventureBonuses = json.adventureBonuses || []
        this.packages = json.packages || []
        this.scheduleRules = json.scheduleRules || []
        this.images = json.images || []
        this.requestCustomFields = json.requestCustomFields || []
        this.reserveCustomFields = json.reserveCustomFields || []
        this.sell_type = json.sell_type || ''
        this.voucher_terms = json.voucher_terms || ''
        this.request_success_message = json.request_success_message || ''
        this.request_email_message = json.request_email_message || ''
        this.reserve_success_message = json.reserve_success_message || ''
        this.reserve_email_message = json.reserve_email_message || ''
        this.pre_event_message = json.pre_event_message || ''
        this.post_event_message = json.post_event_message || ''
        this.emailAutomations = json.emailAutomations || []
        this.noteAutomations = json.noteAutomations || []
        this.terms_on_request = json.terms_on_request || false
        this.terms_on_reserve = json.terms_on_reserve || false
        this.payment_on_request = json.payment_on_request || false
        this.fk_package_for_request = json.fk_package_for_request || null
        this.use_schedule_rule_packages = json.use_schedule_rule_packages || false
        this.request_payment_max = Number(json.request_payment_max) || ""
        this.request_payment_min = Number(json.request_payment_min) || ""
        this.requests = json.requests || []
    }
}

/**
 * Função para transforma uma aventura normalizada em um objeto completo
 * @param {Object} adv Entity normalizada
 * @param {Object} entities Store com as entitys
 */
export function denormalizeAdventure(adv, entities){
    adv.image = !!adv.image ? entities.image.byId[adv.image] : ''
    adv.banner = !!adv.banner ? entities.image.byId[adv.banner] : ''
    adv.scripts = adv.scripts.map(script_id => {
        const script = new Script(entities.script.byId[script_id])
        script.items = script.items.map(item_id => new ScriptItem(entities.scriptItem.byId[item_id]))
        return script
    })
    adv.FAQ = adv.FAQ.map(faq_id => new FAQ(entities.FAQ.byId[faq_id]))
    adv.adventureBonuses = adv.adventureBonuses.map(bonus_id => new AdventureBonus(entities.adventureBonus.byId[bonus_id]))
    adv.packages = adv.packages.map(pack_id => new Package(entities.package.byId[pack_id]))
    adv.scheduleRules = adv.scheduleRules.map(srId => new ScheduleRule(entities.scheduleRule.byId[srId]))
    adv.images = adv.images.map(imgId => new Image(entities.image.byId[imgId]))
    adv.requestCustomFields = adv.requestCustomFields.map(imgId => new RequestCustomField(entities.requestCustomField.byId[imgId]))
    adv.reserveCustomFields = adv.reserveCustomFields.map(imgId => new ReserveCustomField(entities.reserveCustomField.byId[imgId])).filter(f => f.is_visible)
    adv.emailAutomations = adv.emailAutomations.map(autId => new EmailAutomation(entities.emailAutomation.byId[autId]))
    adv.noteAutomations = adv.noteAutomations.map(autId => new NoteAutomation(entities.noteAutomation.byId[autId]))
    adv.tags = adv.tags.map(tagId => new Tag(entities.tag.byId[tagId]))

    if(adv.start_date){
        adv.start_date = adv.start_date
    } else if (adv.scheduleRules[0] && adv.scheduleRules[0].id) {
        adv.start_date = moment(adv.scheduleRules[0].start_date).format("YYYY-MM-DD")
    }

    if (adv.start_hour) {
        adv.start_hour = adv.start_hour
    } else if (adv.scheduleRules[0] && adv.scheduleRules[0].id) {
        adv.start_hour = adv.scheduleRules[0].hour
    }

    if (adv.weekdays) {
        adv.weekdays = adv.weekdays
    } else if (adv.scheduleRules[0] && adv.scheduleRules[0].id) {
        adv.weekdays = adv.scheduleRules[0].weekdays
    }

    return adv
}

/**
 * Função para normalizar de volta a aventura antes de enviar pra store
 * @param {Object} adv Aventura não normalizada
 */
export function normalizeAdventure(adv){
    adv.image = !!adv.image ? adv.image.id : ''
    adv.banner = !!adv.banner ? adv.banner.id : ''
    adv.scripts = adv.scripts.map(script => script.id)
    adv.FAQ = adv.FAQ.map(faq => faq.id)
    adv.adventureBonuses = adv.adventureBonuses.map(bonus => bonus.id)
    adv.packages = adv.packages.map(pack => pack.id)
    adv.scheduleRules = adv.scheduleRules.map(rule => rule.id)
    adv.images = adv.images.map(img => img.id)
    adv.requestCustomFields = adv.requestCustomFields.map(rqcf => rqcf.id)
    adv.reserveCustomFields = adv.reserveCustomFields.map(rscf => rscf.id)
    adv.emailAutomations = adv.emailAutomations.map(automation => automation.id)
    adv.noteAutomations = adv.noteAutomations.map(automation => automation.id)
    adv.tags = adv.tags.map(tag => tag.id)
    return { ...adv }
}

export const adventureTypes = [
    { value: 'voucher', label: 'Voucher' },
    { value: 'event', label: 'Evento' },
    { value: 'trip', label: 'Viagem de experiência' },
    { value: 'daily', label: 'Atividades diárias' },
    { value: 'schedule', label: 'Atividades com vários horários' }
]