import {
    CREATE_NEW_CATEGORY
} from '../../constants'
import { combineReducers } from 'redux'
import { LOGOUT_USER } from '../../auth/constants'
import { REFRESH_STORE } from "../../app/constants";

export function categoryById(state = {}, action) {
    switch (action.type) {
        case CREATE_NEW_CATEGORY:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function categoryAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_NEW_CATEGORY:
            return [ ...state, action.payload.id ]
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}

export default combineReducers({
    byId: categoryById,
    allIds: categoryAllIds
})