import ReservationService from './service'
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { v4 as uuid4 } from 'uuid'
import {
    FETCH_SCHEDULE, 
    FETCH_REQUESTS_FOR_DAY, 
    CREATE_REQUEST, 
    CANCEL_REQUEST, 
    CANCEL_RESERVE, 
    SELECT_REQUEST,
    CHANGE_REQUEST_DATE,
    LOAD_CALENDAR,
    RELOAD_REQUESTS,
    FETCH_REQUESTS_FOR_MONTH,
    CHANGE_REQUEST_INFO,
    CHANGE_RESERVE_INFO,
    SEND_EMAIL,
    UPDATE_REQUEST_TAGS,
    UPDATE_RESERVE_TAGS,
    CHECK_IN_RESERVE,
    FETCH_GOOGLE_EVENTS,
    CHANGE_RESERVE_REQUEST,
    SAVE_REQUEST_BONUSES,
    SAVE_REQUEST_MESSAGES,
    FETCH_AVAIABLE_REQUESTS,
    CREATE_SINGLE_SCHEDULE_RULE,
    UPDATE_SINGLE_SCHEDULE_RULE,
    DELETE_SINGLE_SCHEDULE_RULE,
    SAVE_REQUEST_PACKAGES,
    FETCH_REQUESTS_FOR_DAY_SUCCESS,
    CONFIRM_RESERVE_PAYMENT,
    CHANGE_RESERVE_EXPIRATION
} from './constants'

import {
    fetchRequestsForDaySuccess,
    fetchRequestsForDayError,
    fetchScheduleSuccess,
    fetchScheduleError,
    createRequestSuccess,
    createRequestError,
    cancelRequestSuccess,
    cancelRequestError,
    cancelReserveSuccess,
    cancelReserveError,
    changeRequestDateSuccess,
    changeRequestDateError,
    selectRequest,
    calendarLoaded,
    fetchRequestsForDay,
    fetchRequestsForMonthError,
    fetchRequestsForMonthSuccess,
    fetchRequestsForMonth,
    changeRequestInfoSuccess,
    changeRequestInfoError,
    changeReserveInfoSuccess,
    changeReserveInfoError,
    sendEmailSuccess,
    sendEmailError,
    updateRequestTagsSuccess,
    updateRequestTagsError,
    updateReserveTagsSuccess,
    updateReserveTagsError,
    checkInReserveSuccess,
    checkInReserveError,
    fetchGoogleEventsSuccess,
    fetchGoogleEventsError,
    fetchGoogleEvents,
    changeReserveRequestError,
    changeReserveRequestSuccess, 
    fetchSchedule,
    saveRequestBonusesSuccess,
    saveRequestBonusesError,
    saveRequestMessagesSuccess,
    saveRequestMessagesError,
    fetchAvaiableRequestsSuccess,
    fetchAvaiableRequestsError,
    loadAdventuresForScheduleSuccess,
    loadAdventuresForScheduleError,
    createSingleScheduleRuleSuccess,
    createSingleScheduleRuleError,
    updateSingleScheduleRuleSuccess,
    updateSingleScheduleRuleError,
    deleteSingleScheduleRuleSuccess,
    deleteSingleScheduleRuleError,
    saveRequestPackagesSuccess,
    saveRequestPackagesError,
    loadReservesForRequestsSuccess,
    loadReservesForRequestError,
    loadReservesForRequestSuccess,
    confirmReservePaymentError,
    confirmReservePaymentSuccess,
    changeReserveExpirationSuccess,
    changeReserveExpirationError
} from './actions'
import moment from 'moment'
import { history } from '../../routes';
import { normalizeRequestOnSaga, normalizeScheduleRuleOnSaga, normalizeReserveOnSaga } from './utils';
import { batchCreateReserveEntity, deleteReserveEntity, updateReserveEntity } from '../entities/reserve/actions';
import { getCompanyAdventures } from '../adventures/saga';
import { updateRequestEntity, deleteRequestEntity } from '../entities/request/actions';
import { fetchNotesDate } from '../notes/actions';
import { ScheduleRule } from '../../models';

const selectAllRequests = (state) => state.entities.request.allIds.map(id => state.entities.request.byId[id])

/**
 * Função para consultar os próximos eventos da Empresa
 * A identificação da empresa é feita através do usuário logado
 */
export function* getRequests({ payload }){
    const { date } = payload
    const res = yield call(ReservationService.getActiveRequests, { date, viewType: 'day' })

    if (res.status === 200) {
        const requests_ids = []

        yield all(res.data.map(function*(json){
            const request_m = yield call(normalizeRequestOnSaga, json)
            requests_ids.push(request_m.id)
        }))

        yield put(fetchRequestsForDaySuccess(requests_ids))
    } else {
        yield put(fetchRequestsForDayError(res))
    }
}

export function* loadReservesForRequestsSaga({ payload }) {
    const { entities } = yield select(state => state)
    const requests = payload.map(id => ({ ...entities.request.byId[id] }) )

    yield all(requests.map(function*(model){
        yield call(getReservesForRequestSaga, model)
    }))

    yield put(loadReservesForRequestsSuccess(payload))
}

export function* getReservesForRequestSaga({ pk_request, id }){
    const res = yield call(ReservationService.getReservesForRequest, { pk_request })

    if (res.status !== 200 ) {
        yield put(loadReservesForRequestError(id))
    }
    const reserves_id = []
    
    // Para cada reserve normaliza ela
    const reserves_m = yield all(res.data.map(function*(reserve){
        const reserve_m = yield call(normalizeReserveOnSaga, { ...reserve, request_id: id }, false)
        reserves_id.push(reserve_m.id)
        return reserve_m
        
    }))

    yield put(batchCreateReserveEntity(reserves_m))
    yield put(updateRequestEntity({ id, reserves: reserves_id }))
    yield put(loadReservesForRequestSuccess(id))
}


export function* mapGoogleEventsWithAdventure(json) {
    const { adventures } = yield select(state => state.Reservation)
    const arrays = yield all(Object.keys(json).map((pk_adventure) => {
        const adventure = adventures[pk_adventure]
        return json[pk_adventure].map(event => ({ ...event, adventure })) 
    }))
    return arrays.reduce((allArrs,curArr) => [ ...allArrs, ...curArr ], []);
}

export function* getGoogleEventsSaga({ payload }){
    const { date } = payload
    const res = yield call(ReservationService.getGoogleEvents, { date })
    const events = yield call(mapGoogleEventsWithAdventure, res.data)
    if (res.status === 200) {
        yield put(fetchGoogleEventsSuccess(events))
    } else {
        yield put(fetchGoogleEventsError(res))
    }

}

export function* getMonthRequestsPreview({ payload }){
    const { date, preview } = payload
    const res = yield call(ReservationService.getActiveRequests, { date, preview, viewType: 'month' })
    yield put(fetchGoogleEvents(date))

    if (res.status === 200) {
        yield put(fetchRequestsForMonthSuccess(res.data.map(item => ({ ...item, id: uuid4() }))))
    } else {
        yield put(fetchRequestsForMonthError(res))
    }

}

export function* fetchAvaiableRequestsSaga(){
    const res = yield call(ReservationService.getAllAvaiableRequests)

    if (res.status === 200) {
        yield put(fetchAvaiableRequestsSuccess(res.data))
    } else {
        yield put(fetchAvaiableRequestsError(res))
    }
}

/**
 * Consulta calendario de uma aventura para o mês
 */
export function* getSchedule(){
    // const res = yield call(ReservationService.getActiveSchedules)

    // if (res.status === 200) {
    //     // const rules_ids = []

    //     // yield all(res.data.map(function*(obj){
    //     //     const rule_m = yield call(normalizeScheduleRuleOnSaga, obj)
    //     //     rules_ids.push(rule_m.id)
    //     // }));

    //     yield put(fetchScheduleSuccess(res.data.map(rule => new ScheduleRule(rule))))
    // } else {
    //     yield put(fetchScheduleError(res))
    // }
}

/**
 * Saga para criar agendamentos e ingressos dentro do agendamento
 * @param {Object} action 
 */
export function* createRequestSaga({ payload }) {
    // separa redirect do restante do payload
    const { noRedirect = false, ...payloadData } = payload
    // Pega todos os agendamentos na store
    const requests = yield select(selectAllRequests)
    // Cria a nova reserva
    const res = yield call(ReservationService.createRequest, payloadData)
    if (res.status === 200) {
        // Verifica se o agendamento ja existe
        // se ja existe, cria as reservas. Do contrario cria o agendamento inteiro
        const createdRequest = requests.find(req => req.pk_request === res.data.pk_request)
        if (createdRequest) {
            const reserves_ids = []
            
            yield all(res.data.reserves.map(function*(reserve){
                const reserve_m = yield call(normalizeReserveOnSaga, { ...reserve, request_id: createdRequest.id })
                reserves_ids.push(reserve_m.id)
            }))

            yield put(updateRequestEntity({ ...createdRequest, reserves: reserves_ids, packages: res.data.packages }))
            yield put(createRequestSuccess(createdRequest.id))
        } else {
            const request_m = yield call(normalizeRequestOnSaga,res.data)
            yield put(createRequestSuccess(request_m.id))
            if(!noRedirect)
                yield put(selectRequest(request_m.pk_request))
        }
    } else {
        yield put(createRequestError(res))
    }
}

export function* cancelReserveSaga({ payload }) {
    if(!Array.isArray(payload))
        payload = [payload]

    const res = yield call(ReservationService.cancelReserve, payload.map(item => item.pk_reserve))
    if (res.status === 200) {
        for (let i = 0; i < payload.length; i++) {
            const element = payload[i];
            yield put(deleteReserveEntity(element.id))
        }
        
        yield put(cancelReserveSuccess())
    } else {
        yield put(cancelReserveError(res))
    }
}

export function* selectRequestSaga({ payload: { id } }) {
    const { entities } = yield select(store => store)
    const requestId = entities.request.allIds.find(curId => Number(entities.request.byId[curId].pk_request) === Number(id))
    if (!requestId) {
        yield put(selectRequest(null))
        yield call(history.push, '/schedule/events')
    } else {
        if (history.location.pathname !== `/schedule/events/${id}`) {
            yield call(history.push, `/schedule/events/${id}`)
        }
    }
}

export function* cancelRequest({ payload }) {
    const res = yield call(ReservationService.cancelRequest, payload);
    if(res.status === 200) {
        yield call(history.push, '/schedule/events')
        yield put(deleteRequestEntity(payload.request.id))
        yield put(cancelRequestSuccess());
    }
    else {
        yield put(cancelRequestError(res));
    }
}

export function* changeRequestDate({ payload }) {
    const res = yield call(ReservationService.changeRequestDate, payload);
    if (res.status === 200) {
        yield put(updateRequestEntity({ id: payload.request.id , date: payload.date }))
        yield put(changeRequestDateSuccess());
    } else {
        yield put(changeRequestDateError(res));
    }
}

export function* changeRequestInfoSaga({ payload }) {
    const res = yield call(ReservationService.changeRequestInfo, payload)
    if (res.status === 200) {
        yield put(updateRequestEntity({ ...payload }))
        yield put(changeRequestInfoSuccess())
    } else {
        yield put(changeRequestInfoError(res))
    }
}

export function* changeReserveInfoSaga({ payload }) {
    const res = yield call(ReservationService.changeReserveInfo, payload)
    if (res.status === 200) {
        yield put(updateReserveEntity({ ...payload }))
        yield put(changeReserveInfoSuccess())
    } else {
        yield put(changeReserveInfoError(res))
    }
}

export function* loadAdventuresForSchedule() {
    const res = yield call(ReservationService.loadAdventuresForSchedule)
    if (res.status === 200) {
        yield put(
            fetchScheduleSuccess(
                res.data.reduce((prevRules, adv) => [ ...prevRules, ...adv.scheduleRules.map(item => new ScheduleRule(item)) ], [])    
            )
        )

        yield put(loadAdventuresForScheduleSuccess(res.data))
    } else {
        yield put(loadAdventuresForScheduleError(res))
    }
}

export function* sagaCreateScheduleRule({ payload }) {
    const res = yield call(ReservationService.createScheduleRule, payload)
    if (res.status === 200) {
        const adventure = yield call(ReservationService.loadAdventuresForSchedule, { pk_adventure: payload.data.fk_adventure })
        yield put(createSingleScheduleRuleSuccess(adventure.data))
    } else {
        yield put(createSingleScheduleRuleError(res))
    }
}

export function* sagaUpdateScheduleRule({ payload }) {
    const res = yield call(ReservationService.updateScheduleRule, payload)
    if (res.status === 200) {
        const adventure = yield call(ReservationService.loadAdventuresForSchedule, { pk_adventure: payload.data.fk_adventure })
        yield put(updateSingleScheduleRuleSuccess(adventure.data))
    } else {
        yield put(updateSingleScheduleRuleError(res))
    }
}
export function* sagaDeleteScheduleRule({ payload }) {
    const res = yield call(ReservationService.deleteScheduleRule, payload)
    if (res.status === 200) {
        const adventure = yield call(ReservationService.loadAdventuresForSchedule, { pk_adventure: payload.data.fk_adventure })
        yield put(deleteSingleScheduleRuleSuccess(adventure.data))
    } else {
        yield put(deleteSingleScheduleRuleError(res))
    }
}



export function* loadCalendar() {
    const state = yield select()
    //if(!state.Adventures.adventures.length && !state.Adventures.loadingAdventures)
    //    yield call(getCompanyAdventures)

    yield call(loadAdventuresForSchedule)
    
    // if(!state.Reservation.schedule.length)
    //     yield put(fetchSchedule())
        
    if(!state.Reservation.requests.length)
        yield put(fetchRequestsForDay(moment(state.Reservation.currentDate).format('YYYY-MM-DD')))

    if(!state.Reservation.requestsPreview.length)
        yield put(fetchRequestsForMonth(moment(state.Reservation.currentDate).format('YYYY-MM-DD')))

    if(!state.Notes.notes.length)
        yield put(fetchNotesDate(moment(state.Reservation.currentDate).format('YYYY-MM-DD')))

    yield put(calendarLoaded())
}

export function* reloadRequests() {
    const state = yield select()
    const currentDate = state.Reservation.currentDate
    yield put(fetchRequestsForDay(moment(currentDate).format('YYYY-MM-DD')))
    yield put(fetchRequestsForMonth(moment(currentDate).format('YYYY-MM-DD')))
}

export function* sendEmailSaga({ payload }) {
    const res = yield call(ReservationService.sendEmail, payload)

    if (res.status === 200) {
        yield put(sendEmailSuccess(payload.emailType))
    } else {
        yield put(sendEmailError(res, payload.emailType))
    }
}

export function* updateRequestTagsSaga({ payload }) {
    const res = yield call(ReservationService.updateRequestTags, { pk_request: payload.request.pk_request, tags: payload.tags })

    if (res.status === 200) {
        yield put(updateRequestEntity({ id: payload.request.id, tags: payload.tags }))
        yield put(updateRequestTagsSuccess())
    } else {
        yield put(updateRequestTagsError(res))
    }
}

export function* updateReserveTagsSaga({ payload }) {
    const res = yield call(ReservationService.updateReserveTags, { pk_reserve: payload.reserve.pk_reserve, tags: payload.tags })

    if (res.status === 200) {
        yield put(updateReserveEntity({ id: payload.reserve.id, tags: payload.tags }))
        yield put(updateReserveTagsSuccess())
    } else {
        yield put(updateReserveTagsError(res))
    }
}

export function* checkInReserveSaga({ payload }) {
    let { data, checkin } = payload
    if(!Array.isArray(data))
        data = [data]

    const res = yield call(ReservationService.checkInReserve, data.map(rsv => rsv.pk_reserve), checkin)

    if (res.status === 200) {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            yield put(updateReserveEntity({ id: element.id, checked_in: checkin || res.data[0].checked_in }))
        }

        yield put(checkInReserveSuccess())
    } else {
        yield put(checkInReserveError(res))
    }
}

export function* changeReserveRequestSaga({ payload }) {
    if(!Array.isArray(payload.pk_reserve)){
        payload.pk_reserve = [payload.pk_reserve]
        payload.reserve_id = [payload.reserve_id]
    }

    const res = yield call(ReservationService.changeReserveRequest, payload)
    if (res.status === 200) {
        const { request } = yield select(store => store.entities)
        const request_id = request.allIds.find(id => request.byId[id].pk_request === payload.pk_request) || null
        
        for (let i = 0; i < payload.reserve_id.length; i++) {
            const reserve_id = payload.reserve_id[i];
            yield put(updateReserveEntity({ id: reserve_id, fk_request: payload.pk_request, request_id }))
        }

        yield put(changeReserveRequestSuccess())
    } else {
        yield put(changeReserveRequestError(res))
    }
}

export function* saveRequestBonusesSaga({ payload }) {
    const res = yield call(ReservationService.saveRequestBonuses, payload)

    if (res.status === 200) {
        yield put(saveRequestBonusesSuccess())
    } else {
        yield put(saveRequestBonusesError(res))
    }
}

export function* saveRequestPackagesSaga({ payload }) {
    const res = yield call(ReservationService.saveRequestPackages, payload)

    if (res.status === 200) {
        const { entities, Reservation } = yield select(state => state)

        const request = entities.request.byId[Reservation.currentRequest]

        yield put(updateRequestEntity({ ...request, packages: res.data }))
        yield put(saveRequestPackagesSuccess())
    } else {
        yield put(saveRequestPackagesError(res))
    }
}

export function* saveRequestMessageSaga({ payload }) {
    const res = yield call(ReservationService.saveRequestMessages, payload)

    if (res.status === 200) {
        yield put(saveRequestMessagesSuccess())
    } else {
        yield put(saveRequestMessagesError(res))
    }
}


export function* confirmReservePaymentSaga({ payload }) {
    if(!Array.isArray(payload))
        payload = [payload]

    const res = yield call(ReservationService.confirmReservePayment, payload.map(rv => rv.fk_payment))

    if (res.status === 200) {
        for (let i = 0; i < payload.length; i++) {
            const element = payload[i];
            yield put(updateReserveEntity({ ...element, payment: { ...element.payment, payment_status: 'CONFIRMED' }}))
        }
        yield put(confirmReservePaymentSuccess())
    } else {
        yield put(confirmReservePaymentError(res))
    }
}

export function* changeReserveExpirationSaga({ payload }) {
    const { reserve, date } = payload
    const res = yield call(ReservationService.changeReserveExpiration, reserve.pk_reserve, date)

    if (res.status === 200) {
        yield put(updateReserveEntity({ ...reserve, expiration_date: date }))
        yield put(changeReserveExpirationSuccess())
    } else {
        yield put(changeReserveExpirationError(res))
    }
}

export function* watchFetchAvaiableRequests() {
    yield takeEvery(FETCH_AVAIABLE_REQUESTS, fetchAvaiableRequestsSaga)
}

export function* watchFetchSchedule() {
    yield takeEvery(FETCH_SCHEDULE, getSchedule)
}

export function* watchFetchRequests() {
    yield takeEvery(FETCH_REQUESTS_FOR_DAY, getRequests)
}

export function* watchFetchRequestsForMonthView() {
    yield takeEvery(FETCH_REQUESTS_FOR_MONTH, getMonthRequestsPreview)
}

export function* watchCreateRequest() {
    yield takeEvery(CREATE_REQUEST, createRequestSaga)
}

export function* watchCancelReserve() {
    yield takeEvery(CANCEL_RESERVE, cancelReserveSaga)
}

export function* watchSelectRequest() {
    yield takeEvery(SELECT_REQUEST, selectRequestSaga)
}

export function* watchCancelRequest() {
    yield takeEvery(CANCEL_REQUEST, cancelRequest);
}

export function* watchChangeRequestDate() {
    yield takeEvery(CHANGE_REQUEST_DATE, changeRequestDate)
}

export function* watchChangeRequestInfo() {
    yield takeEvery(CHANGE_REQUEST_INFO, changeRequestInfoSaga)
}

export function* watchChangeReserveInfo() {
    yield takeEvery(CHANGE_RESERVE_INFO, changeReserveInfoSaga)
}

export function* watchLoadCalendar() {
    yield takeEvery(LOAD_CALENDAR, loadCalendar)
}

export function* watchReloadRequests() {
    yield takeEvery(RELOAD_REQUESTS, reloadRequests)
}

export function* watchSendEmail() {
    yield takeEvery(SEND_EMAIL, sendEmailSaga)
}

export function* watchUpdateRequestFlags() {
    yield takeEvery(UPDATE_REQUEST_TAGS, updateRequestTagsSaga)
}

export function* watchUpdateReserveFlags() {
    yield takeEvery(UPDATE_RESERVE_TAGS, updateReserveTagsSaga)
}

export function* watchCheckInReserve() {
    yield takeEvery(CHECK_IN_RESERVE, checkInReserveSaga)
}

export function* watchFetchGoogleEvents() {
    yield takeEvery(FETCH_GOOGLE_EVENTS, getGoogleEventsSaga)
}

export function* watchChangeReserveRequest() {
    yield takeEvery(CHANGE_RESERVE_REQUEST, changeReserveRequestSaga)
}

export function* watchSaveRequestBonuses() {
    yield takeEvery(SAVE_REQUEST_BONUSES, saveRequestBonusesSaga)
}

export function* watchSaveRequestMessage() {
    yield takeEvery(SAVE_REQUEST_MESSAGES, saveRequestMessageSaga)
}

export function* watchCreateSingleScheduleRule() {
    yield takeEvery(CREATE_SINGLE_SCHEDULE_RULE, sagaCreateScheduleRule)
}

export function* watchUpdateSingleScheduleRule() {
    yield takeEvery(UPDATE_SINGLE_SCHEDULE_RULE, sagaUpdateScheduleRule)
}

export function* watchDeleteSingleScheduleRule() {
    yield takeEvery(DELETE_SINGLE_SCHEDULE_RULE, sagaDeleteScheduleRule)
}

export function* watchSaveRequestPackages() {
    yield takeEvery(SAVE_REQUEST_PACKAGES, saveRequestPackagesSaga)
}

export function* watchFetchRequestsForDaySuccess() {
    yield takeEvery(FETCH_REQUESTS_FOR_DAY_SUCCESS, loadReservesForRequestsSaga)
}

export function* watchConfirmReservePayment() {
    yield takeEvery(CONFIRM_RESERVE_PAYMENT, confirmReservePaymentSaga)
}

export function* watchChangeReserveExpiration() {
    yield takeEvery(CHANGE_RESERVE_EXPIRATION, changeReserveExpirationSaga)
}

function* reservationSaga(){
    yield all([
        fork(watchFetchRequestsForDaySuccess),
        fork(watchSaveRequestPackages),
        fork(watchFetchAvaiableRequests),
        fork(watchFetchSchedule), 
        fork(watchFetchRequests),
        fork(watchFetchRequestsForMonthView),
        fork(watchCreateRequest),
        fork(watchCancelRequest),
        fork(watchCancelReserve),
        fork(watchChangeRequestDate),
        fork(watchChangeRequestInfo),
        fork(watchChangeReserveInfo),
        fork(watchSelectRequest),
        fork(watchChangeRequestDate),
        fork(watchLoadCalendar),
        fork(watchReloadRequests),
        fork(watchSendEmail),
        fork(watchUpdateRequestFlags),
        fork(watchUpdateReserveFlags),
        fork(watchCheckInReserve),
        fork(watchFetchGoogleEvents),
        fork(watchSaveRequestBonuses),
        fork(watchSaveRequestMessage),
        fork(watchChangeReserveRequest),
        fork(watchCreateSingleScheduleRule),
        fork(watchUpdateSingleScheduleRule),
        fork(watchDeleteSingleScheduleRule),
        fork(watchConfirmReservePayment),
        fork(watchChangeReserveExpiration)
    ])
}

export default reservationSaga