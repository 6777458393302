import { v4 as uuid4 } from 'uuid'

export class RequestMessage {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.request_id = json.request_id || null
        this.pk_request_message = json.pk_request_message || null
        this.fk_request = json.fk_request || null
        this.description = json.description || ""
        this.created_at = json.created_at
        this.updated_at = json.updated_at
        this.is_deleted = json.is_deleted || false
    }
}