import { CHANGE_OPEN_WIZARD, COMPLETE_WIZARD, REFRESH_STORE, VALIDATE_PASSWORD, VALIDATE_PASSWORD_ERROR, VALIDATE_PASSWORD_SUCCESS } from "./constants";
import moment from 'moment';
import { CLEAR_ERROR } from "../constants";
import { LOGOUT_USER } from "../auth/constants";

const initial_state = {
    last_updated: moment(),
    loadingValidatePassword: false,
    password_validated: false,
    openWizard: undefined,
    error: null,
}

const App = (state = initial_state, action) => {
    switch (action.type) {
        case REFRESH_STORE:
            return { ...state, last_updated: moment() }
        case VALIDATE_PASSWORD:
            return { ...state, loadingValidatePassword: true }
        case VALIDATE_PASSWORD_SUCCESS:
            return { ...state, loadingValidatePassword: false, password_validated: true }
        case VALIDATE_PASSWORD_ERROR:
            return { ...state, loadingValidatePassword: false, error: { ...action.payload } }
        case CHANGE_OPEN_WIZARD:
            return { ...state, openWizard: action.payload.wizard }
        case COMPLETE_WIZARD:
            return { ...state, openWizard: undefined }
        case LOGOUT_USER:
            return initial_state
        case CLEAR_ERROR:
            return { ...state, error: null }
        default:
            return state;
    }
}

export default App