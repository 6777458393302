import { combineReducers } from "redux";
import { CREATE_EMAIL_AUTOMATION, DELETE_EMAIL_AUTOMATION, UPDATE_EMAIL_AUTOMATION } from "./constants";

export const emailAutomationById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_EMAIL_AUTOMATION:
            return { ...state, [action.payload.id]: action.payload }
        case UPDATE_EMAIL_AUTOMATION:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_EMAIL_AUTOMATION:
            return { ...state, [action.payload.id]: undefined }
        default:
            return state
    }
}

export const emailAutomationAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_EMAIL_AUTOMATION:
            return [ ...state, action.payload.id ]
        case DELETE_EMAIL_AUTOMATION:
            return state.reduce((prevState, curId) => curId === action.payload.id ? prevState : [ ...prevState, curId ] , [])
        default:
            return state
    }
}

export default combineReducers({
    byId: emailAutomationById,
    allIds: emailAutomationAllIds
})