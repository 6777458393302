import {
    CHANGE_VIEW_MODE,
    CHANGE_CURRENT_DATE,
    FETCH_REQUESTS_FOR_MONTH,
    FETCH_REQUESTS_FOR_MONTH_SUCCESS,
    FETCH_REQUESTS_FOR_MONTH_ERROR,
    FETCH_REQUESTS_FOR_DAY,
    FETCH_REQUESTS_FOR_DAY_SUCCESS,
    FETCH_REQUESTS_FOR_DAY_ERROR,
    FETCH_SCHEDULE,
    FETCH_SCHEDULE_SUCCESS,
    FETCH_SCHEDULE_ERROR,
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_ERROR,
    CANCEL_REQUEST,
    CANCEL_REQUEST_SUCCESS,
    CANCEL_REQUEST_ERROR,
    CANCEL_RESERVE,
    CANCEL_RESERVE_SUCCESS,
    CANCEL_RESERVE_ERROR,
    CHANGE_REQUEST_DATE,
    CHANGE_REQUEST_DATE_SUCCESS,
    CHANGE_REQUEST_DATE_ERROR,
    SELECT_REQUEST,
    VIEW_MODE_MONTH,
    CLEAR_ERROR,
    DELETE_REQUEST_ENTITY,
    SAVE_REQUEST_BONUSES,
    SAVE_REQUEST_BONUSES_SUCCESS,
    SAVE_REQUEST_BONUSES_ERROR
} from '../constants'
import { LOAD_CALENDAR, CALENDAR_LODADED, RELOAD_REQUESTS, CHANGE_REQUEST_INFO, CHANGE_REQUEST_INFO_SUCCESS, CHANGE_REQUEST_INFO_ERROR, CHANGE_RESERVE_INFO, CHANGE_RESERVE_INFO_SUCCESS, CHANGE_RESERVE_INFO_ERROR, SEND_EMAIL_SUCCESS, SEND_EMAIL_ERROR, UPDATE_REQUEST_TAGS, UPDATE_REQUEST_TAGS_SUCCESS, UPDATE_REQUEST_TAGS_ERROR, UPDATE_RESERVE_TAGS, UPDATE_RESERVE_TAGS_SUCCESS, UPDATE_RESERVE_TAGS_ERROR, CHECK_IN_RESERVE, CHECK_IN_RESERVE_SUCCESS, CHECK_IN_RESERVE_ERROR, FETCH_GOOGLE_EVENTS, FETCH_GOOGLE_EVENTS_SUCCESS, FETCH_GOOGLE_EVENTS_ERROR, CHANGE_RESERVE_REQUEST, CHANGE_RESERVE_REQUEST_SUCCESS, CHANGE_RESERVE_REQUEST_ERROR, SAVE_REQUEST_MESSAGES, SAVE_REQUEST_MESSAGES_SUCCESS, SAVE_REQUEST_MESSAGES_ERROR, FETCH_AVAIABLE_REQUESTS, FETCH_AVAIABLE_REQUESTS_SUCCESS, FETCH_AVAIABLE_REQUESTS_ERROR, LOAD_ADVENTURES_FOR_SCHEDULE_SUCCESS, CREATE_SINGLE_SCHEDULE_RULE, UPDATE_SINGLE_SCHEDULE_RULE, DELETE_SINGLE_SCHEDULE_RULE, CREATE_SINGLE_SCHEDULE_RULE_ERROR, UPDATE_SINGLE_SCHEDULE_RULE_ERROR, DELETE_SINGLE_SCHEDULE_RULE_ERROR, CREATE_SINGLE_SCHEDULE_RULE_SUCCESS, UPDATE_SINGLE_SCHEDULE_RULE_SUCCESS, DELETE_SINGLE_SCHEDULE_RULE_SUCCESS, SAVE_REQUEST_PACKAGES, SAVE_REQUEST_PACKAGES_SUCCESS, SAVE_REQUEST_PACKAGES_ERROR, LOAD_RESERVES_FOR_REQUEST_SUCCESS, LOAD_RESERVES_FOR_REQUEST_ERROR, CONFIRM_RESERVE_PAYMENT, CONFIRM_RESERVE_PAYMENT_SUCCESS, CONFIRM_RESERVE_PAYMENT_ERROR, CHANGE_RESERVE_EXPIRATION, CHANGE_RESERVE_EXPIRATION_SUCCESS, CHANGE_RESERVE_EXPIRATION_ERROR } from './constants'
import { LOGOUT_USER, CLEAR_SUCCESS } from '../auth/constants'
import { REFRESH_STORE } from '../app/constants'
import moment from 'moment'

const initialState = {
    requests: [],
    requestsToLoadReserves: [],
    requestsPreview: [],
    avaiableRequests: [],
    adventures: {},
    googleEvents: [],
    fetchedDates: [],
    fetchedMonths: [],
    schedule: [],
    currentDate: moment().format("YYYY-MM-DD"),
    loadingMonth: false,
    loadingCalendar: false,
    isCalendarLoaded: false,
    loadingRequests: false,
    loadingSchedule: false,
    loadingGoogleEvents: false,
    loadingChangeDate: false,
    loadingChangeReserveRequest: false,
    loadingCancelReserve: false,
    loadingCancelRequest: false,
    loadingCreateRequest: false,
    loadingChangeRequestInfo: false,
    loadingChangeReserveInfo: false,
    loadingRequestMessage: false,
    loadingCheckIn: false,
    loadingRequestTags: false,
    loadingReserveTags: false,
    loadingRequestBonuses: false,
    loadginRequestPackages: false,
    loadingConfirmPayment: false,
    loadingChangeReserveExpiration: false,
    loadingScheduleRulesChange: false,
    currentRequest: null,
    error: null,
    success: null,
    viewMode: VIEW_MODE_MONTH,
}


const Reservations = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REQUESTS_FOR_MONTH:
            return { ...state, fetchedMonths: [ ...state.fetchedMonths, action.payload.date ], loadingMonth: true }
        case FETCH_REQUESTS_FOR_MONTH_SUCCESS:
            return { ...state, requestsPreview: [ ...state.requestsPreview, ...action.payload], loadingMonth: false }
        case FETCH_REQUESTS_FOR_MONTH_ERROR:
            return { ...state, error: action.payload, loadingMonth: false }
        case FETCH_REQUESTS_FOR_DAY:
            return { ...state, fetchedDates: [ ...state.fetchedDates, action.payload.date ], loadingRequests: true }
        case FETCH_REQUESTS_FOR_DAY_SUCCESS:
            return { ...state, requests: [ ...state.requests, ...action.payload], requestsToLoadReserves: [ ...state.requestsToLoadReserves, ...action.payload ], loadingRequests: false }
        case LOAD_RESERVES_FOR_REQUEST_SUCCESS:
        case LOAD_RESERVES_FOR_REQUEST_ERROR:
            return { ...state, requestsToLoadReserves: state.requestsToLoadReserves.reduce((reducedState, id) => id === action.payload.id ? reducedState : [ ...reducedState, id ], []) }
        case FETCH_REQUESTS_FOR_DAY_ERROR:
            return { ...state, error: action.payload, loadingRequests: false }
        case RELOAD_REQUESTS:
            return { ...state, requests: [], requestsToLoadReserves: [], requestsPreview: [], fetchedDates: [], fetchedMonths: [], loadingRequests: true }
        case FETCH_SCHEDULE:
            return { ...state, schedule: [], loadingSchedule: true }
        case FETCH_SCHEDULE_SUCCESS:
            return { ...state, loadingSchedule: false, schedule: [ ...action.payload ] }
        case FETCH_SCHEDULE_ERROR:
            return { ...state, loadingSchedule: false, error: action.payload }
        case FETCH_GOOGLE_EVENTS:
            return { ...state, loadingGoogleEvents: true }
        case FETCH_GOOGLE_EVENTS_SUCCESS:
            return { ...state, loadingGoogleEvents: false, googleEvents: [ ...action.payload, state.googleEvents ] }
        case FETCH_GOOGLE_EVENTS_ERROR:
            return { ...state, loadingGoogleEvents: false, error: action.payload }
        case CHANGE_VIEW_MODE:
            return { ...state, viewMode: action.payload.mode }
        case CHANGE_CURRENT_DATE:
            return { ...state, currentDate: action.payload.date }
        case DELETE_REQUEST_ENTITY:
            return {
                ...state,
                requests: state.requests.reduce((prevState, curId) => curId === action.payload.id ? prevState : [ ...prevState, curId],[])
            }
        case CREATE_REQUEST:
            return { ...state, loadingCreateRequest: true }
        case CREATE_REQUEST_SUCCESS:
            return { ...state, requests: [ ...new Set([ ...state.requests, action.payload.id]) ], loadingCreateRequest: false }
        case CREATE_REQUEST_ERROR:
            return { ...state, error: { ...action.payload }, loadingCreateRequest: false }
        case CANCEL_RESERVE:
            return { ...state, loadingCancelReserve: true }
        case CANCEL_RESERVE_SUCCESS:
            return { ...state, loadingCancelReserve: false }
        case CANCEL_RESERVE_ERROR:
            return { ...state, loadingCancelReserve: false, error: { ...action.payload } }
        case SELECT_REQUEST:
            return { ...state, currentRequest: action.payload.id }
        case CLEAR_ERROR:
            return {...state, error: null}
        case CANCEL_REQUEST:
            return { ...state, loadingCancelRequest: true }
        case CANCEL_REQUEST_SUCCESS:
            return { ...state, loadingCancelRequest: false }
        case CANCEL_REQUEST_ERROR:
            return { ...state, loadingCancelRequest: false, error: action.payload }
        case CHANGE_REQUEST_INFO:
            return { ...state, loadingChangeRequestInfo: true }
        case CHANGE_REQUEST_INFO_SUCCESS:
            return { ...state, loadingChangeRequestInfo: false }
        case CHANGE_REQUEST_INFO_ERROR:
            return { ...state, loadingChangeRequestInfo: false, error: action.payload }
        case CHANGE_RESERVE_INFO:
            return { ...state, loadingChangeReserveInfo: true }
        case CHANGE_RESERVE_INFO_SUCCESS:
            return { ...state, loadingChangeReserveInfo: false }
        case CHANGE_RESERVE_INFO_ERROR:
            return { ...state, loadingChangeReserveInfo: false, error: action.payload }
        case CHANGE_REQUEST_DATE:
            return { ...state, loadingChangeDate: true }
        case CHANGE_REQUEST_DATE_SUCCESS:
            return { ...state, loadingChangeDate: false }
        case CHANGE_REQUEST_DATE_ERROR:
            return { ...state, loadingChangeDate: false, error: action.payload }
        case LOAD_CALENDAR:
            return { ...initialState,
                currentDate: state.currentDate,
                currentRequest: state.currentRequest,
                viewMode: state.viewMode,
                loadingCalendar: true
            }
        case LOAD_ADVENTURES_FOR_SCHEDULE_SUCCESS:
            return { ...state, adventures: action.payload.reduce((prevAdv, adv) => ({ ...prevAdv, [adv.pk_adventure]: adv }), {}) }
        case CALENDAR_LODADED:
            return { ...state, loadingCalendar: false, isCalendarLoaded: true }
        case SEND_EMAIL_SUCCESS:
            if(["request","reserve"].includes(action.payload.emailType))
                return { ...state, success: { message: "Email enviado com sucesso!" } }
            else
                return state
        case SEND_EMAIL_ERROR:
            if(["request","reserve"].includes(action.payload.emailType))
                return { ...state, error: action.payload.error }
            else
                return state
        case UPDATE_REQUEST_TAGS:
            return { ...state, loadingRequestTags: true }
        case UPDATE_REQUEST_TAGS_SUCCESS:
            return { ...state, loadingRequestTags: false }
        case UPDATE_REQUEST_TAGS_ERROR:
            return { ...state, loadingRequestTags: false, error: action.payload }
        case UPDATE_RESERVE_TAGS:
            return { ...state, loadingReserveTags: true }
        case UPDATE_RESERVE_TAGS_SUCCESS:
            return { ...state, loadingReserveTags: false }
        case UPDATE_RESERVE_TAGS_ERROR:
            return { ...state, loadingReserveTags: false, error: action.payload }
        case CHECK_IN_RESERVE:
            return { ...state, loadingCheckIn: true }
        case CHECK_IN_RESERVE_SUCCESS:
            return { ...state, loadingCheckIn: false }
        case CHECK_IN_RESERVE_ERROR:
            return { ...state, loadingCheckIn: false, error: action.payload }
        case CLEAR_SUCCESS:
            return { ...state, success: null }
        case CHANGE_RESERVE_REQUEST:
            return { ...state, loadingChangeReserveRequest: true }
        case CHANGE_RESERVE_REQUEST_SUCCESS:
            return { ...state, loadingChangeReserveRequest: false }
        case CHANGE_RESERVE_REQUEST_ERROR:
            return { ...state, loadingChangeReserveRequest: false, error: action.payload }
        case SAVE_REQUEST_BONUSES:
            return { ...state, loadingRequestBonuses: true }
        case SAVE_REQUEST_BONUSES_SUCCESS:
            return { ...state, loadingRequestBonuses: false }
        case SAVE_REQUEST_BONUSES_ERROR:
            return { ...state, loadingRequestBonuses: false, error: action.payload }
        case SAVE_REQUEST_PACKAGES:
            return { ...state, loadingRequestPackages: true }
        case SAVE_REQUEST_PACKAGES_SUCCESS: 
            return { ...state, loadingRequestPackages: false }
        case SAVE_REQUEST_PACKAGES_ERROR:
            return { ...state, loadingRequestPackages: false, error: action.payload }
        case SAVE_REQUEST_MESSAGES:
            return { ...state, loadingRequestMessage: true }
        case SAVE_REQUEST_MESSAGES_SUCCESS:
            return { ...state, loadingRequestMessage: false }
        case SAVE_REQUEST_MESSAGES_ERROR:
            return { ...state, loadingRequestMessage: false, error: action.payload }
        case REFRESH_STORE:
            return { ...initialState, currentDate: state.currentDate }
        case FETCH_AVAIABLE_REQUESTS:
            return { ...state, loadingAvaiableRequests: true }
        case FETCH_AVAIABLE_REQUESTS_SUCCESS:
            return { ...state, loadingAvaiableRequests: false, avaiableRequests: [ ...action.payload ] }
        case FETCH_AVAIABLE_REQUESTS_ERROR:
            return { ...state, loadingAvaiableRequests: false, avaiableRequests: [], error: action.payload }
        case CREATE_SINGLE_SCHEDULE_RULE:
            return { ...state, loadingScheduleRulesChange: true }
        case UPDATE_SINGLE_SCHEDULE_RULE:
            return { ...state, loadingScheduleRulesChange: true }
        case DELETE_SINGLE_SCHEDULE_RULE:
            return { ...state, loadingScheduleRulesChange: true }
        case CREATE_SINGLE_SCHEDULE_RULE_SUCCESS:
            return { ...state, loadingScheduleRulesChange: false, adventures: { ...state.adventures, [action.payload.pk_adventure]: action.payload } }
        case UPDATE_SINGLE_SCHEDULE_RULE_SUCCESS:
            return { ...state, loadingScheduleRulesChange: false, adventures: { ...state.adventures, [action.payload.pk_adventure]: action.payload } }
        case DELETE_SINGLE_SCHEDULE_RULE_SUCCESS:
            return { ...state, loadingScheduleRulesChange: false, adventures: { ...state.adventures, [action.payload.pk_adventure]: action.payload } }
        case CREATE_SINGLE_SCHEDULE_RULE_ERROR:
            return { ...state, loadingScheduleRulesChange: false, error: action.payload }
        case UPDATE_SINGLE_SCHEDULE_RULE_ERROR:
            return { ...state, loadingScheduleRulesChange: false, error: action.payload }
        case DELETE_SINGLE_SCHEDULE_RULE_ERROR:
            return { ...state, loadingScheduleRulesChange: false, error: action.payload }
        case CONFIRM_RESERVE_PAYMENT:
            return { ...state, loadingConfirmPayment: true }
        case CONFIRM_RESERVE_PAYMENT_SUCCESS:
            return { ...state, loadingConfirmPayment: false }
        case CONFIRM_RESERVE_PAYMENT_ERROR:
            return { ...state, loadingConfirmPayment: false, error: action.payload }
        case CHANGE_RESERVE_EXPIRATION:
            return { ...state, loadingChangeReserveExpiration: true }
        case CHANGE_RESERVE_EXPIRATION_SUCCESS:
            return { ...state, loadingChangeReserveExpiration: false }
        case CHANGE_RESERVE_EXPIRATION_ERROR:
            return { ...state, loadingChangeReserveExpiration: false, error: action.payload }
        case LOGOUT_USER:
            return initialState;
        default:
            return state
    }
}

export default Reservations