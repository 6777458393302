import { Api } from '../../helpers/api';
const API = new Api();

class AdventureService {
    getCompanyAdventures(){
        return API.call('/v2/adventure/get-adventure-by-company-independ-of-status', 'get')
    }

    getAdventuresCategories(){
        return API.call('/v1/category/get-categories', 'get', { withNoAdventures: true })
    }

    getAdventuresTags(){
        return API.call('/v2/tag/get-all-tags', 'get')
    }

    saveDraftAdventure(data){
        // Se tiver o campo de arquivo do termos de uso, monta o FormData
        const payload = new FormData()
        if(data.termsFile){
            payload.append("termsFile", data.termsFile)
        }
        payload.append("adventure", JSON.stringify(data))

        return API.call('/v2/adventure/update-adventure', 'post', payload, { multipart: !!data.termsFile, json: !data.termsFile })
    }

    uploadImageToAws(data){
        return API.call('/v1/image/save-image', 'post', data)
    }

    createAdventure(data){
        return API.call('/v2/adventure/create-adventure', 'post',  { adventure: data })
    }

    cloneAdventure(data){
        return API.call('/v1/adventure/clone-adventure', 'post', data)
    }
}

export default new AdventureService()