import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	FETCH_CART_LIST,
	FETCH_CART_LIST_FAILURE,
	FETCH_CART_LIST_SUCCESS,
} from './constants';
import CartsService from './service';

function* fetchCarts({ payload }) {
	try {
		const res = yield call(CartsService.getCarts, payload);
		yield put({ type: FETCH_CART_LIST_SUCCESS, payload: { items: res.data, total: res.data.total } });
	} catch (error) {
		console.log('erro', error);
		yield put({ type: FETCH_CART_LIST_FAILURE, payload: error });
	}
}

export function* watchFetchCarts() {
	yield takeLatest(FETCH_CART_LIST, fetchCarts);
}

function* cartsSaga() {
	yield all([
		fork(watchFetchCarts)
	])
}

export default cartsSaga