import {
	FETCH_ACTIVITIES,
	FETCH_ACTIVITIES_SUCCESS,
	FETCH_ACTIVITIES_ERROR,
} from './constants';

export const fetchActivities = (payload) => ({
	type: FETCH_ACTIVITIES,
	payload
});

export const fetchActivitiesSuccess = (payload) => ({
	type: FETCH_ACTIVITIES_SUCCESS,
	payload
});

export const fetchActivitiesError = (payload) => ({
	type: FETCH_ACTIVITIES_ERROR,
	payload
});
