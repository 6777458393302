import { CREATE_REQUEST_BONUS, DELETE_REQUEST_BONUS, UPDATE_REQUEST_BONUS } from "./constants"
import { RequestBonus } from "../../../models";

export const createRequestBonusEntity = (payload) => ({
    type: CREATE_REQUEST_BONUS,
    payload: new RequestBonus(payload)
})

export const updateRequestBonusEntity = (payload) => ({
    type: UPDATE_REQUEST_BONUS,
    payload
})

export const deleteRequestBonusEntity = id => ({
    type: DELETE_REQUEST_BONUS,
    payload: { id }
})