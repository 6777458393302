import React from 'react'
import PropTypes from 'prop-types'

export const Unicon = ({ icon, alt, onClick, inline = false, className, fontSize, style, spanStyle = {}, align = 'horizontal'}) => {
    return (
        <span onClick={onClick} aria-label={alt} style={{ ...spanStyle, display: inline ? 'inline-flex' : 'flex', justifyContent: 'center', cursor: 'pointer', height: '100%', verticalAlign: 'middle', flexDirection: align === 'vertical' ? 'column' : 'row' }}>
            <i aria-hidden={true} className={`uil uil-${icon} ${className}`} style={{ fontSize: fontSize, ...style}} ></i>
        </span>
    )
}

Unicon.propTypes = {
    icon: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    fontSize: PropTypes.string,
    style: PropTypes.object,
    align: PropTypes.oneOf(['vertical', 'horizontal'])
}

export const Ticket = (props) => <Unicon icon="ticket" alt="ticket icon" {...props} />
export const WhatsApp = (props) => <Unicon icon="whatsapp" alt="whatsapp icon" {...props} />
export const Trash = (props) => <Unicon icon="trash" alt="trash icon" {...props} />
export const Building = (props) => <Unicon icon="building" alt="building icon" {...props} />
export const Calender = (props) => <Unicon icon="calender" alt="calender icon" {...props} />
export const CalendarAlt = (props) => <Unicon icon="calendar-alt" alt="calendar icon" {...props} />
export const Schedule = (props) => <Unicon icon="schedule" alt="schedule icon" {...props} />
export const Store = (props) => <Unicon icon="store" alt="store icon" {...props} />
export const Notes = (props) => <Unicon icon="notes" alt="note icon" {...props} />
export const Sync = (props) => <Unicon icon="sync" alt="sync icon" {...props} />