/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';
export const CLEAR_FORGET_PASSWORD = 'CLEAR_FORGOT_PASSWORD';

export const UPDATE_USER_FIELDS = 'UPDATE_USER_FIELDS';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

export const UPDATE_COMPANY_TAGS = 'UPDATE_COMPANY_TAGS';
export const UPDATE_COMPANY_TAGS_SUCCESS = 'UPDATE_COMPANY_TAGS_SUCCESS';
export const UPDATE_COMPANY_TAGS_ERROR = 'UPDATE_COMPANY_TAGS_ERROR';

export const SAVE_BANK_ATTRIBUTES = 'SAVE_BANK_ATTRIBUTES';
export const SAVE_BANK_ATTRIBUTES_SUCCESS = 'SAVE_BANK_ATTRIBUTES_SUCCESS';
export const SAVE_BANK_ATTRIBUTES_ERROR = 'SAVE_BANK_ATTRIBUTES_ERROR';

export const SAVE_BUSINESS_ATTRIBUTES = 'SAVE_BUSINESS_ATTRIBUTES';
export const SAVE_BUSINESS_ATTRIBUTES_SUCCESS = 'SAVE_BUSINESS_ATTRIBUTES_SUCCESS';
export const SAVE_BUSINESS_ATTRIBUTES_ERROR = 'SAVE_BUSINESS_ATTRIBUTES_ERROR';

export const SEND_DOCUMENTS = 'SEND_DOCUMENTS';
export const SEND_DOCUMENTS_SUCCESS = 'SEND_DOCUMENTS_SUCCESS';
export const SEND_DOCUMENTS_ERROR = 'SEND_DOCUMENTS_ERROR';

export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

export const SAVE_PAGARME_SETTINGS = 'SAVE_PAGARME_SETTINGS';
export const SAVE_PAGARME_SETTINGS_SUCCESS = 'SAVE_PAGARME_SETTINGS_SUCCESS';
export const SAVE_PAGARME_SETTINGS_ERROR = 'SAVE_PAGARME_SETTINGS_ERROR';

export const SAVE_MERCADO_PAGO_TOKEN = 'SAVE_MERCADO_PAGO_TOKEN';
export const SAVE_MERCADO_PAGO_TOKEN_SUCCESS = 'SAVE_MERCADO_PAGO_TOKEN_SUCCESS';
export const SAVE_MERCADO_PAGO_TOKEN_ERROR = 'SAVE_MERCADO_PAGO_TOKEN_ERROR';

export const SAVE_PAG_SEGURO_CREDENTIALS = 'SAVE_PAG_SEGURO_CREDENTIALS';
export const SAVE_PAG_SEGURO_CREDENTIALS_SUCCESS = 'SAVE_PAG_SEGURO_CREDENTIALS_SUCCESS';
export const SAVE_PAG_SEGURO_CREDENTIALS_ERROR = 'SAVE_PAG_SEGURO_CREDENTIALS_ERROR';

export const SAVE_GETNET_CREDENTIALS = 'SAVE_GETNET_CREDENTIALS';
export const SAVE_GETNET_CREDENTIALS_SUCCESS = 'SAVE_GETNET_CREDENTIALS_SUCCESS';
export const SAVE_GETNET_CREDENTIALS_ERROR = 'SAVE_GETNET_CREDENTIALS_ERROR';

export const CREATE_PIX_KEY = 'CREATE_PIX_KEY'
export const CREATE_PIX_KEY_SUCCESS = 'CREATE_PIX_KEY_SUCCESS'
export const CREATE_PIX_KEY_ERROR = 'CREATE_PIX_KEY_ERROR'