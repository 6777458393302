/**
 * Requests: Agendamentos de horário
 */
export const FETCH_REQUESTS_FOR_MONTH = 'FETCH_REQUESTS_FOR_MONTH'
export const FETCH_REQUESTS_FOR_MONTH_SUCCESS = 'FETCH_REQUESTS_FOR_MONTH_SUCCESS'
export const FETCH_REQUESTS_FOR_MONTH_ERROR = 'FETCH_REQUESTS_FOR_MONTH_ERROR'

export const FETCH_REQUESTS_FOR_DAY = 'FETCH_REQUESTS_FOR_DAY'
export const FETCH_REQUESTS_FOR_DAY_SUCCESS = 'FETCH_REQUESTS_FOR_DAY_SUCCESS'
export const FETCH_REQUESTS_FOR_DAY_ERROR = 'FETCH_REQUESTS_FOR_DAY_ERROR'

export const RELOAD_REQUESTS = 'RELOAD_REQUESTS'

export const FETCH_REQUEST_BY_PK = 'FETCH_REQUEST_BY_PK'
export const FETCH_REQUEST_BY_PK_SUCCESS = 'FETCH_REQUEST_BY_PK_SUCCESS'
export const FETCH_REQUEST_BY_PK_ERROR = 'FETCH_REQUEST_BY_PK_ERROR'

export const CREATE_REQUEST = 'CREATE_REQUEST'
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS'
export const CREATE_REQUEST_ERROR = 'CREATE_REQUEST_ERROR'

export const CANCEL_REQUEST = 'CANCEL_REQUEST'
export const CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS'
export const CANCEL_REQUEST_ERROR = 'CANCEL_REQUEST_ERROR'

export const CHANGE_REQUEST_DATE = 'CHANGE_REQUEST_DATE'
export const CHANGE_REQUEST_DATE_SUCCESS = 'CHANGE_REQUEST_DATE_SUCCESS'
export const CHANGE_REQUEST_DATE_ERROR = 'CHANGE_REQUEST_DATE_ERROR'

export const CHANGE_REQUEST_INFO = 'CHANGE_REQUEST_INFO'
export const CHANGE_REQUEST_INFO_SUCCESS = 'CHANGE_REQUEST_INFO_SUCCESS'
export const CHANGE_REQUEST_INFO_ERROR = 'CHANGE_REQUEST_INFO_ERROR'

/**
 * Reserves: Participantes em horários
 */
export const CANCEL_RESERVE = 'CANCEL_RESERVE'
export const CANCEL_RESERVE_SUCCESS = 'CANCEL_RESERVE_SUCCESS'
export const CANCEL_RESERVE_ERROR = 'CANCEL_RESERVE_ERROR'

export const CHANGE_RESERVE_INFO = 'CHANGE_RESERVE_INFO'
export const CHANGE_RESERVE_INFO_SUCCESS = 'CHANGE_RESERVE_INFO_SUCCESS'
export const CHANGE_RESERVE_INFO_ERROR = 'CHANGE_RESERVE_INFO_ERROR'

/**
 * ScheduleRules: Regras de disponibilidade de horários e valores para horários
 */
export const FETCH_SCHEDULE = 'FETCH_SCHEDULE'
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS'
export const FETCH_SCHEDULE_ERROR = 'FETCH_SCHEDULE_ERROR'

export const FETCH_GOOGLE_EVENTS = 'FETCH_GOOGLE_EVENTS'
export const FETCH_GOOGLE_EVENTS_SUCCESS = 'FETCH_GOOGLE_EVENTS_SUCCESS'
export const FETCH_GOOGLE_EVENTS_ERROR = 'FETCH_GOOGLE_EVENTS_ERROR'

/**
 * Modo de exibição do calendario
 */
export const CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE'
export const CHANGE_CURRENT_DATE = 'CHANGE_CURRENT_DATE'
export const VIEW_MODE_MONTH = 'VIEW_MODE_MONTH'
export const VIEW_MODE_WEEK = 'VIEW_MODE_WEEK'
export const VIEW_MODE_DAY = 'VIEW_MODE_DAY'
export const VIEW_MODE_YEAR = 'VIEW_MODE_YEAR'

export const SELECT_REQUEST = 'SELECT_REQUEST'
export const LOAD_CALENDAR = 'LOAD_CALENDAR'
export const CALENDAR_LODADED = 'CALENDAR_LODADED'

export const SEND_EMAIL = 'SEND_EMAIL'
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR'

export const UPDATE_REQUEST_TAGS = 'UPDATE_REQUEST_TAGS'
export const UPDATE_REQUEST_TAGS_SUCCESS = 'UPDATE_REQUEST_TAGS_SUCCESS'
export const UPDATE_REQUEST_TAGS_ERROR = 'UPDATE_REQUEST_TAGS_ERROR'

export const UPDATE_RESERVE_TAGS = 'UPDATE_RESERVE_TAGS'
export const UPDATE_RESERVE_TAGS_SUCCESS = 'UPDATE_RESERVE_TAGS_SUCCESS'
export const UPDATE_RESERVE_TAGS_ERROR = 'UPDATE_RESERVE_TAGS_ERROR'

export const CHECK_IN_RESERVE = 'CHECK_IN_RESERVE'
export const CHECK_IN_RESERVE_SUCCESS = 'CHECK_IN_RESERVE_SUCCESS'
export const CHECK_IN_RESERVE_ERROR = 'CHECK_IN_RESERVE_ERROR'

export const CHANGE_RESERVE_REQUEST = 'CHANGE_RESERVE_REQUEST'
export const CHANGE_RESERVE_REQUEST_SUCCESS = 'CHANGE_RESERVE_REQUEST_SUCCESS'
export const CHANGE_RESERVE_REQUEST_ERROR = 'CHANGE_RESERVE_REQUEST_ERROR'

export const SAVE_REQUEST_BONUSES = 'SAVE_REQUEST_BONUSES'
export const SAVE_REQUEST_BONUSES_SUCCESS = 'SAVE_REQUEST_BONUSES_SUCCESS'
export const SAVE_REQUEST_BONUSES_ERROR = 'SAVE_REQUEST_BONUSES_ERROR'

export const SAVE_REQUEST_MESSAGES = 'SAVE_REQUEST_MESSAGES'
export const SAVE_REQUEST_MESSAGES_SUCCESS = 'SAVE_REQUEST_MESSAGES_SUCCESS'
export const SAVE_REQUEST_MESSAGES_ERROR = 'SAVE_REQUEST_MESSAGES_ERROR'

export const FETCH_AVAIABLE_REQUESTS = 'FETCH_AVAIABLE_REQUESTS'
export const FETCH_AVAIABLE_REQUESTS_SUCCESS = 'FETCH_AVAIABLE_REQUESTS_SUCCESS'
export const FETCH_AVAIABLE_REQUESTS_ERROR = 'FETCH_AVAIABLE_REQUESTS_ERROR'

export const LOAD_ADVENTURES_FOR_SCHEDULE = 'LOAD_ADVENTURES_FOR_SCHEDULE'
export const LOAD_ADVENTURES_FOR_SCHEDULE_SUCCESS = 'LOAD_ADVENTURES_FOR_SCHEDULE_SUCCESS'
export const LOAD_ADVENTURES_FOR_SCHEDULE_ERROR = 'LOAD_ADVENTURES_FOR_SCHEDULE_ERROR'

export const CREATE_SINGLE_SCHEDULE_RULE = 'CREATE_SINGLE_SCHEDULE_RULE'
export const CREATE_SINGLE_SCHEDULE_RULE_SUCCESS = 'CREATE_SINGLE_SCHEDULE_RULE_SUCCESS'
export const CREATE_SINGLE_SCHEDULE_RULE_ERROR = 'CREATE_SINGLE_SCHEDULE_RULE_ERROR'

export const UPDATE_SINGLE_SCHEDULE_RULE = 'UPDATE_SINGLE_SCHEDULE_RULE'
export const UPDATE_SINGLE_SCHEDULE_RULE_SUCCESS = 'UPDATE_SINGLE_SCHEDULE_RULE_SUCCESS'
export const UPDATE_SINGLE_SCHEDULE_RULE_ERROR = 'UPDATE_SINGLE_SCHEDULE_RULE_ERROR'

export const DELETE_SINGLE_SCHEDULE_RULE = 'DELETE_SINGLE_SCHEDULE_RULE'
export const DELETE_SINGLE_SCHEDULE_RULE_SUCCESS = 'DELETE_SINGLE_SCHEDULE_RULE_SUCCESS'
export const DELETE_SINGLE_SCHEDULE_RULE_ERROR = 'DELETE_SINGLE_SCHEDULE_RULE_ERROR'

export const SAVE_REQUEST_PACKAGES = 'SAVE_REQUEST_PACKAGES'
export const SAVE_REQUEST_PACKAGES_SUCCESS = 'SAVE_REQUEST_PACKAGES_SUCCESS'
export const SAVE_REQUEST_PACKAGES_ERROR = 'SAVE_REQUEST_PACKAGES_ERROR'

export const LOAD_RESERVES_FOR_REQUESTS = 'LOAD_RESERVES_FOR_REQUESTS'
export const LOAD_RESEREVS_FOR_REQUESTS_SUCCESS = 'LOAD_RESEREVS_FOR_REQUESTS_SUCCESS'

export const LOAD_RESERVES_FOR_REQUEST_SUCCESS = 'LOAD_RESERVES_FOR_REQUEST_SUCCESS'
export const LOAD_RESERVES_FOR_REQUEST_ERROR = 'LOAD_RESERVES_FOR_REQUEST_ERROR'

export const CONFIRM_RESERVE_PAYMENT = 'CONFIRM_RESERVE_PAYMENT'
export const CONFIRM_RESERVE_PAYMENT_SUCCESS = 'CONFIRM_RESERVE_PAYMENT_SUCCESS'
export const CONFIRM_RESERVE_PAYMENT_ERROR = 'CONFIRM_RESERVE_PAYMENT_ERROR'

export const CHANGE_RESERVE_EXPIRATION = 'CHANGE_RESERVE_EXPIRATION'
export const CHANGE_RESERVE_EXPIRATION_SUCCESS = 'CHANGE_RESERVE_EXPIRATION_SUCCESS'
export const CHANGE_RESERVE_EXPIRATION_ERROR = 'CHANGE_RESERVE_EXPIRATION_ERROR'