import { v4 as uuid4 } from 'uuid'
import moment from 'moment';

export class Campaign {
    constructor(json) {
        this.id = json.id || uuid4()
        this.pk_discount            = json.pk_discount || null
        this.fk_company             = json.fk_company || null
        this.active                 = json.active || true;
        this.text                   = json.text || "";
        this.adventures             = json.adventures || [];
        this.start                  = json.start || null;
        this.end                    = json.end || null;
        this.campaign               = json.campaign || "";
        this.quantity_by_user       = json.quantity_by_user || "";
        this.quantity_by_request    = json.quantity_by_request || "";
        this.quantity               = json.quantity || "";
        this.value                  = json.value || "";
        this.value_type             = json.value_type || 0;
        this.date_request_start     = json.date_request_start || null;
        this.date_request_end       = json.date_request_end || null;
        this.limited_by_quantity    = json.limited_by_quantity || false;
        this.limited_by_user        = json.limited_by_user || false;
        this.limited_by_request     = json.limited_by_request || false;
        this.uses_count             = json.uses_count || 0
    }
}
