// import axios from 'axios'
// import mock from '../../pages/clients/mock'
import Api from '../../helpers/api';

class ClientsService {
	getClients(payload) {
		return Api.call('/v1/lead/list-lead', 'get', payload)
		// return { data: mock };
	}

	createClient(client) {
		return Api.call('/v1/lead/register-lead', 'post', client)
	}

	editClient(client) {
		return Api.call('/v1/lead/update-lead', 'post', client)
	}

	deleteClient(client) {
		return Api.call('/v1/lead/delete-lead', 'post', client)
	}

	exportCsv() {
		return Api.call('/v1/lead/download-csv', 'get', {}, { raw: true })
		.then(res => {
			console.log(res)
			const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'clientes.csv');
            document.body.appendChild(link);
			link.click();
			document.body.removeChild(link)
		})
	}
}

export default new ClientsService()