import { LOGOUT_USER } from '../auth/constants';
import { CLEAR_ERROR } from '../constants';
import {
	FETCH_CLIENT_LIST,
	FETCH_CLIENT_LIST_SUCCESS,
	FETCH_CLIENT_LIST_FAILURE,
	CREATE_CLIENT,
	CREATE_CLIENT_SUCCESS,
	CREATE_CLIENT_FAILURE,
	EDIT_CLIENT,
	EDIT_CLIENT_SUCCESS,
	EDIT_CLIENT_FAILURE,
	DELETE_CLIENT,
	DELETE_CLIENT_SUCCESS,
	DELETE_CLIENT_FAILURE, EXPORT_CLIENT_CSV, EXPORT_CLIENT_CSV_SUCESS, EXPORT_CLIENT_CSV_FAILURE
} from './constants';

const initialState = {
	clientCount: null,
	clientList: [],
	curPage: 1,
	curSearch: "",
	loadingCsv: false,
	loading: true,
	error: null,
}

const Clients = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CLIENT_LIST:
			return { ...state, curPage: action.payload.page, curSearch: action.payload.search, clientList: action.payload.page === 1 ? [] : state.clientList, loading: true }

		case FETCH_CLIENT_LIST_SUCCESS:
			return { ...state, loading: false, clientList: action.payload.items, total: Number(action.payload.total) }

		case FETCH_CLIENT_LIST_FAILURE:
			return { ...state, loading: false, error: { ...action.payload } }

		case CREATE_CLIENT:
			return { ...state, loading: true }

		case CREATE_CLIENT_SUCCESS:
			return { ...state, loading: false, clientList: [...state.clientList, action.payload], total: state.total + 1 }

		case CREATE_CLIENT_FAILURE:
			return { ...state, loading: false, error: { ...action.payload } }

		case EDIT_CLIENT:
			return { ...state, loading: true }

		case EDIT_CLIENT_SUCCESS:
			const editedClient = { ...action.payload };

			const subsIndex = state.clientList.findIndex(client => client.pk_lead === editedClient.pk_lead);
			const newList = [...state.clientList]
			newList.splice(subsIndex, 1, { ...editedClient })

			return { ...state, loading: false, clientList: newList }

		case EDIT_CLIENT_FAILURE:
			return { ...state, loading: false, error: { ...action.payload } }

		case DELETE_CLIENT:
			return { ...state, loading: true }

		case DELETE_CLIENT_SUCCESS:
			const clientToBeDeleted = { ...action.payload };

			const mutatedClientList = state.clientList.filter(client => client.pk_lead !== clientToBeDeleted.pk_lead)

			return { ...state, loading: false, clientList: mutatedClientList, total: state.total - 1 }

		case DELETE_CLIENT_FAILURE:
			return { ...state, loading: false, error: { ...action.payload } }
		
		case LOGOUT_USER:
			return initialState

		case EXPORT_CLIENT_CSV:
			return { ...state, loadingCsv: true }
		
		case EXPORT_CLIENT_CSV_SUCESS:
			return { ...state, loadingCsv: false }

		case EXPORT_CLIENT_CSV_FAILURE:
			return { ...state, loadingCsv: false, error: { ...action.payload } }

		case CLEAR_ERROR:
			return { ...state, error: null }
			
		default:
			return state;
	}
}

export default Clients
