import { combineReducers } from 'redux'
import {
    CREATE_PACKAGE,
    UPDATE_PACKAGE,
    DELETE_PACKAGE
} from '../../constants'
import { LOGOUT_USER } from '../../auth/constants'
import { REFRESH_STORE } from "../../app/constants";
import { sliceDefaultPackage } from './slices';

export function packageById(state = {}, action) {
    switch (action.type) {
        case CREATE_PACKAGE:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_PACKAGE:
            if (action.payload.default)
                return sliceDefaultPackage(state, action)
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_PACKAGE:
            return { ...state, [action.payload.id]: undefined }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function packageAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_PACKAGE:
            return [ ...state, action.payload.id ]
        case DELETE_PACKAGE:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState, [])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}

export default combineReducers({
    byId: packageById,
    allIds: packageAllIds
})