import {
    CREATE_IMAGE,
    UPDATE_IMAGE,
    DELETE_IMAGE
} from '../../constants'
import { Image } from '../../../models'

export const createImage = (data) => ({
    type: CREATE_IMAGE,
    payload: new Image(data)
})

export const updateImage = (data) => ({
    type: UPDATE_IMAGE,
    payload: data
})

export const deleteImage = id => ({
    type: DELETE_IMAGE,
    payload: { id }
})