import { Api } from '../../helpers/api';
const API = new Api();

class FinanceService {
    getFinancesBalances(){
        // return API.call('/v1/transference/moip-balance', 'get');
        return API.call('/v2/juno/get-balance', 'post');
    }

   // getExtractOfMonth(params){
   //     return API.call('/v1/payment/get-finances-extract', 'get', params);
   // }
   getExtractOfMonth(params){
       console.log(params);
       const {startDate, endDate} = params;
       return API.call('/v2/payment/extract-by-period', 'post', params);
   }

   getFutureExtract(params) {
    console.log(params);
    const {startDate} = params;
    return API.call('/v2/payment/extract-by-period', 'post', {startDate});
   }

    transferAvailableMoipBalance(params){
        return API.call('/v2/transference/transfer-available-moip-balance', 'post', params);
    }
}

export default new FinanceService()