import { Campaign } from '../../models';
import * as CONST from './constants';

export const addCampaign = (campaign) => ({
    type: CONST.ADD_CAMPAIGN,
    payload: new Campaign(campaign),
});

export const addCampaignSuccess = (campaign) => ({
    type: CONST.ADD_CAMPAIGN_SUCCESS,
    payload: campaign,
});

export const addCampaignError = (err) => ({
    type: CONST.ADD_CAMPAIGN_ERROR,
    payload: err,
});

export const updateCampaign = (campaign) => ({
    type: CONST.UPDATE_CAMPAIGN,
    payload: campaign,
});

export const updateCampaignSuccess = (campaign) => ({
    type: CONST.UPDATE_CAMPAIGN_SUCCESS,
    payload: campaign,
});

export const updateCampaignError = (err) => ({
    type: CONST.UPDATE_CAMPAIGN,
    payload: err,
});

export const fetchCampaigns = () => ({
    type: CONST.FETCH_CAMPAIGN,
})

export const fetchCampaignsSuccess = (campaigns) => ({
    type: CONST.FETCH_CAMPAIGN_SUCCESS,
    payload: campaigns
})

export const fetchCampaignsError = (err) => ({
    type: CONST.FETCH_CAMPAIGN_ERROR,
    payload: err
})

export const selectCampaign = (id) => ({
    type: CONST.SELECT_CAMPAIGN,
    payload: { id }
})