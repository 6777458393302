import { combineReducers } from "redux";
import { CREATE_REQUEST_MESSAGE_ENTITY, DELETE_REQUEST_MESSAGE_ENTITY, UPDATE_REQUEST_MESSAGE_ENTITY } from "./constants";

export const requestMessageById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REQUEST_MESSAGE_ENTITY:
            return { ...state, [action.payload.id]: action.payload }
    
        case UPDATE_REQUEST_MESSAGE_ENTITY:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }

        case DELETE_REQUEST_MESSAGE_ENTITY:
            return { ...state, [action.payload.id]: undefined }
        default:
            return state
    }
}

export const requestMessageAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_REQUEST_MESSAGE_ENTITY:
            return [ ...state, action.payload.id ]

        case DELETE_REQUEST_MESSAGE_ENTITY:
            return state.reduce((prevState, curID) => curID === action.payload.id ? prevState : [ ...prevState, curID ] , [])
    
        default:
            return state
    }
}

export default combineReducers({
    byId: requestMessageById,
    allIds: requestMessageAllIds
})