import { Api } from '../../helpers/api';
const API = new Api()

class ReservationService {
    getActiveSchedules(){
        return API.call('/v2/schedule-rule/get-active-schedule-rules', 'get')
    }

    loadAdventuresForSchedule(payload = {}){
        return API.call('/v2/adventure/get-adventure-for-schedule-by-company', 'get', payload)
    }

    createScheduleRule(payload){
        return API.call('/v1/schedule-rule/create-schedule-rule', 'post', payload)
    }

    updateScheduleRule(payload){
        return API.call('/v1/schedule-rule/update-schedule-rule', 'post', payload)
    }

    deleteScheduleRule(payload){
        return API.call('/v1/schedule-rule/delete-schedule-rule', 'post', payload)
    }

    getActiveRequests(data){
        return API.call('/v2/request/get-active-requests', 'get', data)
    }

    createRequest(data){
        return API.call('/v2/request/create-reservation', 'post', data)
    }

    cancelReserve(pk_reserve){
        return API.call('/v2/reserve/cancel-voucher', 'post', { pk_reserve })
    }

    checkInReserve(pk_reserve, checkin = null){
        return API.call('/v1/reserve/check-in-participant', 'post', { pk_reserve, checkin })
    }

    cancelRequest(params){
        return API.call('/v1/request/cancel-request', 'post', { pk_request: params.request.pk_request, description: params.reason })
    }

    changeRequestDate(params){
        return API.call('/v1/request/change-request-date', 'post', { pkRequest: params.request.pk_request, date: params.date })
    }

    changeRequestInfo(params){
        return API.call('/v2/request/update-request-info', 'post', params)
    }

    changeReserveInfo(params){
        return API.call('/v2/request/update-reserve-info', 'post', params)
    }

    sendEmail(params){
        return API.call('/v2/request/send-email', 'post', params)
    }

    updateRequestTags(params){
        return API.call('/v2/request/update-request-tags', 'post', params)
    }

    updateReserveTags(params){
        return API.call('/v2/request/update-reserve-tags', 'post', params)
    }

    getGoogleEvents(params){
        return API.call('/v2/request/get-blocked-hours-for-month', 'get', params)
    }

    changeReserveRequest(params){
        return API.call('/v2/request/change-reserve-to-request', 'post', params)
    }
    
    saveRequestBonuses(params){
        return API.call('/v2/request/update-request-bonuses', 'post', params)
    }

    saveRequestMessages(params){
        return API.call('/v2/request/update-request-messages', 'post', params)
    }

    saveRequestPackages(params){
        return API.call('/v2/request/update-request-packages', 'post', params)
    }

    getAllAvaiableRequests(){
        return API.call('/v2/request/list-avaiable-requests', 'get')
    }

    getReservesForRequest(params){
        return API.call('/v2/reserve/get-reserves-for-request', 'get', params)
    }

    confirmReservePayment(pk_payment){
        return API.call('/v2/reserve/confirm-payment', 'post', { pk_payment })
    }

    changeReserveExpiration(pk_reserve, date){
        return API.call('/v2/reserve/change-reserve-expiration', 'post', { pk_reserve, date })
    }
}

export default new ReservationService()