import { LOGOUT_USER } from '../auth/constants';
import { CLEAR_ERROR } from '../constants';
import {
	FETCH_ACTIVITIES,
	FETCH_ACTIVITIES_SUCCESS,
	FETCH_ACTIVITIES_ERROR,
} from './constants';

const initialState = {
	count: 0,
	activities: [],
	page: 1,
	search: "",
	loading: true,
	error: null,
}

const Activities = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ACTIVITIES:
			return { ...state, page: action.payload.page, search: action.payload.search, activities: action.payload.page === 1 ? [] : state.activities, loading: true }

		case FETCH_ACTIVITIES_SUCCESS:
			return { ...state, loading: false, activities: action.payload.activities, count: Number(action.payload.count) }

		case FETCH_ACTIVITIES_ERROR:
			return { ...state, loading: false, error: { ...action.payload } }
		
		case LOGOUT_USER:
			return initialState

		case CLEAR_ERROR:
			return { ...state, error: null }
			
		default:
			return state;
	}
}

export default Activities
