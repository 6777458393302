import { CREATE_ADVENTURE_BONUS, DELETE_ADVENTURE_BONUS, UPDATE_ADVENTURE_BONUS } from "./constants";
import { AdventureBonus } from "../../../models";

export const createAdventureBonusEntity = (payload) => ({
    type: CREATE_ADVENTURE_BONUS,
    payload: new AdventureBonus(payload)
})

export const updateAdventureBonusEntity = (payload) => ({
    type: UPDATE_ADVENTURE_BONUS,
    payload
})

export const deleteAdventureBonusEntity = id => ({
    type: DELETE_ADVENTURE_BONUS,
    payload: { id }
})