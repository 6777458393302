import 'moment/locale/pt-br'
import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts';
import { ChartPTBRLocale } from '../constants/utils';
import Api from '../helpers/api'
import { makeFullDateArray } from '../helpers/utils';
import Loader from './Loader';

const apexAreaChart1Opts = {
    chart: {
        height: 550,
        locales: [ChartPTBRLocale],
        defaultLocale: 'pt-br',
        type: "line",
        toolbar: {
            show: false
        },
    },
    noData: {
        text: "Sem informações para este período"
    },
    tooltip: {
        x: { show: true }
    },
    dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
    },
    xaxis: {
        type: "datetime"
    },
    legend: {
        position: 'bottom',
    },
    responsive: [
        {
            breakpoint: 780,
            options: {
                legend: {
                    position: 'bottom',
                    offsetY: 0
                },
            },
        },
    ],
};

const series = [
    {
        name: "Ingressos consumidos",
        type: "bar",
        data: []
    },
    {
        name: "Eventos realizados",
        type: "line",
        data: []
    },
]

export const ChartScheduleMetrics = ({ options = {}, period }) => {
    const [loading, setLoading] = useState(false)
    const { start_date, end_date } = period

    useEffect(() => {
        setLoading(true)
        Api.call('/v2/metric/list-reserves-and-request-by-date', 'post', { start_date, end_date })
        .then(({ data }) => {
            series[0].data = data.reservesGroupedByDate.map(item => ({ x: item.date, y: Number(item.count) }))
            series[1].data = makeFullDateArray(data.requestsGroupedByDate, 'date', 'count')[1]
            setLoading(false)
        })
        .catch(() => setLoading(false))
    }, [start_date, end_date])
    
    return (
        <>
            <h3>Agenda</h3>
            <hr />
            {loading ? 
                <Loader />
            :
                <Chart options={apexAreaChart1Opts} height={400} type="line" series={series} />
            }
        </>
    )
}
