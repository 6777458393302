import { v4 as uuid4 } from 'uuid'

export class Tag {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_tag = json.pk_tag || null
        this.name = json.name || ''
        this.description = json.description || ''
        this.url_name = json.url_name || ''
        this.banner_full = json.banner_full || ''
        this.banner_mobile = json.banner_mobile || ''
        this.page_title = json.page_title || ''
        this.highlighted = json.highlighted || ''
        this.url_blog = json.url_blog || ''
    }
}