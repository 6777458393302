import { v4 as uuid4 } from 'uuid'

export class Image{
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_image = json.pk_image || null
        this.adventure_id = json.adventure_id || null
        this.url = json.url || ''
        this.type = json.type || ''
        this.tl_px = json.tl_px || ''
        this.br_px = json.br_px || ''
        this.url_sm = json.url_sm || ''
        this.url_md = json.url_md || ''
        this.url_lg = json.url_lg || ''
        this.url_xlg = json.url_xlg || ''
    }
}

export function getImageCloudPreviewLink(image) {
    return !!image ? `https://ayiszsbdmo.cloudimg.io/v7/${image.url}?tl_px=${image.tl_px}&br_px=${image.br_px}&w=740` : ""
}