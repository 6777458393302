import { combineReducers } from "redux";
import { 
    LOGOUT_USER, 
    REFRESH_STORE,
    CREATE_ADVENTURE_BONUS,
    UPDATE_ADVENTURE_BONUS,
    DELETE_ADVENTURE_BONUS
} from "../../constants";

export const adventureBonusById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_ADVENTURE_BONUS:
            return { ...state, [action.payload.id]: action.payload }

        case UPDATE_ADVENTURE_BONUS:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}

        case DELETE_ADVENTURE_BONUS:
            return { ...state, [action.payload.id]: undefined }

        case LOGOUT_USER:
            return {}

        case REFRESH_STORE:
            return {}

        default:
            return state;
    }
}

export const adventureBonusAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_ADVENTURE_BONUS:
            return [ ...state, action.payload.id ]
        
        case DELETE_ADVENTURE_BONUS:
            return state.reduce((prevState, id) => id === action.payload.id ? prevState : [ ...prevState, id ], [])
            
        case LOGOUT_USER:
            return []

        case REFRESH_STORE:
            return []

        default:
            return state
    }
}

export default combineReducers({
    byId: adventureBonusById,
    allIds: adventureBonusAllIds
})