import {
    CREATE_USER,
    UPDATE_USER_ENTITY,
    DELETE_USER
} from '../../constants'
import { User } from '../../../models'


export const createUser = (data) => ({
    type: CREATE_USER,
    payload: new User(data)
})

export const updateUserEntity = (data) => ({
    type: UPDATE_USER_ENTITY,
    payload: data
})

export const deleteUser = (id) => ({
    type: DELETE_USER,
    payload: { id }
})