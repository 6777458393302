import { v4 as uuid4 } from 'uuid'
import moment from 'moment'

export class Reserve {
    constructor(json={}){
        this.id = json.id || uuid4()
        this.pk_reserve = json.pk_reserve || null
        this.request_id = json.request_id || null
        this.fk_request = json.fk_request || null
        this.fk_partner = json.fk_partner || null
        this.adventure_id = json.adventure_id || null
        this.fk_adventure = json.fk_adventure || null
        this.adventure = json.adventure || undefined
        this.payment_id = json.payment_id || null
        this.fk_payment = json.fk_payment || null
        this.payment = json.payment || undefined
        this.package_id = json.package_id || null
        this.fk_package = json.fk_package || null
        
        if(json.package)
            this.package = json.package
        else if(json.requestPackage)
            this.package = json.requestPackage
        
        this.partner = json.partner || undefined

        this.user_id = json.user_id || null
        this.fk_user = json.fk_user || null
        this.type = !!json.fk_request ? "reserve" : "voucher"
        this.user = json.user || null
        this.request = json.request || null
        this.request_date = json.request_date || ''
        this.selected = json.selected || false
        this.created_at = json.created_at || ''
        this.is_deleted = json.is_deleted || false
        this.name = json.name || ''
        this.phone = json.phone || ''
        this.email = json.email || ''
        this.observation = json.observation || ''
        this.checked_in = !!json.checked_in || false
        this.expiration_date = json.expiration_date || null
        this.is_consumed = json.is_consumed || false
        this.tags = json.tags || []
        this.custom_fields = json.custom_fields ? json.custom_fields.map(field => ({ ...field, id: uuid4() })) : []
    }
}

export const denormalizeReserve = (reserve, entities, adventure = undefined) => {
    // reserve.adventure = { ...entities.adventure.byId[reserve.adventure_id] }
    // reserve.payment = { ...entities.payment.byId[reserve.payment_id] }
    // reserve.package = { ...entities.package.byId[reserve.package_id] }
    // reserve.user = { ...entities.user.byId[reserve.user_id] }
    reserve.request = { ...entities.request.byId[reserve.request_id] }
    return new Reserve({ ...reserve })
}

export const normalizeReserve = (reserve) => {
    // reserve.package = undefined
    // reserve.payment = undefined
    // reserve.adventure = undefined
    // reserve.user = undefined
    reserve.request = undefined
    return { ...reserve }
}

export function searchReserve(search, items){
    search = search.toLowerCase()
    return items.reduce((filteredItems, curItem) => {
        const itemIncludesSearch =  moment(curItem.created_at).format('DD/MM/YYYY').toLowerCase().includes(search) ||
                                      curItem.name.toLowerCase().includes(search) ||
                                      curItem.phone.toLowerCase().includes(search) ||
                                      curItem.email.toLowerCase().includes(search) ||
                                      (curItem.adventure && curItem.adventure.title.toLowerCase().includes(search)) ||
                                      (curItem.package && curItem.package.name.toLowerCase().includes(search))

        if (itemIncludesSearch) { 
            return [ ...filteredItems, curItem]
        } else {
            return filteredItems
        }
    },[])
}

export function groupReserveByUser(items){
    const mapFkUserToItem = {
        [1]: {
            label: 'Gerado pelo App',
            items: []
        },
        [-1]: {
            label: 'Pagamento não identificado',
            items: []
        }
    }
    items.forEach(item => {
        if(!!item.user){
            if(!mapFkUserToItem[item.user.pk_user])
                mapFkUserToItem[item.user.pk_user] = { label: `Gerado por ${item.user.email}`, items: [] }

                mapFkUserToItem[item.user.pk_user].items.push(item)
        } else {
            mapFkUserToItem[1].items.push(item)
        }
    })

    return Object.keys(mapFkUserToItem).map(key => mapFkUserToItem[key]).filter(group => group.items.length)
}
