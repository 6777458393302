import { 
    CREATE_NEW_SCRIPT,
    DELETE_SCRIPT,
    CREATE_NEW_SCRIPT_ITEM,
    EDIT_SCRIPT_ITEM,
    DELETE_SCRIPT_ITEM
} from "../../constants";
import { Script, ScriptItem } from "../../../models";

/**
 * Cria um novo dia do roteiro
 * @param {Object} data Dia do roteiro (model/script)
 */
export const createNewScript = data => ({
    type: CREATE_NEW_SCRIPT,
    payload: new Script(data)
})

export const deleteScript = id => ({
    type: DELETE_SCRIPT,
    payload: { id }
})

/**
 * Cria uma nova etapa em um dia do roteiro
 * @param {Object} data Etapa do dia (model/script/scriptitem)
 */
export const createNewScriptItem = data => ({
    type: CREATE_NEW_SCRIPT_ITEM,
    payload: new ScriptItem(data)
})

/**
 * Edita uma etapa em um dia do roteiro
 * @param {Object} data Etapa do dia editada (mode/script/scriptitem)
 */
export const editScriptItem = data => ({
    type: EDIT_SCRIPT_ITEM,
    payload: data
})

export const deleteScriptItem = id => ({
    type: DELETE_SCRIPT_ITEM,
    payload: { id }
})