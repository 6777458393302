import React from 'react'
import { ChevronRight } from 'react-feather'

import './Wizard.scss'

export const Wizard = ({ onClose, open, children, title = "" }) => {
    return (
        <div className={`wizard ${open && 'open'}`}>
            <div className="wizard-title">
                <span className="wizard-toggle" onClick={onClose}>
                    <ChevronRight className="text-primary" />
                </span>
                <h3 className="m-0">{title}</h3>
            </div>
            <div className="wizard-content">
                    {children}
            </div>
        </div>
    )
}
