import AdventureService from './service'
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { 
    fetchCompanyAdventuresSuccess, 
    fetchCompanyAdventuresError, 
    saveDraftAdventureSuccess, 
    saveDraftAdventureError, 
    fetchAdventuresCategoriesSuccess, 
    fetchAdventuresCategoriesError, 
    createNewCategory,
    createImage,
    uploadImageFileToAwsSuccess,
    uploadImageFileToAwsError,
    fetchAdventuresTagsSuccess,
    fetchAdventuresTagsError,
    createTag,
    selectAdventureError,
    createNewAdventureError,
    updateAdventure,
    createNewAdventureSuccess,
    clearCurrentAdenture,
} from '../actions';
import { 
    FETCH_COMPANY_ADVENTURES, 
    SAVE_DRAFT_ADVENTURE, 
    FETCH_ADVENTURES_CATEGORIES ,
    UPLOAD_IMAGE_FILE_TO_AWS,
    FETCH_ADVENTURES_TAGS,
    SELECT_ADVENTURE,
    CREATE_NEW_ADVENTURE
} from '../constants';
import { Image, Tag } from '../../models';
import { normalizeAdventureOnSaga } from './utils';
import { history } from '../../routes';
import { cloneAdventureError, cloneAdventureSuccess } from './actions';
import { validateAdventureFields } from '../../helpers/utils';
import { CLONE_ADVENTURE } from './constants';

/**
 * Saga para carregar as aventuras da empresa
 */
export function* getCompanyAdventures(){
    const res = yield call(AdventureService.getCompanyAdventures)
    if (res.status === 200) {
        const adventures = []
        yield all(res.data.map(function*(element){
            const adv_m = yield call(normalizeAdventureOnSaga, element)
            adventures.push(adv_m.id)
        }))

        yield put(fetchCompanyAdventuresSuccess(adventures));
    } else {
        yield put(fetchCompanyAdventuresError(res));
    }
}

/**
 * Saga para carregar as categorias das aventuras
 */
export function* getAdventuresCategories(){
    const res = yield call(AdventureService.getAdventuresCategories)
    if (res.status === 200) {
        const cats_ids = []
        yield all(res.data.map(function*(cat){
            const createCategoryAction = yield put(createNewCategory(cat));
            cats_ids.push(createCategoryAction.payload.id)
        }))

        yield put(fetchAdventuresCategoriesSuccess(cats_ids))
    } else {
        yield put(fetchAdventuresCategoriesError(res))
    }
} 

/**
 * Saga para carregar as tags das aventuras
 */
export function* getAdventuresTags(){
    const res = yield call(AdventureService.getAdventuresTags)
    if (res.status === 200) {
        const tagsIds = []
        yield all(res.data.map(function*(tag){
            const tag_m = new Tag(tag)
            yield put(createTag(tag_m))
            tagsIds.push(tag_m.id)
        }))
        yield put(fetchAdventuresTagsSuccess(tagsIds))
    } else {
        yield put(fetchAdventuresTagsError(res))
    }
}

/**
 * Saga para salvar a aventura sendo editada
 * @param {Object} action Action disparada 
 */
export function* saveAdventureDraft({ payload }){
    const { updatedFields } = yield select(state => state.Adventures)
    const errors = validateAdventureFields(['title', 'fk_category', 'long_description', 'street', 'requestCustomFields', 'reserveCustomFields', payload.sell_type], payload)
    if (errors.length) {
        return yield put(saveDraftAdventureError(errors[0]))
    }

    const adventureUpdated = Object.keys(updatedFields).reduce((adv, field) => ({ ...adv, [field]: payload[field] }) , { pk_adventure: payload.pk_adventure })

    const res = yield call(AdventureService.saveDraftAdventure, adventureUpdated)
    if (res.status === 200) {
        yield call(normalizeAdventureOnSaga, { ...res.data, id: payload.id })
        yield put(saveDraftAdventureSuccess())
        //yield call(history.push, '/adventures/all')
    } else {
        yield put(saveDraftAdventureError(res))
    }
}

export function* cloneAdventureSaga({ payload }){
    const res = yield call(AdventureService.cloneAdventure, payload )
    if (res.status === 200) {
        const newAdventure = yield call(normalizeAdventureOnSaga, res.data)
        yield put(cloneAdventureSuccess(newAdventure.id))
    } else {
        yield put(cloneAdventureError(res))
    }
}

/**
 * Saga para enviar uma imagem para a AWS, sem atrelar a uma aventura ou realizador
 * @param {Object} action Action disparada 
 */
export function* uploadImage({ payload }){
    const data = new FormData();
    data.append('image', payload.image)

    const res = yield call(AdventureService.uploadImageToAws, data)
    if (res.status === 200) {
        const image = new Image({ ...payload, url: res.data.uploadedImage })
        yield put(createImage(image))
        yield put(uploadImageFileToAwsSuccess())
    } else {
        yield put(uploadImageFileToAwsError(res))
    }
}

/**
 * Saga para navegar para a URL da aventura selecionada para edição
 * @param {Object} action 
 */
export function* navigateToFormAdventure({ payload }){
    const state = yield select()
    // Se for deselecionar uma aventura, ignora
    if  (payload.id === null) return
    // Caso exista a aventura, navega para a pagina de edição
    // do contrario, exibe um erro e volta para a lista
    if (state.entities.adventure.byId[payload.id]) {
        yield call(history.push, `/adventures/edit/${payload.id}`)
    } else {
        yield put(selectAdventureError({ message: 'Falha ao carregar aventura' }))
        yield call(history.push, `/adventures/all`)
    }
}

export function* sendNewAdventure({ payload }){
    // Salva na API
    // Se sucesso, atualiza com a pk
    // Se falha, coloca error
    const res = yield call(AdventureService.createAdventure, payload)
    if (res.status === 200) {
        if (payload.step === 2 || (payload.step === 1 && payload.sell_type === 'noSell')) {
            yield call(normalizeAdventureOnSaga, { ...res.data, id: payload.id })
            yield put(clearCurrentAdenture())
            yield put(createNewAdventureSuccess())
            yield call(history.push, `/adventures/all`)
        } else {
            yield put(updateAdventure({ id: payload.id, pk_adventure: res.data.pk_adventure }))
            yield put(createNewAdventureSuccess())
        }
    } else {
        yield put(createNewAdventureError(res))
    }
}

// Observer das actions
export function* watchFetchCompanyAdventures(){
    yield takeEvery(FETCH_COMPANY_ADVENTURES, getCompanyAdventures)
}

export function* watchFetchAdventuresCategories(){
    yield takeEvery(FETCH_ADVENTURES_CATEGORIES, getAdventuresCategories)
}

export function* watchSaveAdventureDraft(){
    yield takeEvery(SAVE_DRAFT_ADVENTURE, saveAdventureDraft)
}

export function* watchUploadImageFileToAws(){
    yield takeEvery(UPLOAD_IMAGE_FILE_TO_AWS, uploadImage)
}

export function* watchFetchAdventuresTags(){
    yield takeEvery(FETCH_ADVENTURES_TAGS, getAdventuresTags)
}

export function* watchSelectAdventure(){
    yield takeEvery(SELECT_ADVENTURE, navigateToFormAdventure)
}

export function* watchCreateNewAdventure(){
    yield takeEvery(CREATE_NEW_ADVENTURE, sendNewAdventure)
}

export function* watchCloneAdventure(){
    yield takeEvery(CLONE_ADVENTURE, cloneAdventureSaga)
}

function* adventuresSaga(){
    yield all([
        fork(watchFetchCompanyAdventures),
        fork(watchSaveAdventureDraft),
        fork(watchCloneAdventure),
        fork(watchFetchAdventuresCategories),
        fork(watchUploadImageFileToAws),
        fork(watchFetchAdventuresTags),
        fork(watchSelectAdventure),
        fork(watchCreateNewAdventure)
    ])
}

export default adventuresSaga