import React from 'react'
import { Check, PlayCircle } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { WIZARDS } from '.'
import { changeOpenWizard, completeWizard } from '../../redux/actions'
import { CardHorizontal, CARD_ACTION_COLORS } from '../Cards'
import { Wizard } from './Wizard'

export const PublicProfileWizardCard = ({ onClick, completed = false, CTA = false }) => {
    return (
        <CardHorizontal color={!!completed ? CARD_ACTION_COLORS.SUCCESS : CARD_ACTION_COLORS.DEFAULT} onClick={onClick}>
            <FormGroup className="position-relative">
                <Label>Editar Perfil Público</Label>
                <p>Edite seu perfil com as informações que serão mostradas nos canais de venda.</p>
                {!!completed && <Check style={{ position: 'absolute', top: 0, right: 0 }} color={CARD_ACTION_COLORS.SUCCESS} />}
            </FormGroup>
            <FormGroup>
                <Button color="primary" outline={!CTA}><PlayCircle /> Iniciar</Button>
            </FormGroup>
        </CardHorizontal>
    )
}

export const PublicProfileWizard = ({}) => { 
    const { openWizard } = useSelector(state => state.app)
    const dispatch = useDispatch()

    return (
        <Wizard title="Editar Perfil Público" open={openWizard === WIZARDS.PUBLIC_PROFILE} onClose={() => dispatch(changeOpenWizard())}>
            <Row>
                <Col>
                    <p><strong>1.</strong> No menu, clique na seta ao lado do nome da empresa e clique em MEU NEGÓCIO.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_32.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>2.</strong> Confirme sua senha.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_33.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>3.</strong> Selecione a aba PERFIL PÚBLICO.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_34.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>4.</strong> Preencha os campos do formulário. Confira os textos de apoio de cada campo para mais informações.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_35.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>5.</strong> Clique em SALVAR para receber a mensagem de confirmação.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_36.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={() => dispatch(completeWizard(WIZARDS.PUBLIC_PROFILE))} outline><Check /> Concluir</Button>
                </Col>
            </Row>
        </Wizard>
    )
}
