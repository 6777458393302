
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    CLEAR_FORGET_PASSWORD,
    UPDATE_COMPANY_TAGS_SUCCESS,
    UPDATE_COMPANY_TAGS_ERROR
} from '../constants';
import { UPDATE_USER_FIELDS, SAVE_BUSINESS_ATTRIBUTES, SAVE_BUSINESS_ATTRIBUTES_SUCCESS, SAVE_BUSINESS_ATTRIBUTES_ERROR, SAVE_BANK_ATTRIBUTES, SAVE_BANK_ATTRIBUTES_SUCCESS, SAVE_BANK_ATTRIBUTES_ERROR, SEND_DOCUMENTS, SEND_DOCUMENTS_SUCCESS, SEND_DOCUMENTS_ERROR, CLEAR_SUCCESS, UPDATE_COMPANY_TAGS, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR, SAVE_PAGARME_SETTINGS, SAVE_PAGARME_SETTINGS_SUCCESS, SAVE_PAGARME_SETTINGS_ERROR, SAVE_MERCADO_PAGO_TOKEN, SAVE_MERCADO_PAGO_TOKEN_SUCCESS, SAVE_MERCADO_PAGO_TOKEN_ERROR, SAVE_PAG_SEGURO_CREDENTIALS, SAVE_PAG_SEGURO_CREDENTIALS_SUCCESS, SAVE_PAG_SEGURO_CREDENTIALS_ERROR, SAVE_GETNET_CREDENTIALS, SAVE_GETNET_CREDENTIALS_SUCCESS, SAVE_GETNET_CREDENTIALS_ERROR, CREATE_PIX_KEY, CREATE_PIX_KEY_SUCCESS, CREATE_PIX_KEY_ERROR } from './constants';


export const loginUser = (email, password) => ({
    type: LOGIN_USER,
    payload: { email, password },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserError = (error) => ({
    type: LOGIN_USER_ERROR,
    payload: error,
});

export const registerUser = (data) => ({
    type: REGISTER_USER,
    payload: data,
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserError = (error) => ({
    type: REGISTER_USER_ERROR,
    payload: error,
});

export const updateUser = (data) => ({
    type: UPDATE_USER,
    payload: data,
});

export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: user,
});

export const updateUserError = (error) => ({
    type: UPDATE_USER_ERROR,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordError = (error) => ({
    type: FORGET_PASSWORD_ERROR,
    payload: error,
});

export const clearForgetPassword = () => ({
    type: CLEAR_FORGET_PASSWORD
})

export const updateUserFields = (data) => ({
    type: UPDATE_USER_FIELDS,
    payload: data
})

export const saveBusinessAttributes = (data) => ({
    type: SAVE_BUSINESS_ATTRIBUTES,
    payload: data
})

export const saveBusinessAttributesSuccess = (data) => ({
    type: SAVE_BUSINESS_ATTRIBUTES_SUCCESS,
    payload: data
})

export const saveBusinessAttributesError = (err) => ({
    type: SAVE_BUSINESS_ATTRIBUTES_ERROR,
    payload: err
})

export const saveBankAttributes = (data) => ({
    type: SAVE_BANK_ATTRIBUTES,
    payload: data
})

export const saveBankAttributesSuccess = (data) => ({
    type: SAVE_BANK_ATTRIBUTES_SUCCESS,
    payload: data
})

export const saveBankAttributesError = (err) => ({
    type: SAVE_BANK_ATTRIBUTES_ERROR,
    payload: err
})

export const sendDocuments = (data) => ({
    type: SEND_DOCUMENTS,
    payload: data
})

export const sendDocumentsSuccess = (data) => ({
    type: SEND_DOCUMENTS_SUCCESS,
    payload: data
})

export const sendDocumentsError = (err) => ({
    type: SEND_DOCUMENTS_ERROR,
    payload: err
})

export const clearSuccess = () => ({
    type: CLEAR_SUCCESS
})

export const savePagarmeSettings = (data) => ({
    type: SAVE_PAGARME_SETTINGS,
    payload: data
})

export const savePagarmeSettingsSuccess = () => ({
    type: SAVE_PAGARME_SETTINGS_SUCCESS
})

export const savePagarmeSettingsError = (err) => ({
    type: SAVE_PAGARME_SETTINGS_ERROR,
    payload: err
})

export const saveMercadoPagoToken = (data) => ({
    type: SAVE_MERCADO_PAGO_TOKEN,
    payload: data
})

export const saveMercadoPagoTokenSuccess = () => ({
    type: SAVE_MERCADO_PAGO_TOKEN_SUCCESS
})

export const saveMercadoPagoTokenError = (err) => ({
    type: SAVE_MERCADO_PAGO_TOKEN_ERROR,
    payload: err
})

export const savePagSeguroCredentials = (data) => ({
    type: SAVE_PAG_SEGURO_CREDENTIALS,
    payload: data
})

export const savePagSeguroCredentialsSuccess = () => ({
    type: SAVE_PAG_SEGURO_CREDENTIALS_SUCCESS
})

export const savePagSeguroCredentialsError = (err) => ({
    type: SAVE_PAG_SEGURO_CREDENTIALS_ERROR,
    payload: err
})

export const saveGetnetCredentials = (data) => ({
    type: SAVE_GETNET_CREDENTIALS,
    payload: data
})

export const saveGetnetCredentialsSuccess = () => ({
    type: SAVE_GETNET_CREDENTIALS_SUCCESS
})

export const saveGetnetCredentialsError = (err) => ({
    type: SAVE_GETNET_CREDENTIALS_ERROR,
    payload: err
})

export const updateCompanyTags = (tags) => ({
    type: UPDATE_COMPANY_TAGS,
    payload: tags
})

export const updateCompanyTagsSuccess = () => ({
    type: UPDATE_COMPANY_TAGS_SUCCESS,
})

export const updateCompanyTagsError = (err) => ({
    type: UPDATE_COMPANY_TAGS_ERROR,
    payload: err
})

export const changePassword = (password) => ({
    type: CHANGE_PASSWORD,
    payload: { password }
})

export const changePasswordSucces = () => ({
    type: CHANGE_PASSWORD_SUCCESS
})

export const changePasswordError = (err) => ({
    type: CHANGE_PASSWORD_ERROR,
    payload: err
})

export const createPixKey = () => ({
    type: CREATE_PIX_KEY
})

export const createPixKeySuccess = () => ({
    type: CREATE_PIX_KEY_SUCCESS
})

export const createPixKeyError = () => ({
    type: CREATE_PIX_KEY_ERROR
})