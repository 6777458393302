import {
    CREATE_NEW_FAQ,
    EDIT_FAQ,
    DELETE_FAQ
} from '../../constants'

import { FAQ } from '../../../models'

export const createNewFAQ = (data) => ({
    type: CREATE_NEW_FAQ,
    payload: new FAQ(data)
})

export const editFAQ = (data) => ({
    type: EDIT_FAQ,
    payload: data
})

export const deleteFAQ = id => ({
    type: DELETE_FAQ,
    payload: { id }
})