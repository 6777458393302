import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	CREATE_CLIENT,
	CREATE_CLIENT_FAILURE,
	CREATE_CLIENT_SUCCESS,
	FETCH_CLIENT_LIST,
	FETCH_CLIENT_LIST_FAILURE,
	FETCH_CLIENT_LIST_SUCCESS,
	EDIT_CLIENT,
	EDIT_CLIENT_SUCCESS,
	EDIT_CLIENT_FAILURE,
	DELETE_CLIENT,
	DELETE_CLIENT_SUCCESS,
	DELETE_CLIENT_FAILURE, EXPORT_CLIENT_CSV, EXPORT_CLIENT_CSV_SUCESS, EXPORT_CLIENT_CSV_FAILURE
} from './constants';
import ClientsService from './service';

function* fetchClients({ payload }) {
	try {
		const res = yield call(ClientsService.getClients, payload);
		yield put({ type: FETCH_CLIENT_LIST_SUCCESS, payload: { items: res.data.leads, total: res.data.total } });
	} catch (error) {
		yield put({ type: FETCH_CLIENT_LIST_FAILURE, payload: error });
	}
}

function* createClient({ payload }) {
	try {
		const createRes = yield call(ClientsService.createClient, payload);
		yield put({ type: CREATE_CLIENT_SUCCESS, payload: createRes.data });
	} catch (error) {
		yield put({ type: CREATE_CLIENT_FAILURE, payload: error })
	}
}

function* editClient({ payload }) {
	try {
		const res = yield call(ClientsService.editClient, payload);
		yield put({ type: EDIT_CLIENT_SUCCESS, payload: res.data.client })
	} catch (error) {
		yield put({ type: EDIT_CLIENT_FAILURE, payload: error })
	}
}

function* deleteClient({ payload }) {
	try {
		yield call(ClientsService.deleteClient, payload);
		yield put({ type: DELETE_CLIENT_SUCCESS, payload: payload })
	} catch (error) {
		yield put({ type: DELETE_CLIENT_FAILURE, payload: error })
	}
}

function* exportCsv() {
	try {
		yield call(ClientsService.exportCsv);
		yield put({ type: EXPORT_CLIENT_CSV_SUCESS })
	} catch (error) {
		yield put({ type: EXPORT_CLIENT_CSV_FAILURE, payload: error })
	}
}

export function* watchFetchClients() {
	yield takeLatest(FETCH_CLIENT_LIST, fetchClients);
}

export function* watchCreateClient() {
	yield takeEvery(CREATE_CLIENT, createClient);
}

export function* watchEditClient() {
	yield takeLatest(EDIT_CLIENT, editClient);
}

export function* watchDeleteClient() {
	yield takeLatest(DELETE_CLIENT, deleteClient);
}

export function* watchExportClientCsv() {
	yield takeLatest(EXPORT_CLIENT_CSV, exportCsv);
}

function* clientsSaga() {
	yield all([
		fork(watchFetchClients),
		fork(watchCreateClient),
		fork(watchEditClient),
		fork(watchDeleteClient),
		fork(watchExportClientCsv),
	])
}

export default clientsSaga