import {
    CREATE_VOUCHER,
    UPDATE_VOUCHER,
    DELETE_VOUCHER
} from '../../constants'

import { Voucher } from '../../../models'

export const createVoucher = (data) => ({
    type: CREATE_VOUCHER,
    payload: new Voucher(data)
})

export const updateVoucher = (data) => ({
    type: UPDATE_VOUCHER,
    payload: data
})

export const deleteVoucher = (id) => ({
    type: DELETE_VOUCHER,
    payload: { id }
})