import {
    CREATE_REQUEST_CUSTOM_FIELD,
    UPDATE_REQUEST_CUSTOM_FIELD,
    DELETE_REQUEST_CUSTOM_FIELD
} from '../../constants'
import { RequestCustomField } from '../../../models'

export const createRequestCustomField = data => ({
    type: CREATE_REQUEST_CUSTOM_FIELD,
    payload: new RequestCustomField(data)
})

export const updateRequestCustomField = data => ({
    type: UPDATE_REQUEST_CUSTOM_FIELD,
    payload: data
})

export const deleteRequestCustomField = id => ({
    type: DELETE_REQUEST_CUSTOM_FIELD,
    payload: { id }
})