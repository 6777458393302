import {
    FETCH_COMPANY_PURCHASES,
    FETCH_COMPANY_PURCHASES_ERROR,
    FETCH_COMPANY_PURCHASES_SUCCESS,
    SAVE_NEW_VOUCHER,
    SAVE_NEW_VOUCHER_SUCCESS,
    SAVE_NEW_VOUCHER_ERROR,
    CLEAR_ERROR,
    CONSUME_VOUCHER,
    CONSUME_VOUCHER_ERROR,
    CONSUME_VOUCHER_SUCCESS,
    SAVE_VOUCHER_INFO,
    SAVE_VOUCHER_INFO_SUCCESS,
    SAVE_VOUCHER_INFO_ERROR,
    CANCEL_VOUCHER, 
    CANCEL_VOUCHER_SUCCESS, 
    CANCEL_VOUCHER_ERROR
} from '../constants'
import { LOGOUT_USER } from '../auth/constants'
import { TOGGLE_RESERVE, TOGGLE_RESERVE_ERROR, TOGGLE_RESERVE_SUCCESS, TOGGLE_VOUCHER, TOGGLE_VOUCHER_ERROR, TOGGLE_VOUCHER_SUCCESS } from './constants'

const INITIAL_STATE = {
    loadingPurchases: false,
    loadingSaveVoucher: false,
    loadingConsumeVoucher: false,
    loadingCancelVoucher: false,
    loadingSaveVoucherInfo: false,
    loadingToggleReserve: false,
    purchases: [],
    error: null
}

const Vouchers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPANY_PURCHASES:
            return { ...state, loadingPurchases: true }
        case FETCH_COMPANY_PURCHASES_SUCCESS:
            return { ...state, purchases: action.payload.page === 1 ? [ ...action.payload.items ] : [ ...state.purchases, ...action.payload.items ], loadingPurchases: false }
        case FETCH_COMPANY_PURCHASES_ERROR:
            return { ...state, error: { ...action.payload }, loadingPurchases: false }
        case CLEAR_ERROR:
            return { ...state, error: null }
        case SAVE_NEW_VOUCHER:
            return { ...state, loadingSaveVoucher: true }
        case SAVE_NEW_VOUCHER_SUCCESS:
            return { ...state, loadingSaveVoucher: false, purchases: [ ...action.payload, ...state.purchases ]}
        case SAVE_NEW_VOUCHER_ERROR:
            return { ...state, loadingSaveVoucher: false, error: { ...action.payload } }
        case CONSUME_VOUCHER:
            return { ...state, loadingConsumeVoucher: true }
        case CONSUME_VOUCHER_ERROR:
            return { ...state, loadingConsumeVoucher: false, error: { ...action.payload } }
        case CONSUME_VOUCHER_SUCCESS:
            return { ...state, loadingConsumeVoucher: false }
        case CANCEL_VOUCHER:
            return { ...state, loadingCancelVoucher: true }
        case CANCEL_VOUCHER_SUCCESS:
            return { ...state, loadingCancelVoucher: false }
        case CANCEL_VOUCHER_ERROR:
            return { ...state, loadingCancelVoucher: false, error: { ...action.payload }}
        case TOGGLE_VOUCHER:
            return { ...state, loadingToggleVoucher: true }
        case TOGGLE_VOUCHER_SUCCESS:
            return { ...state, loadingToggleVoucher: false }
        case TOGGLE_VOUCHER_ERROR:
            return { ...state, loadingToggleVoucher: false, error: action.payload }
        case TOGGLE_RESERVE:
            return { ...state, loadingToggleReserve: true }
        case TOGGLE_RESERVE_SUCCESS:
            return { ...state, loadingToggleReserve: false }
        case TOGGLE_RESERVE_ERROR:
            return { ...state, loadingToggleReserve: false, error: action.payload }
        case SAVE_VOUCHER_INFO:
            return { ...state, loadingSaveVoucherInfo: true }
        case SAVE_VOUCHER_INFO_SUCCESS:
            return { ...state, loadingSaveVoucherInfo: false }
        case SAVE_VOUCHER_INFO_ERROR:
            return { ...state, loadingSaveVoucherInfo: false, error: { ...action.paylaod }}
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            return state
    }
}

export default Vouchers