import { combineReducers } from "redux";
import { 
    LOGOUT_USER, 
    REFRESH_STORE, 
    CREATE_REQUEST_BONUS, 
    UPDATE_REQUEST_BONUS, 
    DELETE_REQUEST_BONUS 
} from "../../constants";

export const requestBonusById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REQUEST_BONUS:
            return { ...state, [action.payload.id]: action.payload }

        case UPDATE_REQUEST_BONUS:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }

        case DELETE_REQUEST_BONUS:
            return { ...state, [action.payload.id]: undefined }

        case LOGOUT_USER:
            return {}

        case REFRESH_STORE:
            return {}
    
        default:
            return state;
    }
}

export const requestBonusAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_REQUEST_BONUS:
            return [ ...state, action.payload.id ]

        case DELETE_REQUEST_BONUS:
            return state.reduce((prevState, id) => id === action.payload.id ? prevState : [ ...prevState, action.payload.id ] , [])
        
        case LOGOUT_USER:
            return []

        case REFRESH_STORE:
            return []
    
        default:
            return state
    }
}


export default combineReducers({
    byId: requestBonusById,
    allIds: requestBonusAllIds
})