import React from 'react'
import { Check, PlayCircle } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { WIZARDS } from '.'
import { changeOpenWizard, completeWizard } from '../../redux/actions'
import { CardHorizontal, CARD_ACTION_COLORS } from '../Cards'
import { Wizard } from './Wizard'

export const ReserveWizardCard = ({ onClick, completed = false, CTA = false }) => {
    return (
        <CardHorizontal color={!!completed ? CARD_ACTION_COLORS.SUCCESS : CARD_ACTION_COLORS.DEFAULT} onClick={onClick}>
            <FormGroup className="position-relative">
                <Label>Crie um ingresso</Label>
                <p>Crie um ingresso para oferecer separadamente, ou vincule-o a um evento..</p>
                {!!completed && <Check style={{ position: 'absolute', top: 0, right: 0 }} color={CARD_ACTION_COLORS.SUCCESS} />}
            </FormGroup>
            <FormGroup>
                <Button color="primary" outline={!CTA}><PlayCircle /> Iniciar</Button>
            </FormGroup>
        </CardHorizontal>
    )
}

export const ReserveWizard = ({}) => { 
    const { openWizard } = useSelector(state => state.app)
    const dispatch = useDispatch()

    return (
        <Wizard title="Crie um ingresso" open={openWizard === WIZARDS.RESERVE} onClose={() => dispatch(changeOpenWizard())}>
            <Row>
                <Col>
                    <p><strong>1.</strong> No menu, clique em AGENDA, selecione no calendário um dos dias que tenha um evento já criado e entre na tela do evento.</p>
                    <p>Você consegue identificar no calendário os dias que já possuem evento, procurando pelas bolinhas coloridas.</p>
                    <p>Para diferenciar os eventos dos horários livres existem as bolinhas VERMELHAS, quando é um evento exclusivo ou sem vagas disponíveis, ou as AMARELAS, quando é um evento não exclusivo e com vagas.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_55.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>2.</strong> Ao entrar na tela do evento, clique no botão CRIAR INGRESSO. </p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_56.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>3.</strong> Ao abrir o modal para CRIAR INGRESSO, você precisará escolher um dos tipos de ingresso e a quantidade dele que será atribuído a um cliente participante.</p>                    
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_57.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>4.</strong> Se desejar criar ingressos de tipos diferentes, mesmo que seja para o mesmo participante, basta clicar em INCLUIR OUTRO TIPO, depois escolher o tipo de ingresso e a quantidade.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_97.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>5.</strong> O passo seguinte é inserir os dados do participante, ou seja, as informações que vão constar no(s) ingresso(s) selecionado(s).</p>
                    <p>As informações solicitadas aqui são as que foram previamente configuradas na experiência. Não é obrigatório que você preencha todos os campos para poder criar os ingressos.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_98.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>6.</strong> Por fim, você pode optar em salvar, criando estes ingressos, e voltar para a tela do evento. Ou também pode criar os ingressos e já reabrir o mesmo modal de criação, para que possa criar mais ingressos.</p>
                    <p>Ao multiplicar os ingressos, você está atribuindo mais ingressos para a mesma pessoa. Então, esta função de salvar e criar outro é importante para quando você precisa criar ingressos inserindo os dados de cada um dos participantes, um diferente do outro.</p>
                    <p>Por exemplo, você cria um ingresso inserindo os dados da pessoa que comprou, clica em SALVAR E CRIAR OUTRO, cria outro ingresso com as informações do acompanhante e, para terminar, clica em SALVAR E SAIR.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_100.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>7.</strong> Após ter terminado, retornará para a tela do evento e verá o(s) ingresso(s) recém criados.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_99.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={() => dispatch(completeWizard(WIZARDS.RESERVE))} outline><Check /> Concluir</Button>
                </Col>
            </Row>
        </Wizard>
    )
}
