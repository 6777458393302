import VouchersService from './service'
import ReservationService from './../reservation/service'
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
    FETCH_COMPANY_PURCHASES,
    SAVE_NEW_VOUCHER,
    CONSUME_VOUCHER,
    CANCEL_VOUCHER,
    SAVE_VOUCHER_INFO
} from '../constants'
import {
    fetchCompanyPurchasesSuccess,
    fetchCompanyPurchasesError,
    saveNewVoucherSuccess,
    saveNewVoucherError,
    consumeVoucherError,
    consumeVoucherSuccess,
    cancelVoucherSuccess,
    cancelVoucherError,
    saveVoucherInfoSuccess,
    saveVoucherInfoError
} from '../actions'
import { normalizeReserveOnSaga } from '../reservation/utils'
import { normalizeReserve } from '../../models';
import { fetchCompanyPurchases, toggleReserveError, toggleReserveSuccess, toggleVoucherError, toggleVoucherSuccess } from './actions';
import { batchCreateReserveEntity, updateReserveEntity } from '../entities/reserve/actions';
import { FIRST_LOAD_VOUCHERS, TOGGLE_RESERVE, TOGGLE_VOUCHER } from './constants';
import { fetchCompanyAdventures } from '../adventures/actions';

export function* getCompanyPurchases({ payload }) {
    const res = yield call(VouchersService.getCompanyPurchases, payload)
    // Criar vouchers
    if (res.status === 200) {
        const voucher_ids = []
        yield all(res.data.reserves.map(function*(voucher){
            const voucher_m = yield call(normalizeReserveOnSaga,voucher)
            voucher_ids.push(voucher_m.id)
        }))
        yield put(fetchCompanyPurchasesSuccess(voucher_ids, payload.page))
    } else {
        yield put(fetchCompanyPurchasesError(res))
    }
}

export function* firstLoadVouchersSaga() {
    yield put(fetchCompanyAdventures())
    yield put(fetchCompanyPurchases())
}

export function* createNewVoucher({ payload }) {
    const res = yield call(VouchersService.saveVoucher, payload)
    if (res.status === 200) {
        const voucher_ids = []
        const voucher_ms = yield all(res.data.map(function*(voucher){
            const voucher_m = yield call(normalizeReserveOnSaga, voucher, false)
            voucher_ids.push(voucher_m.id)
            return voucher_m
        }))
        
        yield put(batchCreateReserveEntity(voucher_ms))
        yield put(saveNewVoucherSuccess(voucher_ids))
    } else {
        yield put(saveNewVoucherError(res))
    }
}

export function* toggleVoucher({ payload }) {
    if(!Array.isArray(payload))
        payload = [payload]

    const res = yield call(VouchersService.toggleVoucher, payload.map(item => item.pk_reserve))
    if (res.status === 200) {
        for (let i = 0; i < payload.length; i++) {
            const element = payload[i];
            const { expiration_date, is_consumed } = res.data.find(item => item.pk_reserve === element.pk_reserve )
            yield put(updateReserveEntity(normalizeReserve({ id: element.id, expiration_date, fk_request: null, request_id: null, is_consumed })))
        }

        yield put(toggleVoucherSuccess())
    } else {
        yield put(toggleVoucherError(res))
    }
}

export function* toggleReserveSaga({ payload }) {
    const res = yield call(VouchersService.toggleReserve, payload)

    if (res.status === 200) {
        const updatedReserve =  yield call(normalizeReserveOnSaga, { id: payload.id, ...res.data })
        yield put(updateReserveEntity(updatedReserve))
        yield put(toggleReserveSuccess())
    } else {
        yield put(toggleReserveError(res))
    }
}

export function* consumeVoucher({ payload }) {
    if(!Array.isArray(payload))
        payload = [payload]

    const res = yield call(VouchersService.consumeVoucher, payload.map(item => item.pk_reserve))
    if (res.status === 200) {
        for (let i = 0; i < payload.length; i++) {
            const element = payload[i];
            yield put(updateReserveEntity(normalizeReserve({ id: element.id, is_consumed: true })))
        }

        yield put(consumeVoucherSuccess())
    } else {
        yield put(consumeVoucherError(res))
    }
}

export function* cancelVoucher({ payload }) {
    if(!Array.isArray(payload))
        payload = [payload]
    const res = yield call(VouchersService.cancelVoucher, payload.map(item => item.pk_reserve))
    if (res.status === 200) {
        for (let i = 0; i < payload.length; i++) {
            const element = payload[i];
            yield put(updateReserveEntity(normalizeReserve({ id: element.id, is_deleted: true })))
        }

        yield put(cancelVoucherSuccess())
    } else {
        yield put(cancelVoucherError(res))
    }
}

export function* saveVoucherInfo({ payload }) {
    const voucher = payload
    const res = yield call(VouchersService.saveVoucherInfo, voucher)
    if (res.status === 200) {
        yield put(updateReserveEntity(normalizeReserve({ ...voucher })))
        yield put(saveVoucherInfoSuccess())
    } else {
        yield put(saveVoucherInfoError(res))
    }
}

export function* watchFetchCompanyPurchases() {
    yield takeEvery(FETCH_COMPANY_PURCHASES, getCompanyPurchases)
}

export function* watchToggleVoucer() {
    yield takeEvery(TOGGLE_VOUCHER, toggleVoucher)
}

export function* watchToggleReserve() {
    yield takeEvery(TOGGLE_RESERVE, toggleReserveSaga)
}

export function* watchSaveNewVoucher() {
    yield takeEvery(SAVE_NEW_VOUCHER, createNewVoucher)
}

export function* watchConsumeVoucher() {
    yield takeEvery(CONSUME_VOUCHER, consumeVoucher)
}

export function* watchCancelVoucher() {
    yield takeEvery(CANCEL_VOUCHER, cancelVoucher)
}

export function* watchSaveVoucherInfo() {
    yield takeEvery(SAVE_VOUCHER_INFO, saveVoucherInfo)
}

export function* watchFirstLoadVouchers() {
    yield takeEvery(FIRST_LOAD_VOUCHERS, firstLoadVouchersSaga)
}

function* vouchersSaga(){
    yield all([
        fork(watchFetchCompanyPurchases), 
        fork(watchFirstLoadVouchers),
        fork(watchToggleVoucer), 
        fork(watchToggleReserve), 
        fork(watchSaveNewVoucher),
        fork(watchConsumeVoucher),
        fork(watchCancelVoucher),
        fork(watchSaveVoucherInfo)
    ])
}

export default vouchersSaga