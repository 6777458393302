import {
    CREATE_SCHEDULE_RULE,
    UPDATE_SCHEDULE_RULE,
    DELETE_SCHEDULE_RULE
} from '../../constants'
import { ScheduleRule } from '../../../models'

export const createScheduleRule = (data) => ({
    type: CREATE_SCHEDULE_RULE,
    payload: new ScheduleRule(data)
})

export const updateScheduleRule = (data) => ({
    type: UPDATE_SCHEDULE_RULE,
    payload: data
})

export const deletescheduleRule = id => ({
    type: DELETE_SCHEDULE_RULE,
    payload: { id }
})