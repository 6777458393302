import { v4 as uuid4 } from 'uuid'
export class Script {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_script = json.pk_script || null
        this.fk_adventure = json.fk_adventure || null
        this.adventure_id = json.adventure_id || null
        this.day = json.day || ''
        this.items = json.items || []
    }
}

export class ScriptItem {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_script_item = json.pk_script_item || null
        this.fk_script = json.fk_script || null
        this.script_id = json.script_id || null
        this.description = json.description || ''
        this.minutes = json.minutes || ''
    }
}