export const FETCH_COMPANY_PURCHASES = 'FETCH_COMPANY_PURCHASES'
export const FETCH_COMPANY_PURCHASES_SUCCESS = 'FETCH_COMPANY_PURCHASES_SUCCESS'
export const FETCH_COMPANY_PURCHASES_ERROR = 'FETCH_COMPANY_PURCHASES_ERROR'
export const SAVE_NEW_VOUCHER = 'SAVE_NEW_VOUCHER'
export const SAVE_NEW_VOUCHER_SUCCESS = 'SAVE_NEW_VOUCHER_SUCCESS'
export const SAVE_NEW_VOUCHER_ERROR = 'SAVE_NEW_VOUCHER_ERROR'
export const CONSUME_VOUCHER = 'CONSUME_VOUCHER'
export const CONSUME_VOUCHER_SUCCESS = 'CONSUME_VOUCHER_SUCCESS'
export const CONSUME_VOUCHER_ERROR = 'CONSUME_VOUCHER_ERROR'
export const CANCEL_VOUCHER = 'CANCEL_VOUCHER'
export const CANCEL_VOUCHER_SUCCESS = 'CANCEL_VOUCHER_SUCCESS'
export const CANCEL_VOUCHER_ERROR = 'CANCEL_VOUCHER_ERROR'
export const SAVE_VOUCHER_INFO = 'SAVE_VOUCHER_INFO'
export const SAVE_VOUCHER_INFO_SUCCESS = 'SAVE_VOUCHER_INFO_SUCCESS'
export const SAVE_VOUCHER_INFO_ERROR = 'SAVE_VOUCHER_INFO_ERROR'
export const TOGGLE_VOUCHER = 'TOGGLE_VOUCHER'
export const TOGGLE_VOUCHER_SUCCESS = 'TOGGLE_VOUCHER_SUCCESS'
export const TOGGLE_VOUCHER_ERROR = 'TOGGLE_VOUCHER_ERROR'
export const TOGGLE_RESERVE = 'TOGGLE_RESERVE'
export const TOGGLE_RESERVE_SUCCESS = 'TOGGLE_RESERVE_SUCCESS'
export const TOGGLE_RESERVE_ERROR = 'TOGGLE_RESERVE_ERROR'
export const FIRST_LOAD_VOUCHERS = 'FIRST_LOAD_VOUCHERS'