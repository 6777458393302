import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { fetchActivitiesError, fetchActivitiesSuccess } from './actions';
import {
	FETCH_ACTIVITIES,
} from './constants';
import ActivitiesService from './service';

function* fetchActivities({ payload }) {
        const res = yield call(ActivitiesService.getActivities, payload);
        if (res.status === 200) {
            yield put(fetchActivitiesSuccess(res.data));
        } else {
            yield put(fetchActivitiesError(res));
        }
}

export function* watchFetchClients() {
	yield takeLatest(FETCH_ACTIVITIES, fetchActivities);
}

function* clientsSaga() {
	yield all([
		fork(watchFetchClients),
	])
}

export default clientsSaga