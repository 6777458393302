import axios from 'axios'
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  return fetch(url, options)
    .then(response => {
      if (!response.status === 200) {
        throw response.json();
      }
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch(error => {
      throw error;
    });
};

const _env = () => {
  const location = window.location.href;
  if (location.includes('hero99')) {
    return 'homolog'
  } else if (location.includes('dev')) {
    return 'local'
  } else if (location.includes('localhost')) {
    return 'local'
  } else if (location.includes('adrenalyze.com.br') || location.includes('adrena.me') || location.includes('offstation.com.br')) {
    return 'prod'
  } else {
    return 'local'
  }
}

class Api {

  call(url, method = 'get', payload = {}, params = {}) {
    let contentType = 'application/x-www-form-urlencoded';

    if (params.json === true || method !== 'get') {
      contentType = 'application/json';
    }

    if (params.multiPart === true) {
      contentType = 'multipart/form-data';
    }

    return axios({
      url,
      method,
      baseURL: this.getBaseURL(),
      headers: {
        'Accept': 'application/json',
        'Content-Type': contentType,
        'Authorization': this.getAuthorization(),
        'OS-Profile': this.getProfile()
      },
      data: method !== 'get' ? payload : undefined,
      params: method === 'get' ? payload : undefined,
      ...params
    })
      .then(res => params.raw ? res : ({ ...res.data, status: res.status }))
      .catch(err => {
        if (!err.response) {
          return { message: 'Sem resposta do servidor' }
        } else {
          return { ...err.response.data.data, status: err.response.status }
        }
      });
  }

  getAuthorization() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user)
      return user.auth_key

    return null
  }

  getProfile() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user)
      return user.fantasy_name

    return null
  }

  getBaseURL() {
    if (_env() === 'prod') {
      return 'https://api.adrenalyze.com.br';
    } else if (_env() === 'homolog') {
      return 'https://api.hero99.adrenalyze.com.br';
    } else if (_env() === 'local') {
      return 'http://localhost:8090/api/web/';
      // return 'http://dev.back.adrenalyze.com.br';
    }
  }

}

export { fetchJSON, Api };
export default new Api();