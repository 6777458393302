import { v4 as uuid4 } from 'uuid'
import { RequestBonus } from './requestBonus'
import { RequestMessage } from './requestMessage'
import { denormalizeReserve } from './reserve'

export class Request {
    constructor(json={}){
        this.id = json.id || uuid4()
        this.pk_request = json.pk_request || null
        this.fk_adventure = json.fk_adventure || null
        this.adventure_id = json.adventure_id || null
        this.roca_id = json.roca_id || null
        this.reserves = json.reserves || []
        this.bonuses = json.bonuses || []
        this.packages = json.packages || []
        this.messages = json.messages || []
        this.tags = json.tags || []
        this.name = json.name || ''
        this.phone = json.phone || ''
        this.email = json.email || ''
        this.observation = json.observation || ''
        this.type = Number(json.type) || 1
        this.max = json.max || null
        this.date = json.date || ''
        this.created_at = json.created_at || ''
        this.short_url = json.short_url || ''
        this.custom_fields = json.custom_fields ? json.custom_fields.map(field => ({ ...field, id: uuid4() })) : []
    }
}

export const injectRequestAdventure = (request, entities) => {
    const adventureId = entities.adventure.allIds.find(id => entities.adventure.byId[id].pk_adventure === request.fk_adventure)
    request.adventure = { ...entities.adventure.byId[adventureId] }
    return request
}

export const denormalizeRequest = (request, entities, adventure = undefined) => {
    request.adventure = adventure || { ...entities.adventure.byId[request.adventure_id] }
    request.bonuses = request.bonuses.map(bonus_id => new RequestBonus(entities.requestBonus.byId[bonus_id]))
    request.messages = request.messages.map(message_id => new RequestMessage(entities.requestMessage.byId[message_id]))
    request.reserves = request.reserves.map(id => ({ ...denormalizeReserve({ ...entities.reserve.byId[id] }, entities, adventure) }))
    return { ...request }
}


export const normalizeRequest = (request) => {
    request.adventure = undefined
    request.reserves = request.reserves.map(res => res.id)
    request.bonuses = request.bonuses.map(bonus => bonus.id)
    request.messages = request.messages.map(message => message.id)
    return { ...request }
}