import { v4 as uuid4 } from 'uuid'
import { getRandomColor } from '../helpers/utils'
import moment from 'moment'

export class ScheduleRule {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_schedule_rule = json.pk_schedule_rule || null
        this.adventure_id = json.adventure_id || null
        this.fk_adventure = json.fk_adventure || null
        this.hour = json.hour || json.start || ''
        this.start = json.start || '' // TODO: Trocar pra hour
        
        if (json.repeat === undefined)
            this.repeat = !json.end_date || !moment(json.end_date).isSame(moment(json.start_date), 'day')
        else
            this.repeat = json.repeat

        this.start_date = json.start_date || ''
        this.end_date = moment(json.end_date).isValid() ? moment(json.end_date).format('YYYY-MM-DD') : json.end_date
        this.name = json.name || ''
        if (Array.isArray(json.weekdays)) {
            this.weekdays = json.weekdays.map((day) => day.pk_schedule_rule_weekday ? day.day : day)
        } else {
            this.weekdays = []
        }
        this.avaiablePackages = json.avaiablePackages || []
        this.tag = json.tag || getRandomColor()
        this.new = json.new || false
    }
}

export const denormalizeScheduleRuleForCalendar = (rule, entities, adventure = undefined) => {
    rule.adventure = adventure || { ...entities.adventure.byId[rule.adventure_id] }
    return { ...rule }
}