import {
	FETCH_CLIENT_LIST,
	FETCH_CLIENT_LIST_SUCCESS,
	FETCH_CLIENT_LIST_FAILURE,
	CREATE_CLIENT,
	CREATE_CLIENT_SUCCESS,
	CREATE_CLIENT_FAILURE,
	EDIT_CLIENT,
	EDIT_CLIENT_SUCCESS,
	EDIT_CLIENT_FAILURE,
	DELETE_CLIENT,
	DELETE_CLIENT_SUCCESS,
	DELETE_CLIENT_FAILURE,
	EXPORT_CLIENT_CSV, EXPORT_CLIENT_CSV_SUCESS, EXPORT_CLIENT_CSV_FAILURE
} from './constants';

export const fetchClientList = (payload) => ({
	type: FETCH_CLIENT_LIST,
	payload
});

export const fetchClientListSuccess = (payload) => ({
	type: FETCH_CLIENT_LIST_SUCCESS,
	payload
});

export const fetchClientListFailure = (payload) => ({
	type: FETCH_CLIENT_LIST_FAILURE,
	payload
});

export const createClient = (payload) => ({
	type: CREATE_CLIENT,
	payload
});

export const createClientSuccess = (payload) => ({
	type: CREATE_CLIENT_SUCCESS,
	payload
});

export const createClientFailure = (payload) => ({
	type: CREATE_CLIENT_FAILURE,
	payload
});

export const editClient = (payload) => ({
	type: EDIT_CLIENT,
	payload
});

export const editClientSucess = (payload) => ({
	type: EDIT_CLIENT_SUCCESS,
	payload
});

export const editClientFailure = (payload) => ({
	type: EDIT_CLIENT_FAILURE,
	payload
});

export const deleteClient = (payload) => ({
	type: DELETE_CLIENT,
	payload
});

export const deleteClientSuccess = (payload) => ({
	type: DELETE_CLIENT_SUCCESS,
	payload
});

export const deleteClienteFailure = (payload) => ({
	type: DELETE_CLIENT_FAILURE,
	payload
});

export const exportClientCsv = () => ({
	type: EXPORT_CLIENT_CSV
})

export const exportClientCsvSucess = () => ({
	type: EXPORT_CLIENT_CSV_SUCESS
})

export const exportClientCsvFailure = (err) => ({
	type: EXPORT_CLIENT_CSV_FAILURE,
	payload: err
})