import { v4 as uuid4 } from 'uuid'

export class Package {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_package = json.pk_package || null
        this.adventure_id = json.adventure_id || null
        this.is_visible = json.is_visible === undefined ? true : json.is_visible
        this.fk_adventure = json.fk_adventure || null
        this.name = json.name || ''
        this.price = json.price || ''
        this.priceWithDiscount = json.priceWithDiscount || ''
        this.description = json.description || ''
        this.discount = json.discount || ''
        this.max = json.max || undefined
        this.default = json.default || false
    }
}