import { 
    FETCH_NOTES_DATE,
    FETCH_NOTES_DATE_SUCCESS,
    FETCH_NOTES_DATE_ERROR,
    SAVE_NOTES,
    SAVE_NOTES_SUCCESS,
    SAVE_NOTES_ERROR,
    UPDATE_NOTES,
    UPDATE_NOTES_SUCCESS,
    UPDATE_NOTES_ERROR,
    COMPLETE_NOTES,
    COMPLETE_NOTES_SUCCESS,
    COMPLETE_NOTES_ERROR,
    DELETE_NOTES,
    DELETE_NOTES_SUCCESS,
    DELETE_NOTES_ERROR
} from './constants';

export const fetchNotesDate = (date) => ({
    type: FETCH_NOTES_DATE,
    payload: { date }
});

export const fetchNotesDateSuccess = (data) => ({
    type: FETCH_NOTES_DATE_SUCCESS,
    payload: data
});

export const fetchNotesDateError = (err) => ({
    type: FETCH_NOTES_DATE_ERROR,
    payload: err
});

export const saveNotes = (notes) => ({
    type: SAVE_NOTES,
    payload: notes
});

export const saveNotesSuccess = (data) => ({
    type: SAVE_NOTES_SUCCESS,
    payload: data
});

export const saveNotesError = (err) => ({
    type: SAVE_NOTES_ERROR,
    payload: err
});

export const updateNotes = (notes) => ({
    type: UPDATE_NOTES,
    payload: notes
});

export const updateNotesSuccess = (data) => ({
    type: UPDATE_NOTES_SUCCESS,
    payload: data
});

export const updateNotesError = (err) => ({
    type: UPDATE_NOTES_ERROR,
    payload: err
});

export const completeNotes = (notes) => ({
    type: COMPLETE_NOTES,
    payload: notes
});

export const completeNotesSuccess = (data) => ({
    type: COMPLETE_NOTES_SUCCESS,
    payload: data
});

export const completeNotesError = (err) => ({
    type: COMPLETE_NOTES_ERROR,
    payload: err
});

export const deleteNotes = (notes) => ({
    type: DELETE_NOTES,
    payload: notes
});

export const deleteNotesSuccess = (data) => ({
    type: DELETE_NOTES_SUCCESS,
    payload: data
});

export const deleteNotesError = (err) => ({
    type: DELETE_NOTES_ERROR,
    payload: err
});