import { combineReducers } from "redux";
import {
    CREATE_RESERVE_ENTITY,
    UPDATE_RESERVE_ENTITY,
    DELETE_RESERVE_ENTITY,
    BATCH_CREATE_RESERVE_ENTITY
} from '../../constants'
import { LOGOUT_USER } from "../../auth/constants";
import { REFRESH_STORE } from "../../app/constants";

export const reserveById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_RESERVE_ENTITY:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case BATCH_CREATE_RESERVE_ENTITY:
            return action.payload.reduce((prevState, item) => ({ ...prevState, [item.id]: { ...item }}), state)
        case UPDATE_RESERVE_ENTITY:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_RESERVE_ENTITY:
            return { ...state, [action.payload.id]: undefined }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export const reserveAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_RESERVE_ENTITY:
            return [ ...state, action.payload.id ]
        case BATCH_CREATE_RESERVE_ENTITY:
            return [ ...state, ...action.payload.map(item => item.id) ]
        case DELETE_RESERVE_ENTITY:
            return state.reduce((prevState,curId) => curId === action.payload.id ? prevState : [ ...prevState, curId],[])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}

export default combineReducers({
    byId: reserveById,
    allIds: reserveAllIds
})