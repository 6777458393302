import React from 'react'
import { Check, PlayCircle } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { WIZARDS } from '.'
import { changeOpenWizard, completeWizard } from '../../redux/actions'
import { CardHorizontal, CARD_ACTION_COLORS } from '../Cards'
import { Wizard } from './Wizard'

export const RequestWizardCard = ({ onClick, completed = false, CTA = false }) => {
    return (
        <CardHorizontal color={!!completed ? CARD_ACTION_COLORS.SUCCESS : CARD_ACTION_COLORS.DEFAULT} onClick={onClick}>
            <FormGroup className="position-relative">
                <Label>Crie um evento</Label>
                <p>Comece a organizar sua agenda e os grupos que atende.</p>
                {!!completed && <Check style={{ position: 'absolute', top: 0, right: 0 }} color={CARD_ACTION_COLORS.SUCCESS} />}
            </FormGroup>
            <FormGroup>
                <Button color="primary" outline={!CTA}><PlayCircle /> Iniciar</Button>
            </FormGroup>
        </CardHorizontal>
    )
}

export const RequestWizard = ({}) => { 
    const { openWizard } = useSelector(state => state.app)
    const dispatch = useDispatch()

    return (
        <Wizard title="Crie um evento" open={openWizard === WIZARDS.REQUEST} onClose={() => dispatch(changeOpenWizard())}>
            <Row>
                <Col>
                    <p><strong>1.</strong> No menu, clique em AGENDA e depois selecione no calendário um dos dias que tenha previamente configurado um horário livre.</p>
                    <p>Este calendário também mostra indicações de eventos já existentes, representados por bolinhas, cuja cor informa de qual experiência é o evento.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_52.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>2.</strong> Para cada um dos horários podem aparecer 3 padrões de cores na faixa esquerda.</p>
                    <p>Se o horário está marcado com VERMELHO, significa que já está criado um evento exclusivo e/ou está sem vagas disponíveis. Clicando nele você irá para a tela do evento.</p> 
                    <p>O AMARELO significa que já está criado um evento não exclusivo e ele ainda tem vagas disponíveis. Clicando nele você irá para a tela do evento.</p>
                    <p>O VERDE, por sua vez, é quando ainda não há um evento criado para aquele horário. Ou seja, ele está livre e é neste que você vai clicar para criar um novo evento.</p>
                    <p>ATENÇÃO: É necessário ter um horário livre para poder criar eventos. Para cadastrar novos horários livres, clique no botão EDITAR HORÁRIOS LIVRES. </p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_53.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>3.</strong> Quando o modal para CRIAR EVENTO se abrir, confira a experiência, o dia e horário de início.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_46.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>4.</strong> Selecione se o evento será exclusivo, para que apenas convidados possam comprar ingressos, ou não exclusivo, para que as demais vagas do evento fiquem disponíveis para serem vendidas publicamente.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_47.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>5.</strong> De acordo com o selecionado acima, você terá opções diferentes de continuar.</p>
                    <p>A primeira é simplesmente criar o evento 'em branco', sem adicionar qualquer outra informação, o que pode ser útil quando quer acessar ferramentas específicas de eventos, como o link de convite por exemplo. Para fazer isso, basta deixar tudo em branco e clicar em CRIAR.</p>
                    <p>Outra opção é quando está criando um evento exclusivo, nele você pode adicionar as informações do organizador do evento (aquele que será o responsável pelo horário), depois é só clicar em CRIAR.</p>
                    <p>A ultima opção é quando está criando um evento não exclusivo, nele você pode adicionar os ingressos para um primeiro cliente, ao mesmo tempo que cria o evento. Para fazer isso, você vai incluir os ingressos e quantidades e, em seguida, colocar os dados do participante antes de clicar em CRIAR.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_50.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_51.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    
                    <p><strong>6.</strong> Após criado o evento, você será direcionado para a tela do evento, onde recomendamos revisar as informações geradas automaticamente a partir das configurações da experiência e adequar às necessidades particulares, por exemplo:</p>
                    <ul>
                        <li>Copiar e compartilhar o link de convite do evento</li>
                        <li>Criar novos ingressos para o evento;</li>
                        <li>Editar informações e regras do evento; ou</li>
                        <li>Mudar o valor dos ingressos disponíveis para o evento;</li>
                    </ul>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_54.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={() => dispatch(completeWizard(WIZARDS.REQUEST))} outline><Check /> Concluir</Button>
                </Col>
            </Row>
        </Wizard>
    )
}
