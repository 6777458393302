import { 
    FETCH_COMPANY_ADVENTURES, 
    FETCH_COMPANY_ADVENTURES_SUCCESS, 
    FETCH_COMPANY_ADVENTURES_ERROR,
    SAVE_DRAFT_ADVENTURE, 
    SAVE_DRAFT_ADVENTURE_SUCCESS,
    SAVE_DRAFT_ADVENTURE_ERROR,
    SELECT_ADVENTURE,
    SELECT_ADVENTURE_ERROR,
    REFRESH_ADVENTURES,
    FETCH_ADVENTURES_CATEGORIES,
    FETCH_ADVENTURES_CATEGORIES_SUCCESS,
    FETCH_ADVENTURES_CATEGORIES_ERROR,
    UPLOAD_IMAGE_FILE_TO_AWS,
    UPLOAD_IMAGE_FILE_TO_AWS_SUCCESS,
    UPLOAD_IMAGE_FILE_TO_AWS_ERROR,
    FETCH_ADVENTURES_TAGS_SUCCESS,
    FETCH_ADVENTURES_TAGS_ERROR,
    FETCH_ADVENTURES_TAGS,
    INIT_ADVENTURE_CREATION,
    CREATE_NEW_ADVENTURE,
    CREATE_NEW_ADVENTURE_SUCCESS, 
    CREATE_NEW_ADVENTURE_ERROR,
    CLEAR_CURRENT_ADVENTURE,
    CHANGE_ADVENTURE_FIELD
} from "../constants";
import { CLONE_ADVENTURE, CLONE_ADVENTURE_ERROR, CLONE_ADVENTURE_SUCCESS } from "./constants";

/**
 * Consulta aventuras da empresa (necessário autenticação)
 */
export const fetchCompanyAdventures = () => ({
    type: FETCH_COMPANY_ADVENTURES
})

/**
 * Resultado positivo da consulta de aventuras
 * @param {Array} data Lista de aventuras
 */
export const fetchCompanyAdventuresSuccess = (data) => ({
    type: FETCH_COMPANY_ADVENTURES_SUCCESS,
    payload: data
})

/**
 * Resultado negativo da consulta de aventuras
 * @param {Object} error Erro encontrado, com mensagem e status code
 */
export const fetchCompanyAdventuresError = (error) => ({
    type: FETCH_COMPANY_ADVENTURES_ERROR,
    payload: error
})

/**
 * Consulta de categorias das aventuras
 */
export const fetchAdventuresCategories = () => ({
    type: FETCH_ADVENTURES_CATEGORIES
})

/**
 * TODO: UPDATE
 * Resultado positivo da consulta de categorias
 * @param {Array} data 
 */
export const fetchAdventuresCategoriesSuccess = (ids) => ({
    type: FETCH_ADVENTURES_CATEGORIES_SUCCESS,
    payload: ids
})

/**
 * Resultado negativo da consulta de categorias
 * @param {Object} err 
 */
export const fetchAdventuresCategoriesError = (err) => ({
    type: FETCH_ADVENTURES_CATEGORIES_ERROR,
    payload: err
})

/**
 * Salvar edições de uma aventura
 * @param {Object} data Aventura sendo salva
 */
export const saveDraftAdventure = (data) => ({
    type: SAVE_DRAFT_ADVENTURE,   
    payload: data
})

/**
 * TODO: UPDATE
 * Atualiza a aventura sendo edita com as infos salvas no servidor
 */
export const saveDraftAdventureSuccess = () => ({
    type: SAVE_DRAFT_ADVENTURE_SUCCESS
})

/**
 * Informa erro durante o salvamento da aventura
 * @param {Object} error Erro com mensagem e status code
 */
export const saveDraftAdventureError = (error) => ({
    type: SAVE_DRAFT_ADVENTURE_ERROR,
    payload: error
})


export const cloneAdventure = (title, pkAdventure) => ({
    type: CLONE_ADVENTURE,
    payload: { title, pkAdventure }
})

export const cloneAdventureSuccess = (id) => ({
    type: CLONE_ADVENTURE_SUCCESS,
    payload: { id }
})

export const cloneAdventureError = (err) => ({
    type: CLONE_ADVENTURE_ERROR,
    payload: err
})

/**
 * Ação para selecionar aventura para edição
 * @param {Number} id ID da aventura sendo editada
 */
export const selectAdventure = (id) => ({
    type: SELECT_ADVENTURE,
    payload: { id }
})

export const selectAdventureError = (err) => ({
    type: SELECT_ADVENTURE_ERROR,
    payload: err
})

/**
 * Limpa todas as aventuras carregadas
 */
export const refreshAdventures = () => ({
    type: REFRESH_ADVENTURES
})

export const uploadImageFileToAws = (data) => ({
    type: UPLOAD_IMAGE_FILE_TO_AWS,
    payload: data
})

export const uploadImageFileToAwsSuccess = (data) => ({
    type: UPLOAD_IMAGE_FILE_TO_AWS_SUCCESS,
    payload: data
})

export const uploadImageFileToAwsError = (error) => ({
    type: UPLOAD_IMAGE_FILE_TO_AWS_ERROR,
    payload: error
})

export const fetchAdventuresTags = () => ({
    type: FETCH_ADVENTURES_TAGS
})

export const fetchAdventuresTagsSuccess = (data) => ({
    type: FETCH_ADVENTURES_TAGS_SUCCESS,
    payload: data
})

export const fetchAdventuresTagsError = (err) => ({
    type: FETCH_ADVENTURES_TAGS_ERROR,
    payload: err
})

export const initAdventureCreation = (id) => ({
    type: INIT_ADVENTURE_CREATION,
    payload: { id }
})

export const createNewAdventure = (data) => ({
    type: CREATE_NEW_ADVENTURE,
    payload: data
})

export const createNewAdventureSuccess = () => ({
    type: CREATE_NEW_ADVENTURE_SUCCESS
})

export const createNewAdventureError = (err) => ({
    type: CREATE_NEW_ADVENTURE_ERROR,
    payload: err
})

export const clearCurrentAdenture = () => ({
    type: CLEAR_CURRENT_ADVENTURE
})

export const changeAdventureField = (field) => ({
    type: CHANGE_ADVENTURE_FIELD,
    payload: { field }
})