import React from 'react';
import './ProgressBar.scss';

export const ProgressBar = ({ completedItems, itemsTotal }) => {
    
    const percentCompleted = Math.round(completedItems * 100) / itemsTotal;

    return (
        <React.Fragment>
            <header className="experienceBar">
                <div>
                    <div style={{ width: `${percentCompleted}%` }} />
                    <span className="currentExperience" style={{ left: `${percentCompleted}%` }}>
                        {Math.round(percentCompleted * 100) / 100} %
                    </span>
                </div>
            </header>
        </React.Fragment>
    );
};