import { v4 as uuid4 } from 'uuid'
export class FAQ {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_faq = json.pk_faq || null
        this.fk_adventure = json.fk_adventure || null
        this.adventure_id = json.adventure_id || null

        this.question = json.question || ''
        this.answer = json.answer || ''
    }
}