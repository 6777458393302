import React, { useCallback } from 'react'
import { Modal, ModalBody, CustomInput, ModalHeader, FormGroup, Label, FormText, Button, Input, Container, Alert } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserFields, saveBusinessAttributes, clearError } from '../../redux/actions'
import { BusinessInfo } from '../../pages/profile/BusinessInfo'
import { Check } from 'react-feather'
import { isUserAuthenticated } from '../../helpers/authUtils';
import Loader from '../Loader'
import moment from 'moment'
import { useState } from 'react'
import { Toast } from '../Toast'
import { UFS } from '../../constants'
import { useHistory } from 'react-router'
import { UseAndContractTermLink } from '../UseAndContractTermLink'

const unformatFields = (user) => {
    return {
        ...user,
        cep: user.cep.replace(/\D/g, ''),
        cpf: user.cpf.replace(/\D/g, ''),
        cnpj: user.cnpj.replace(/\D/g, ''),
        business_phone: user.business_phone.replace(/\D/g, ''),
        cep: user.cep.replace(/\D/g, ''),
        cep: user.cep.replace(/\D/g, ''),
        cep: user.cep.replace(/\D/g, ''),
    }
}


const validateFieldsErrors = (user) => {

    user = unformatFields(user)

    // Valida campos para cnpj
    if (user.type === "cnpj") {
        if (!(!!user.cnpj && user.cnpj.length === 14)) 
            return "CNPJ"

        if (!user.social_reason) 
            return "Razão social"
    } 

    // Valida campos para cpf e cnpj
    if (!(!!user.cep && user.cep.length === 8))
        return "CEP"
    
    if (!user.address)
        return "Endereço"
    
    if (!user.number)
        return "Número"
    
    if (!user.district)
        return "Bairro"
    
    if (!user.city)
        return "Cidade"
    
    if (!(!!user.state && user.state.length === 2 && UFS.find(uf => uf === user.state.toUpperCase())))
        return "Estado"
    
    if (!user.name)
        return user.type === "cpf" ? "Nome completo" : "Nome do responsável legal"
    
    if (!(!!user.cpf && user.cpf.length === 11))
        return user.type === "cpf" ? "CPF" : "CPF do responsável legal"
    
    if (!(!!user.birthday && moment(user.birthday, 'DD/MM/YYYY').isValid()))
        return user.type === "cpf" ? "Data de nascimento" : "Data de nascimento do responsável legal"
    
    if (!(!!user.business_phone && user.business_phone.length >= 11))
        return "Telefone principal"

    return false
}

export const ModalFirstAccess = () => {
    const { user, loadingSaveBusinessAttributes, error } = useSelector(state => state.Auth)
    const [toastMessage, setToastMessage] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const handleUpdateFields = (field, value) => {
        dispatch(updateUserFields({ [field]: value }))
    }

    const handleConfirm = useCallback(() => {
        const error = validateFieldsErrors(user)
        if (!error) {
            dispatch(saveBusinessAttributes(unformatFields({ ...user, first_access: true, silent: true })))
        } else {
            setToastMessage(error)
        }
    }, [user])

    if (!isUserAuthenticated() || user === null)
        return ''

    return (
        <Modal size="lg" isOpen={user !== null && !user.term_sign_date && !user.admin}>
            <ModalHeader toggle={() => history.push('/account/logout')}>
                Bem vindo(a) ao OffStation!
            </ModalHeader>
            <ModalBody>
                {loadingSaveBusinessAttributes && <Loader />}
                <Container>
                    <p className="lead">Agora você também faz parte deste projeto: <strong>O online para o offline!!</strong></p>
                    <p>Aos poucos estamos formando uma comunidade que acredita na <strong>internet como um local para fazer
                        negócios</strong> e que pode facilitar para que cada vez mais pessoas possam praticar
                        esportes e viverem aventuras.</p>
                    <p>Nós que trabalhamos oferecendo experiências sabemos exatamente o valor de <strong> uma jornada agradável 
                        para o seu cliente.</strong> Eles têm um caminho que começa lá na descoberta de sua empresa como uma oportunidade,
                        passa pela execução e vai até a criação de uma lembrança inesquecível. </p>
                    <p>Aqui na OffStation damos o nosso melhor para estarmos <strong>juntos com você nesse objetivo</strong>, 
                        te dando as ferramentas para que tenha dinheiro e tempo para você se dedicar ao seu propósito maior.</p>
                    <p><strong>Juntos vamos proporcionar uma experiência só, online e offline, e vai ser memorável.</strong></p>
                    <FormGroup className="mt-5">
                        <FormText>Personalidade jurídica daquele que está contratando a OffStation.</FormText>
                        <CustomInput onClick={() => handleUpdateFields("type", "cnpj")} checked={user.type === "cnpj"} id="type-cnpj" className="radio-card-input" type="radio" label="Pessoa Jurídica (CNPJ ativo)" />
                        <CustomInput onClick={() => handleUpdateFields("type", "cpf")} checked={user.type === "cpf"} id="type-cpf" className="radio-card-input" type="radio" label="Pessoa Física" />
                    </FormGroup>
                </Container>
                <BusinessInfo onChangeField={handleUpdateFields} {...user} noSave fullWidth />
                <Container>
                    <FormGroup>
                        <Label>Código de indicação</Label>
                        <FormText>Cupom de desconto ou indicação. Se não possuir, apenas deixe em branco.</FormText>
                        <Input />
                    </FormGroup>
                    <CustomInput type="checkbox" id="terms-acceptance" checked={user.termsAcceptance} onChange={e => handleUpdateFields("termsAcceptance", e.target.checked)} label={<span>Concordo com o <UseAndContractTermLink /></span>} />
                    <Button onClick={() => handleConfirm()} color="primary" className="float-right" ><Check /> Salvar</Button>
                </Container>
            </ModalBody>
            <div style={{ position: 'fixed', bottom: '5px', left: '0', right: '0', zIndex: '99', margin: 'auto', maxWidth: '35vw' }}>
                {error && <Alert color="danger" isOpen={!!error} toggle={() => dispatch(clearError())}>{error.message}</Alert> }
            </div>
            {toastMessage && <Toast message={`Ainda existem campos com erro: ${toastMessage}`} clear={() => setToastMessage(undefined)} color="danger" />}
        </Modal>
    )
}
