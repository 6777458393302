
import moment from 'moment';
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap'
import { changeOpenWizard, completeWizard } from '../../redux/actions';
import { ChartScheduleMetrics } from '../ChartScheduleMetrics';
import { ProgressBar } from '../ProgressBar'
import { AdventureWizardCard } from './AdventureWizard';
import { ConfirmEmailWizardCard } from './ConfirmEmailWizard';
import { PaymentMethodsWizardCard } from './PaymentMethodsWizard';
import { PublicProfileWizardCard } from './PublicProfileWizard';
import { RequestWizardCard } from './RequestWizard';
import { ReserveWizardCard } from './ReserveWizard';

export const FirstStepsWizard = ({ }) => {
    const dispatch = useDispatch()
    const {  
        adventure_wizard_completed,
        confirm_email_wizard_completed,
        payment_method_wizard_completed,
        public_profile_wizard_completed,
        request_wizard_completed,
        reserve_wizard_completed
    } = useSelector(state => state.Auth.user)

    const completedConfirmEmailWizard = confirm_email_wizard_completed
    const completedPublicProfileWizard = public_profile_wizard_completed
    const completedPaymentMethodsWizard = payment_method_wizard_completed
    const completedAdventureWizard = adventure_wizard_completed
    const completedRequestWizard = request_wizard_completed
    const completedReserveWizard = reserve_wizard_completed

    const reserveWizardItem = { Component: ReserveWizardCard, completed: completedReserveWizard, onClick: () => dispatch(changeOpenWizard(WIZARDS.RESERVE)) }
    const requestWizardItem = { Component: RequestWizardCard, completed: completedRequestWizard, onClick: () => dispatch(changeOpenWizard(WIZARDS.REQUEST)) }
    const adventureWizardItem = { Component: AdventureWizardCard, completed: completedAdventureWizard, onClick: () => dispatch(changeOpenWizard(WIZARDS.ADVENTURE)) }
    const paymentMethodWizardItem = { Component: PaymentMethodsWizardCard, completed: completedPaymentMethodsWizard, onClick: () => dispatch(changeOpenWizard(WIZARDS.PAYMENT_METHODS)) }
    const publicProfileWizardItem = { Component: PublicProfileWizardCard, completed: completedPublicProfileWizard, onClick: () => dispatch(changeOpenWizard(WIZARDS.PUBLIC_PROFILE)) }
    const confirmEmailWizardItem = { Component: ConfirmEmailWizardCard, completed: completedConfirmEmailWizard, onClick: () => dispatch(changeOpenWizard(WIZARDS.CONFIRM_EMAL)) }

    const allWizards = [
        //confirmEmailWizardItem,
        publicProfileWizardItem,
        paymentMethodWizardItem,
        adventureWizardItem,
        requestWizardItem,
        reserveWizardItem,
    ]
    
    const completedWizards = useMemo(() => {
        const completedWizards = []

        allWizards.map(item => item.completed && completedWizards.push(item))

        return completedWizards

    }, allWizards.map(item => item.completed))

    const notCompletedWizards = useMemo(() => {
        const completedWizards = []

        allWizards.map(item => !item.completed && completedWizards.push(item))

        return completedWizards

    }, allWizards.map(item => item.completed))

    const wizardsCards = [ ...notCompletedWizards, ...completedWizards]
    const SuccessFragment = useMemo(() => () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <h4 className="text-success">Tudo pronto!</h4>
                    <p>A partir de agora está tudo pronto para vender mais! Confira o desempenho das suas experiências, ou acesse a <Link to="/reports">página de relatórios</Link> para mais dados.</p>
                </FormGroup>
                <Row>
                    <Col>
                        <ChartScheduleMetrics period={{ start_date: moment().add(-1, 'month').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') }} />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }, [])

    return (
        <Card>
            <CardBody>
                <FormGroup>
                    <h3 className="text-primary">Vamos começar?</h3>
                    <p>Execute as tarefas abaixo para começar a receber reservas online.</p>
                </FormGroup>
                <Row className="mb-5">
                    <Col md={6}>
                        <ProgressBar completedItems={completedWizards.length} itemsTotal={allWizards.length} />
                    </Col>
                </Row>
                { wizardsCards.length === completedWizards.length && <SuccessFragment /> } 
                <Row className="flex-wrap">
                    {wizardsCards.map((item, index) =>  
                        <Col key={`wizard-card-${index}`} md={4}>
                            <item.Component completed={item.completed} CTA={!item.completed && index === 0} onClick={item.onClick} />
                        </Col>
                    )}
                </Row>
            </CardBody>
        </Card>
    )
}

export const WIZARDS = {
    ADVENTURE: 'ADVENTURE',
    CONFIRM_EMAL: 'CONFIRM_EMAL',
    PAYMENT_METHODS: 'PAYMENT_METHODS',
    PUBLIC_PROFILE: 'PUBLIC_PROFILE',
    REQUEST: 'REQUEST',
    RESERVE: 'RESERVE',
}

export const WIZARDS_ROUTES = {
    ADVENTURE: '/adventures/new',
    CONFIRM_EMAIL: '/account/profile',
    PAYMENT_METHODS: '/account/profile#receivings',
    PUBLIC_PROFILE: '/account/profile',
    REQUEST: '/schedule/events',
    RESERVE: '/schedule/events'
}

export * from './AdventureWizard'
export * from './ConfirmEmailWizard'
export * from './PaymentMethodsWizard'
export * from './PublicProfileWizard';
export * from './RequestWizard'
export * from './ReserveWizard'