import {
  FETCH_NOTES_DATE,
  FETCH_NOTES_DATE_SUCCESS,
  FETCH_NOTES_DATE_ERROR,
  SAVE_NOTES,
  SAVE_NOTES_SUCCESS,
  SAVE_NOTES_ERROR,
  UPDATE_NOTES,
  UPDATE_NOTES_SUCCESS,
  UPDATE_NOTES_ERROR,
  COMPLETE_NOTES,
  COMPLETE_NOTES_SUCCESS,
  COMPLETE_NOTES_ERROR,
  DELETE_NOTES,
  DELETE_NOTES_SUCCESS,
  DELETE_NOTES_ERROR,
  REFRESH_STORE,
  LOGOUT_USER
} from '../constants';

const initialState = {
    loadingNotes: false,
    notes: [],
    saveData: [],
    updateData: [],
    completeData: [],
    deleteData: [],
    error: null
};

const mergeNotes = (oldNotes, newNotes) => {
    const mergedNotesPk = {}
    const mergedArray = []
    const notMergedArray = [ ...oldNotes, ...newNotes]

    while (true) {
        if (!notMergedArray.length) {
            break;
        }

        const note = notMergedArray[0]
        if(mergedNotesPk[note.pk_note]) {
            continue;
        } 

        mergedNotesPk[note.pk_note] = true
        mergedArray.push(note)
        notMergedArray.splice(0, 1)
    }

    return mergedArray
}

const removeNotes = (notes, toBeRemoved) => {
    return notes
            .reduce((prevArray, note) => 
                toBeRemoved.find(removedNote => removedNote.pk_note === note.pk_note) ? 
                    prevArray : 
                    [ ...prevArray, note ], 
            [])
}

const Notes = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_NOTES_DATE:
            return { ...state, loadingNotes: true };
        case FETCH_NOTES_DATE_SUCCESS:
            return { ...state, loadingNotes: false, notes: mergeNotes(state.notes , action.payload) };
        case FETCH_NOTES_DATE_ERROR:
            return { ...state, loadingNotes: false, error: action.payload };
        case SAVE_NOTES:
            return { ...state, loadingNotes: true, saveData: action.payload };
        case SAVE_NOTES_SUCCESS:
            return { ...state, loadingNotes: false, notes: [...state.notes, ...action.payload] };
        case SAVE_NOTES_ERROR:
            return { ...state, loadingNotes: false, error: action.error };
        case UPDATE_NOTES:
            return { ...state, loadingNotes: true, updateData: action.payload };
        case UPDATE_NOTES_SUCCESS:
            return { ...state, loadingNotes: false, notes: [...action.payload] };
        case UPDATE_NOTES_ERROR:
            return { ...state, loadingNotes: false, error: action.error };
        case COMPLETE_NOTES:
            return { ...state, loadingNotes: true, completeData: action.payload };
        case COMPLETE_NOTES_SUCCESS:
            return { ...state, loadingNotes: false, notes: [...action.payload] };
        case COMPLETE_NOTES_ERROR:
            return { ...state, loadingNotes: false, error: action.error };
        case DELETE_NOTES:
            return { ...state, loadingNotes: true, deleteData: action.payload };
        case DELETE_NOTES_SUCCESS:
            return { ...state, loadingNotes: false, notes: removeNotes(state.notes, action.payload) };
        case DELETE_NOTES_ERROR:
            return { ...state, loadingNotes: false, error: action.error };
        case REFRESH_STORE:
            return initialState
        case LOGOUT_USER:
            return initialState
        default:
            return state;
    }
};

export default Notes;