import {
    CREATE_RESERVE_ENTITY,
    UPDATE_RESERVE_ENTITY,
    DELETE_RESERVE_ENTITY,
    BATCH_CREATE_RESERVE_ENTITY
} from '../../constants'
import { Reserve } from '../../../models'

export const createReserveEntity = (data) => ({
    type: CREATE_RESERVE_ENTITY,
    payload: new Reserve(data)
})

export const updateReserveEntity = (data) => ({
    type: UPDATE_RESERVE_ENTITY,
    payload: data
})

export const deleteReserveEntity = (id) => ({
    type: DELETE_RESERVE_ENTITY,
    payload: { id }
})

export const batchCreateReserveEntity = (data) => ({
    type: BATCH_CREATE_RESERVE_ENTITY,
    payload: data.map(item => new Reserve(item))
})