import { reduceItemFromArray } from "../../../helpers/utils"

export const removeReserveSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curReqId) => ({
                 ...prevState, 
                 [curReqId]: { 
                        ...state[curReqId], 
                        reserves: state[curReqId].reserves
                                    .reduce((prevReserves, curReserveId) => curReserveId !== action.payload.id ? [ ...prevReserves, curReserveId] : prevReserves, []) 
                    }
            }), {})
}

export const addReserveSliceById = (state, action) => {
    const { request_id } = action.payload
    if (request_id && !!state[request_id]) {
        return { 
            ...state, 
            [request_id]: { 
                ...state[request_id], 
                reserves: [ ...state[request_id].reserves, action.payload.id ] 
            } 
        }
    } 

    return state
}

export const batchReserveSliceById = (state, action) => {
    return action.payload.reduce((prevState, item) => {
        const { request_id } = item
        if (request_id && !!prevState[request_id]) {
            return { 
                ...prevState, 
                [request_id]: { 
                    ...prevState[request_id], 
                    reserves: [ ...prevState[request_id].reserves, item.id ] 
                } 
            }
        } 

        return prevState
    }, state)
}

export const refreshOnUpdateSliceById = (state, action) => {
    // If updating request reference
    if (action.payload.request_id !== undefined) {
        const clearedState = removeReserveSliceById(state, action) // Remove all references
        return addReserveSliceById(clearedState, action) // Ads new reference
    }

    return state
}


export const addRequestBonusSliceById = (state, action) => {
    const { request_id } = action.payload
    
    if (!(request_id && !!state[request_id])) {
        return state
    }

    return { 
        ...state, 
        [request_id]: { 
            ...state[request_id], 
            bonuses: [ ...state[request_id].bonuses, action.payload.id ] 
        } 
    }

}

export const removeRequestBonusSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curReqId) => ({
                 ...prevState, 
                 [curReqId]: { 
                        ...state[curReqId], 
                        bonuses: state[curReqId].bonuses
                                    .reduce((prevBonuses, curBonusId) => curBonusId !== action.payload.id ? [ ...prevBonuses, curBonusId] : prevBonuses, []) 
                    }
            }), {})
}

export const addRequestMessageSliceById = (state, action) => {
    const { request_id } = action.payload
    
    if (!(request_id && !!state[request_id])) {
        return state
    }

    return { 
        ...state, 
        [request_id]: { 
            ...state[request_id], 
            messages: [ ...state[request_id].messages, action.payload.id ] 
        } 
    }

}

export const removeRequestMessageSliceById = (state, action) => {
    return Object
            .keys(state)
            .reduce((prevState, curReqId) => ({
                    ...prevState, 
                    [curReqId]: { 
                        ...state[curReqId], 
                        messages: reduceItemFromArray(state[curReqId].messages, action.payload.id)  
                    }
            }), {})
}