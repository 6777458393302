import { combineReducers } from "redux";
import {
    CREATE_IMAGE,
    UPDATE_IMAGE,
    DELETE_IMAGE
} from '../../constants'
import { LOGOUT_USER } from "../../auth/constants";
import { REFRESH_STORE } from "../../app/constants";

export function imageById(state = {}, action){
    switch (action.type) {
        case CREATE_IMAGE:
            return { ...state, [action.payload.id]: action.payload }
        case UPDATE_IMAGE:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}
        case DELETE_IMAGE:
            return { ...state, [action.payload.id]: undefined }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function imageAllIds(state = [], action){
    switch (action.type) {
        case CREATE_IMAGE:
            return [ ...state, action.payload.id]
        case DELETE_IMAGE:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId ] : prevState, [])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}


export default combineReducers({
    byId: imageById,
    allIds: imageAllIds
})