export const FETCH_CLIENT_LIST = 'FETCH_CLIENT_LIST';
export const FETCH_CLIENT_LIST_SUCCESS = 'FETCH_CLIENT_LIST_SUCCESS';
export const FETCH_CLIENT_LIST_FAILURE = 'FETCH_CLIENT_LIST_FAILURE';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';
export const EXPORT_CLIENT_CSV = 'EXPORT_CLIENT_CSV';
export const EXPORT_CLIENT_CSV_SUCESS = 'EXPORT_CLIENT_CSV_SUCESS';
export const EXPORT_CLIENT_CSV_FAILURE = 'EXPORT_CLIENT_CSV_FAILURE';