import React from 'react'
import { Check, PlayCircle } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { WIZARDS } from '.'
import { changeOpenWizard, completeWizard } from '../../redux/actions'
import { CardHorizontal, CARD_ACTION_COLORS } from '../Cards'
import { Wizard } from './Wizard'

export const AdventureWizardCard = ({ onClick, completed = false, CTA = false }) => {
    return (
        <CardHorizontal color={!!completed ? CARD_ACTION_COLORS.SUCCESS : CARD_ACTION_COLORS.DEFAULT} onClick={onClick}>
            <FormGroup className="position-relative">
                <Label>Criar a primeira experiência</Label>
                <p>Cadastre a primeira experiência que será colocada nos canais de venda.</p>
                {!!completed && <Check style={{ position: 'absolute', top: 0, right: 0 }} color={CARD_ACTION_COLORS.SUCCESS} />}
            </FormGroup>
            <FormGroup>
                <Button color="primary" outline={!CTA}><PlayCircle /> Iniciar</Button>
            </FormGroup>
        </CardHorizontal>
    )
}

export const AdventureWizard = ({}) => { 
    const { openWizard } = useSelector(state => state.app)
    const dispatch = useDispatch()

    return (
        <Wizard title="Criar a primeira experiência" open={openWizard === WIZARDS.ADVENTURE} onClose={() => dispatch(changeOpenWizard())}>
            <Row>
                <Col>
                    <p><strong>1.</strong> No menu, clique em EXPERIÊNCIAS. Depois, no canto superior direito, clique no botão NOVA EXPERIÊNCIA.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_40.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>2.</strong> Preencha os campos do formulário. Confira os textos de apoio de cada campo para mais informações.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_41.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>3.</strong> Conforme for concluindo os passos, clique em SALVAR E CONTINUAR. Se precisar voltar algum passo ou cancelar a criação, basta utilizar os botões secundários.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_42.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>4.</strong> Quando tiver concluído a criação básica, você será direcionado para a tela de edição da experiência, onde poderá revisar as configurações geradas automaticamente pelo sistema.</p>
                    <p>Este passo é muito importante para garantir que as informações sobre a oferta e o fluxo de reserva estejam de acordo com o desejado.</p>
                    <p>Portanto, navegue entre as abas, lendo atentamente a descrição da funcionalidade de cada campo e ajustando sempre que necessário.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_43.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>5.</strong> Após ter terminado a revisão, ou sempre que desejar, clique em SALVAR para receber a mensagem de confirmação.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_36.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                    <p><strong>Obs.</strong> Caso queira criar outra experiência semelhante a uma que já criou, você pode duplicá-la e mudar apenas aquilo que desejar. Para criar a cópia, clique no menu da experiência e selecione COPIAR.</p>
                    <p> <img src='https://adrenalyze-app.s3-sa-east-1.amazonaws.com/offstation/imgs/Screenshot_44.jpg' alt='Onde clicar.' style={{ border: '1px solid #1855B7', margin: 'auto', display: 'block', height: 'auto', width: '100%' }} /> </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={() => dispatch(completeWizard(WIZARDS.ADVENTURE))} outline><Check /> Concluir</Button>
                </Col>
            </Row>
        </Wizard>
    )
}
