import { Api } from '../../helpers/api';
const API = new Api();

class NoteService {
    getNotesByDate(date) {
        return API.call('/v2/note/get-notes-by-date', 'post', { date });
    }

    saveNotes(notes) {
        return API.call('/v2/note/save-notes', 'post', { notes });
    }

    updateNotes(notes) {
        return API.call('/v2/note/update-notes', 'post', { notes });
    }

    completeNotes(notes) {
        return API.call('/v2/note/complete-notes', 'post', { notes });
    }

    deleteNotes(notes) {
        return API.call('/v2/note/delete-notes', 'post', { notes });
    }
}

export default new NoteService()