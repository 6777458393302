import Api from '../../helpers/api';
import mockData from './mockData2.js';

class CartsService {
	getCarts(payload) {
		return Api.call('/v1/company/get-company-carts', 'get', payload)
		// console.log('mockData on service:', mockData);
		// return Promise.resolve(mockData);
	}
}

export default new CartsService()