import { combineReducers } from "redux";
import { CREATE_REQUEST_ENTITY, UPDATE_REQUEST_ENTITY, DELETE_REQUEST_ENTITY } from "./constants";
import { BATCH_CREATE_RESERVE_ENTITY, CREATE_RESERVE_ENTITY, DELETE_RESERVE_ENTITY, UPDATE_RESERVE_ENTITY } from "../reserve/constants";
import { addReserveSliceById, removeReserveSliceById, refreshOnUpdateSliceById, removeRequestBonusSliceById, addRequestBonusSliceById, addRequestMessageSliceById, removeRequestMessageSliceById, batchReserveSliceById } from "./slices";
import { LOGOUT_USER } from "../../auth/constants";
import { REFRESH_STORE } from "../../app/constants";
import { CREATE_REQUEST_BONUS, DELETE_REQUEST_BONUS } from "../requestBonus/constants";
import { CREATE_REQUEST_MESSAGE_ENTITY, DELETE_REQUEST_MESSAGE_ENTITY } from "../requestMessage/constants";

export const requestById = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REQUEST_ENTITY:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_REQUEST_ENTITY:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_REQUEST_ENTITY:
            return { ...state, [action.payload.id]: undefined } 
        case CREATE_RESERVE_ENTITY:
            return addReserveSliceById(state, action)
        case BATCH_CREATE_RESERVE_ENTITY:
            return batchReserveSliceById(state, action)
        case DELETE_RESERVE_ENTITY:
            return removeReserveSliceById(state, action)
        case UPDATE_RESERVE_ENTITY:
            return refreshOnUpdateSliceById(state, action)
        case CREATE_REQUEST_BONUS:
            return addRequestBonusSliceById(state, action)
        case DELETE_REQUEST_BONUS:
            return removeRequestBonusSliceById(state, action)
        case CREATE_REQUEST_MESSAGE_ENTITY:
            return addRequestMessageSliceById(state, action)
        case DELETE_REQUEST_MESSAGE_ENTITY:
            return removeRequestMessageSliceById(state, action)
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state;
    }
}

export const requestAllIds = (state = [], action) => {
    switch (action.type) {
        case CREATE_REQUEST_ENTITY:
            return [ ...state, action.payload.id ]
        case DELETE_REQUEST_ENTITY:
            return state.reduce((prevState,curId) => curId === action.payload.id ? prevState : [ ...prevState, curId],[])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state;
    }
}

export default combineReducers({
    byId: requestById,
    allIds: requestAllIds
})