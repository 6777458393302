import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import NoteService from './service';
import {
  fetchNotesDateSuccess,
  fetchNotesDateError,
  saveNotesSuccess,
  saveNotesError,
  updateNotesSuccess,
  updateNotesError,
  completeNotesSuccess,
  completeNotesError,
  deleteNotesSuccess,
  deleteNotesError
} from './actions';
import {
  FETCH_NOTES_DATE,
  SAVE_NOTES,
  UPDATE_NOTES,
  COMPLETE_NOTES,
  DELETE_NOTES
} from './constants';

function* getNotesByDate({ payload }) {
    const { date } = payload;
    const res = yield call(NoteService.getNotesByDate, date);

    if(res.status === 200) {
        yield put(fetchNotesDateSuccess(res.data));
    }
    else {
        yield put(fetchNotesDateError(res));
    }
}

function* saveNotes({ payload }) {
    const { notes } = payload;
    const res = yield call(NoteService.saveNotes, notes);

    if(res.status === 200) {
        yield put(saveNotesSuccess(res.data));
    }
    else {
        yield put(saveNotesError(res));
    }
}

function* updateNotes({ payload }) {
    const { notes } = payload;
    const res = yield call(NoteService.updateNotes, notes);

    if(res.status === 200) {
        const newData = res.data[0];
        const newStateNotes = yield select(({ Notes }) => Notes.notes.map(n => n.pk_note === newData.pk_note ? newData : n));
        yield put(updateNotesSuccess(newStateNotes));
    }
    else {
        yield put(updateNotesError(res));
    }
}

function* completeNotes({ payload }) {
    const { notes } = payload;
    const res = yield call(NoteService.completeNotes, notes);

    if(res.status === 200) {
        const newData = res.data[0];
        const newStateNotes = yield select(({ Notes }) => Notes.notes.map(n => n.pk_note === newData.pk_note ? newData : n));
        yield put(completeNotesSuccess(newStateNotes));
    }
    else {
        yield put(completeNotesError(res));
    }
}

function* deleteNotes({ payload }) {
    const notes = payload;
    const res = yield call(NoteService.deleteNotes, notes);

    if(res.status === 200) {
        yield put(deleteNotesSuccess(res.data));
    }
    else {
        yield put(deleteNotesError(res));
    }
}

export function* watchFetchNotesByDate() {
    yield takeEvery(FETCH_NOTES_DATE, getNotesByDate);
}

export function* watchSaveNotes() {
    yield takeEvery(SAVE_NOTES, saveNotes);
}

export function* watchUpdateNotes() {
    yield takeEvery(UPDATE_NOTES, updateNotes);
}

export function* watchCompleteNotes() {
    yield takeEvery(COMPLETE_NOTES, completeNotes);
}

export function* watchDeleteNotes() {
    yield takeEvery(DELETE_NOTES, deleteNotes);
}

function* notesSaga() {
    yield all([
        fork(watchFetchNotesByDate),
        fork(watchSaveNotes),
        fork(watchUpdateNotes),
        fork(watchCompleteNotes),
        fork(watchDeleteNotes),
    ]);
}

export default notesSaga;
