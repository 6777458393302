import { UPDATE_ADVENTURE, CREATE_ADVENTURE } from "../../constants";
import { Adventure } from "../../../models";

export const updateAdventure = adv => ({
    type: UPDATE_ADVENTURE,
    payload: adv
})

export const createAdventure = adv => ({
    type: CREATE_ADVENTURE,
    payload: new Adventure(adv)
})