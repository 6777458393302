import FinanceService from './services';
import { Api } from '../../helpers/api';
import { all, call, put, takeEvery, fork } from 'redux-saga/effects';
import {
    fetchFinancesBalancesSuccess,
    fetchFinancesBalancesError,
    fetchFinancesExtractMonthSuccess,
    fetchFinancesExtractMonthError,
    fetchFinancesTransferSuccess,
    fetchFinancesTransferError,
    fetchFinancesBalances,
    fetchFinancesFutureExtractSuccess,
    fetchFinancesFutureExtractError
} from './actions';
import {
    FETCH_FINANCES_BALANCES,
    FETCH_FINANCES_EXTRACT_MONTH,
    FETCH_FINANCES_TRANSFER,
    FETCH_FINANCES_FUTURE_EXTRACT
} from './constants';
import moment from 'moment';

function* getFinancesBalances() {
    const res = yield call(FinanceService.getFinancesBalances);
    if(res.status === 200) {
        const { balance, transferableBalance, withheldBalance } = res.data[0];
        yield put(fetchFinancesBalancesSuccess({balance, transferableBalance, withheldBalance}));
    }
    else {
        yield put(fetchFinancesBalancesError(res));
    }
}

function* getFinancesFutureExtract({payload}) {
    console.log(payload);
    const res = yield call(FinanceService.getFutureExtract, {startDate: moment().add(-1, 'months').format('YYYY-MM-DD hh:mm:ss')});

    if(res.status === 200) {
        const data = Object.keys(res.data).map(date => ({ date, ...res.data[date] }))
        yield put(fetchFinancesFutureExtractSuccess(res.data));
    }
    else {
        yield put(fetchFinancesFutureExtractError(res));
    }
}

function* getFinancesExtractOfMonth({ payload }){
    const { startDate, endDate, download, authKey, future } = payload;

    const res = yield call(FinanceService.getExtractOfMonth, {
        startDate,
        endDate, 
        download,
        authKey,
        future,
    });
    if(future) {
        if(res.status === 200) {
            const data = Object.keys(res.data).map(date => ({ date, ...res.data[date] }))
            yield put(fetchFinancesFutureExtractSuccess(res.data));
        }
        else {
            yield put(fetchFinancesFutureExtractError(res));
        }
    } else {
        if(res.status === 200) {
            const data = Object.keys(res.data).map(date => ({ date, ...res.data[date] }))
            yield put(fetchFinancesExtractMonthSuccess(res.data));
        }
        else {
            yield put(fetchFinancesExtractMonthError(res));
        }
    }
    
}

function* transferAvailableMoipBalance({ payload }){
    const { amount_to_transfer } = payload;

    const res = yield call(FinanceService.transferAvailableMoipBalance, { 
        amount: amount_to_transfer
    });

    if (res.status === 200) {
        yield put(fetchFinancesBalances())
        yield put(fetchFinancesTransferSuccess(res.data));
    } else {
        yield put(fetchFinancesTransferError(res));
    }
}

export function* watchFetchFinancesBalances(){
    yield takeEvery(FETCH_FINANCES_BALANCES, getFinancesBalances)
}

export function* watchFetchFinancesExtractOfMonth(){
    yield takeEvery(FETCH_FINANCES_EXTRACT_MONTH, getFinancesExtractOfMonth)
}

export function* watchFetchFinancesTransfer(){
    yield takeEvery(FETCH_FINANCES_TRANSFER, transferAvailableMoipBalance)
}

export function* watchFetchFinancesExtractFutre(){
    yield takeEvery(FETCH_FINANCES_FUTURE_EXTRACT, getFinancesFutureExtract)
}

function* financesSaga(){
    yield all([
        fork(watchFetchFinancesBalances),
        fork(watchFetchFinancesExtractOfMonth),
        fork(watchFetchFinancesTransfer),
        fork(watchFetchFinancesExtractFutre)
    ])
}

export default financesSaga
