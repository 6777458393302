
import { put, all, call, select } from 'redux-saga/effects'
import {
    createRequestEntity,
    createReserveEntity,
    createScheduleRule
} from '../actions'
import { Request, Reserve, Payment, User, Package, RequestBonus, RequestMessage } from '../../models'
import { normalizeAdventureOnSaga } from '../adventures/utils'
import { createPayment } from '../entities/payment/actions'
import { createPackage } from '../entities/package/actions'
import { createUser } from '../entities/user/actions'
import { createRequestBonusEntity } from '../entities/requestBonus/actions'
import { createRequestMessageEntity } from '../entities/requestMessage/actions'
import { batchCreateReserveEntity } from '../entities/reserve/actions'
const selectEntities = (state) => state.entities

/**
 * Função para normalizar um objeto request criando suas entities atreladas
 * @param {Object} request Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeRequestOnSaga(request, deep = true){
    const entities = yield select(selectEntities)
    const request_m = new Request(request)

    // Se tiver uma aventura, normaliza a aventura
    // if (request.adventure && deep) {
    //     // Busca a aventura já criada na store
    //     let adv_m = yield call(() => Object.values(entities.adventure.byId).find(adv => adv.pk_adventure === request.adventure.pk_adventure))

    //     if(!adv_m){
    //         // Normaliza a aventura
    //         adv_m = yield call(normalizeAdventureOnSaga, request.adventure)
    //     }

    //     request_m.adventure_id = adv_m.id
    // } else {
    //      // Busca a aventura já criada na store pelA FK
    //      let adv_id = yield call(() => entities.adventure.allIds.find(id => entities.adventure.byId[id].pk_adventure === request.fk_adventure))

    //      if (adv_id){
    //         request_m.adventure_id = adv_id
    //      }
    // }

    // Se tiver reservas dentro do agendamento, normaliza elas
    if (request.reserves && deep) {
        const reserves_id = []
        
        // Para cada reserve normaliza ela
        const reserves_m = yield all(request.reserves.map(function*(reserve){
            const reserve_m = yield call(normalizeReserveOnSaga, { ...reserve, request_id: request_m.id }, false)
            reserves_id.push(reserve_m.id)
            return reserve_m
            
        }))
        request_m.reserves = reserves_id
        yield put(batchCreateReserveEntity(reserves_m))
    }

    // Se tiver bonus dentro do agendamento, cria elas nas entities
    if (request.bonuses && deep) {
        const bonusIds = []

        yield all(request_m.bonuses.map(function*(bonus){
            const bonus_m = new RequestBonus(bonus)
            bonus_m.request_id = request_m.id

            yield put(createRequestBonusEntity(bonus_m))
            bonusIds.push(bonus_m.id)
        }))
        request_m.bonuses = bonusIds
    }

    // Se tiver mensagens dentro do agendamento, cria eles nas entities
    if (request.messages && deep) {
        const messagesIds = []

        yield all(request_m.messages.map(function*(message){
            const message_m = new RequestMessage(message)
            message_m.request_id = request_m.id

            yield put(createRequestMessageEntity(message_m))
            messagesIds.push(message_m.id)
        }))
        request_m.messages = messagesIds
    }

    // Cria a entity se ja nao estiver criada final na store
    const created_request_id = entities.request.allIds.find(id => entities.request.byId[id].pk_request === request_m.pk_request)
    if(created_request_id) request_m.id = created_request_id
    
    yield put(createRequestEntity(request_m))
    return request_m
}

/**
 * Função para normalizar um objeto schedule_rule criando suas entities atreladas
 * @param {Object} rule Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeScheduleRuleOnSaga(rule) {
    const entities = yield select(selectEntities)
    for (const id in entities.adventure.byId) {
        if (entities.adventure.byId.hasOwnProperty(id)) {
            if (entities.adventure.byId[id].pk_adventure === rule.fk_adventure) {
                rule.adventure_id = id
                break;
            }
        }
    }
    
    const createRuleAction = yield put(createScheduleRule(rule))
    const rule_m = createRuleAction.payload
    return rule_m
}


/**
 * Função para normalizar um objeto reserve criando suas entities atreladas
 * @param {Object} reserve Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeReserveOnSaga(reserve, singleInsert = true) {
    const entities = yield select(selectEntities)
    const reserve_m = new Reserve(reserve)

    // Se tiver uma aventura, normaliza a aventura
    // if (!reserve.adventure) {
    //     // Busca a aventura já criada na store
    //     const adv_m = Object.values(entities.adventure.byId).find(adv => adv.pk_adventure === reserve.fk_adventure)

    //     if(adv_m){
    //         reserve_m.adventure_id = adv_m.id
    //         reserve_m.adventure = { ...adv_m }
    //     }
    // }

    // Se tiver um evento, normaliza o evento
    if (!!reserve.request && !reserve.request_id) {
        // Busca o evento ja criada na store
        // let request_m = Object.values(entities.request.byId).find(req => req.pk_request === reserve.request.pk_request)

        // Normaliza o evento
        let request_m = yield call(normalizeRequestOnSaga, reserve.request, false)

        reserve_m.request_id = request_m.id
        reserve_m.request = request_m
    }
    
    // Se tiver um pagamento, normaliza o pagamento
    // if (!!reserve.payment) {
    //     // Busca o pagamento ja criado
    //     let pay_m = yield call(() => Object.values(entities.payment.byId).find(pay => pay.pk_payment === reserve.payment.pk_payment))

    //     if (!pay_m) {
    //         // Normaliza pagamento
    //         pay_m = yield call(normalizePaymentOnSaga, reserve.payment)
    //     }

    //     reserve_m.payment_id = pay_m.id
    // }
    
    // Se tiver um pagamento, normaliza o pagamento
    // if (!!reserve.package) {
    //     // Busca o pacote ja criado
    //     let pack_m = yield call(() => Object.values(entities.package.byId).find(pack => pack.pk_package === reserve.package.pk_package))

    //     if (!pack_m) {
    //         // Normaliza pagamento
    //         pack_m = yield call(normalizePackageOnSaga, reserve.package)
    //     }

    //     reserve_m.package_id = pack_m.id
    // } else if (!!reserve.requestPackage) {
    //     // Normaliza pagamento
    //     const pack_m = yield call(normalizePackageOnSaga, reserve.requestPackage)

    //     reserve_m.package_id = pack_m.id
    // }

    // Se tiver um pagamento, normaliza o pagamento
    // if (!!reserve.user) {
    //     // Busca o user ja criado
    //     let user_m = yield call(() => Object.values(entities.user.byId).find(user => user.pk_user === reserve.user.pk_user))

    //     if (!user_m) {
    //         // Normaliza user
    //         user_m = yield call(normalizeUserOnSaga, reserve.user)
    //     }

    //     reserve_m.user_id = user_m.id
    // }
    if(singleInsert)
        yield put(createReserveEntity(reserve_m))

    return reserve_m
}

/**
 * Função para normalizar um objeto payment criando suas entities atreladas
 * @param {Object} payment Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizePaymentOnSaga(payment) {
    const payment_m = new Payment(payment)
    yield put(createPayment(payment_m))
    return payment_m
}

/**
 * Função para normalizar um objeto package criando suas entities atreladas
 * @param {Object} pack Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizePackageOnSaga(pack) {
    const package_m = new Package(pack)
    yield put(createPackage(package_m))
    return package_m
}

/**
 * Função para normalizar um objeto user criando suas entities atreladas
 * @param {Object} user Objeto não normalizado
 * @return {Object} Objeto normalizado
 */
export function* normalizeUserOnSaga(user) {
    const user_m = new User(user)
    yield put(createUser(user_m))
    return user_m
}