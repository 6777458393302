export const FETCH_NOTES_DATE            = 'FETCH_NOTES_DATE';
export const FETCH_NOTES_DATE_SUCCESS    = 'FETCH_NOTES_DATE_SUCCESS';
export const FETCH_NOTES_DATE_ERROR      = 'FETCH_NOTES_DATE_ERROR';

export const SAVE_NOTES                  = 'SAVE_NOTES';
export const SAVE_NOTES_SUCCESS          = 'SAVE_NOTES_SUCCESS';
export const SAVE_NOTES_ERROR            = 'SAVE_NOTES_ERROR';

export const UPDATE_NOTES                = 'UPDATE_NOTES';
export const UPDATE_NOTES_SUCCESS        = 'UPDATE_NOTES_SUCCESS';
export const UPDATE_NOTES_ERROR          = 'UPDATE_NOTES_ERROR';

export const COMPLETE_NOTES              = 'COMPLETE_NOTES';
export const COMPLETE_NOTES_SUCCESS      = 'COMPLETE_NOTES_SUCCESS';
export const COMPLETE_NOTES_ERROR        = 'COMPLETE_NOTES_ERROR';

export const DELETE_NOTES                = 'DELETE_NOTES';
export const DELETE_NOTES_SUCCESS        = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_ERROR          = 'DELETE_NOTES_ERROR';