import { combineReducers } from "redux";
import { 
    CREATE_ADVENTURE,
    UPDATE_ADVENTURE,
    CREATE_NEW_SCRIPT,
    CREATE_NEW_FAQ,
    CREATE_PACKAGE,
    CREATE_SCHEDULE_RULE,
    CREATE_IMAGE,
    CREATE_REQUEST_CUSTOM_FIELD,
    CREATE_RESERVE_CUSTOM_FIELD,
    DELETE_SCRIPT,
    DELETE_FAQ,
    DELETE_PACKAGE,
    DELETE_IMAGE,
    DELETE_REQUEST_CUSTOM_FIELD,
    DELETE_RESERVE_CUSTOM_FIELD,
    DELETE_SCHEDULE_RULE
}  from '../../constants'
import { 
    addScriptSliceById, 
    addFAQSliceById, 
    addPackageSliceById, 
    addScheduleRuleSliceById, 
    addImageSliceById, 
    addRequestCustomFieldSliceById, 
    addReserveCustomFieldSliceById, 
    removeScriptSliceById,
    removeFAQSliceById,
    removePackageSliceById,
    removeScheduleRuleSliceById,
    removeImageSliceById,
    removeRequestCustomFieldSliceById,
    removeReserveCustomFieldSliceById,
    addAdventureBonusSliceById,
    removeAdventureBonusSliceById,
    addEmailAutomationSliceById,
    removeEmailAutomationSliceById,
    addNoteAutomationSliceById,
    removeNoteAutomationSliceById
} from './slices'
import { REFRESH_STORE } from "../../app/constants";
import { LOGOUT_USER } from "../../auth/constants";
import { CREATE_ADVENTURE_BONUS, DELETE_ADVENTURE_BONUS } from "../adventureBonus/constants";
import { CREATE_EMAIL_AUTOMATION, DELETE_EMAIL_AUTOMATION } from "../emailAutomation/constants";
import { CREATE_NOTE_AUTOMATION, DELETE_NOTE_AUTOMATION } from "../noteAutomation/constants";
import { FETCH_COMPANY_ADVENTURES } from "../../adventures/constants";

export function adventureById(state = {}, action) {
    switch (action.type) {
        case CREATE_ADVENTURE:
            return { ...state, [action.payload.id]: { ...action.payload } }
        case UPDATE_ADVENTURE:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}
        case CREATE_NEW_SCRIPT:
            return addScriptSliceById(state, action)
        case DELETE_SCRIPT:
            return removeScriptSliceById(state, action)
        case CREATE_NEW_FAQ:
            return addFAQSliceById(state, action)
        case DELETE_FAQ:
            return removeFAQSliceById(state, action)
        case CREATE_PACKAGE:
            return addPackageSliceById(state, action)
        case DELETE_PACKAGE:
            return removePackageSliceById(state, action)
        case CREATE_SCHEDULE_RULE:
            return addScheduleRuleSliceById(state, action)
        case DELETE_SCHEDULE_RULE:
            return removeScheduleRuleSliceById(state, action)
        case CREATE_IMAGE:
            return addImageSliceById(state, action)
        case DELETE_IMAGE:
            return removeImageSliceById(state, action)
        case CREATE_REQUEST_CUSTOM_FIELD:
            return addRequestCustomFieldSliceById(state, action)
        case DELETE_REQUEST_CUSTOM_FIELD:
            return removeRequestCustomFieldSliceById(state, action)
        case CREATE_RESERVE_CUSTOM_FIELD:
            return addReserveCustomFieldSliceById(state, action)
        case DELETE_RESERVE_CUSTOM_FIELD:
            return removeReserveCustomFieldSliceById(state, action)
        case CREATE_ADVENTURE_BONUS:
            return addAdventureBonusSliceById(state, action)
        case DELETE_ADVENTURE_BONUS:
            return removeAdventureBonusSliceById(state, action)
        case CREATE_EMAIL_AUTOMATION:
            return addEmailAutomationSliceById(state, action)
        case DELETE_EMAIL_AUTOMATION:
            return removeEmailAutomationSliceById(state, action)
        case CREATE_NOTE_AUTOMATION:
            return addNoteAutomationSliceById(state, action)
        case DELETE_NOTE_AUTOMATION:
            return removeNoteAutomationSliceById(state, action)
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        case FETCH_COMPANY_ADVENTURES:
            return {}
        default:
            return state
    }
}

export function adventureAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_ADVENTURE:
            return  [ ...state, action.payload.id ]
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        case FETCH_COMPANY_ADVENTURES:
            return []
        default:
            return state
    }
}

export default combineReducers({
    byId: adventureById,
    allIds: adventureAllIds
})