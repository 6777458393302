import { combineReducers } from "redux";
import { CREATE_NOTE_AUTOMATION, DELETE_NOTE_AUTOMATION, UPDATE_NOTE_AUTOMATION } from "./constants";

export const noteAutomationById = (state = {}, { payload, type }) => {
    switch (type) {
        case CREATE_NOTE_AUTOMATION:
            return { ...state, [payload.id]: payload }
        case UPDATE_NOTE_AUTOMATION:
            return { ...state, [payload.id]: { ...state[payload.id], ...payload } }
        case DELETE_NOTE_AUTOMATION:
            return { ...state, [payload.id]: undefined }
        default:
            return state
    }
}

export const noteAutomationAllIds = (state = [], { payload, type }) => {
    switch (type) {
        case CREATE_NOTE_AUTOMATION:
            return [ ...state, payload.id ]
        case DELETE_NOTE_AUTOMATION:
            return state.reduce((prevState, curId) => curId === payload.id ? prevState : [ ...prevState, payload.id ] , [])
        default:
            return state
    }
}

export default combineReducers({
    byId: noteAutomationById,
    allIds: noteAutomationAllIds
})