import { RequestMessage } from "../../../models";
import { CREATE_REQUEST_MESSAGE_ENTITY, DELETE_REQUEST_MESSAGE_ENTITY, UPDATE_REQUEST_MESSAGE_ENTITY } from "./constants";

export const createRequestMessageEntity = (message) => ({
    type: CREATE_REQUEST_MESSAGE_ENTITY,
    payload: new RequestMessage(message)
})

export const updateRequestMessageEntity = (message) => ({
    type: UPDATE_REQUEST_MESSAGE_ENTITY,
    payload: message
})

export const deleteRequestMessageEntity = (id) => ({
    type: DELETE_REQUEST_MESSAGE_ENTITY,
    payload: { id }
})