import { v4 as uuid4 } from 'uuid'

export class AdventureBonus {
    constructor(json = {}) {
        this.id = json.id || uuid4()
        this.pk_adventure_bonus = json.pk_adventure_bonus || null
        this.adventure_id = json.adventure_id || null
        this.fk_adventure = json.fk_adventure || null
        this.created_at = json.created_at || null
        this.updated_at = json.updated_at || null
        this.is_deleted = json.is_deleted || false
        this.minimal = json.minimal || 0
        this.prize = json.prize || ""
        this.description = json.description || ""
    }
}