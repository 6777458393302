import {
    CREATE_NEW_FAQ,
    EDIT_FAQ,
    DELETE_FAQ
} from '../../constants'
import { combineReducers } from 'redux'
import { LOGOUT_USER } from '../../auth/constants'
import { REFRESH_STORE } from "../../app/constants";

export function faqById(state = {}, action) {
    switch (action.type) {
        case CREATE_NEW_FAQ:
            return { ...state, [action.payload.id]: { ...action.payload }}
        case EDIT_FAQ:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload }}
        case DELETE_FAQ:
            return { ...state, [action.payload.id]: undefined }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function faqAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_NEW_FAQ:
            return [ ...state, action.payload.id ]
        case DELETE_FAQ:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState, [])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}

export default combineReducers({
    byId: faqById,
    allIds: faqAllIds
});