import { EmailAutomation } from "../../../models";
import { CREATE_EMAIL_AUTOMATION, DELETE_EMAIL_AUTOMATION, UPDATE_EMAIL_AUTOMATION } from "./constants";

export const createEmailAutomationEntity = (data) => ({
    type: CREATE_EMAIL_AUTOMATION,
    payload: new EmailAutomation(data)
})

export const updateEmailAutomationEntity = (data) => ({
    type: UPDATE_EMAIL_AUTOMATION,
    payload: data
})

export const deleteEmailAutomationEntity = (id) => ({
    type: DELETE_EMAIL_AUTOMATION,
    payload: { id }
})