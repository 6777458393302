import { CHANGE_OPEN_WIZARD, COMPLETE_WIZARD, REFRESH_STORE, VALIDATE_PASSWORD, VALIDATE_PASSWORD_ERROR, VALIDATE_PASSWORD_SUCCESS } from "./constants";

export const refreshStore = () => ({
    type: REFRESH_STORE
})


export const validatePassword = (password) => ({
    type: VALIDATE_PASSWORD,
    payload: { password }
})

export const validatePasswordSuccess = () => ({
    type: VALIDATE_PASSWORD_SUCCESS
})

export const validatePasswordError = (err) => ({
    type: VALIDATE_PASSWORD_ERROR,
    payload: err
})

export const changeOpenWizard = (wizard) => ({
    type: CHANGE_OPEN_WIZARD,
    payload: { wizard }
})

export const completeWizard = (wizard) => ({
    type: COMPLETE_WIZARD,
    payload: { wizard }
})