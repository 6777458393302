import { NoteAutomation } from "../../../models";
import { CREATE_NOTE_AUTOMATION, DELETE_NOTE_AUTOMATION, UPDATE_NOTE_AUTOMATION } from "./constants";

export const createNoteAutomationEntity = (data) => ({
    type: CREATE_NOTE_AUTOMATION,
    payload: new NoteAutomation(data)
})

export const updateNoteAutomationEntity = (data) => ({
    type: UPDATE_NOTE_AUTOMATION,
    payload: data
})

export const deleteNoteAutomationEntity = (id) => ({
    type: DELETE_NOTE_AUTOMATION,
    payload: { id }
})