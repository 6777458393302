import { v4 as uuid4 } from 'uuid'

export class User {
    constructor(json = {}){
        this.id = json.id || uuid4()
        this.pk_user = json.pk_user || null
        this.name = json.name || ''
        this.phone = json.phone || ''
        this.email = json.email || ''
        this.cpf = json.cpf || ''
    }
}