import { v4 as uuid4 } from 'uuid'
import { COUNTRIES } from '../constants'

export class ReserveCustomField {
    constructor(json = {}) {
        this.id = json.id || uuid4()
        this.pk_reserve_custom_fields = json.pk_reserve_custom_fields || null
        this.fk_adventure = json.fk_adventure || null
        this.adventure_id = json.adventure_id || null
        this.is_visible = json.is_visible === undefined ? true : json.is_visible
        this.title = json.title || ''
        this.type = json.type || ''
        this.options = json.options || ''
        this.icon = json.icon || ''
    }
}

export const reserveCustomFieldsTypes = [
    { value: 'text', label: 'Texto' }, { value: 'date', label: 'Data' }, { value: 'select', label: 'Seleção' }, { value: 'number', label: 'Númerico' }, { value: 'tel', label: 'Telefone' }
]

export const getSeguroCustomFields = (fk_adventure) => {
    const fields = []

    fields.push(new ReserveCustomField({
        title: 'CPF',
        type: 'cpf',
        fk_adventure,
      }))

      fields.push(new ReserveCustomField({
        title: 'Data de Nascimento',
        type: 'birthday',
        fk_adventure
      }))

      fields.push(new ReserveCustomField({
        title: 'Estrangeiro',
        type: 'estrangeiro',
        options: "Não, Sim",
        fk_adventure
      }))

      fields.push(new ReserveCustomField({
        title: 'Nacionalidade',
        type: 'nacionalidade',
        options: COUNTRIES.join(","),
        fk_adventure
      }))

      fields.push(new ReserveCustomField({
        title: 'Passaporte (Apenas estrangeiros)',
        type: 'passaporte',
        fk_adventure
      }))

      return fields
}