import * as CONST from './constants';

const INITIAL_STATE = {
    automaticEmails: [
        {
            id: 0,
            subject: 'Assunto do email',
            emailContent: '<p>Aqui vai o conteúdo do email</p>',
            whenSend: 'BEFORE_EVENT',
            time: 2,
            recipients: {
                organizer: true,
                participants: false,
                otherEmails: 'user@email.com',
            },
        },
    ],
    automaticNotes: [
        {
            id: 0,
            title: 'Título da nota',
            description: 'Esta é uma nota automática.',
            time: 3,
            includedInformation: {
                participantAndTicket: true,
                eventAndOrganizer: true,
            },
            whenSend: 'BEFORE_EVENT',
        },
    ],
    selectedAutomaticEmail: null,
    selectedAutomaticNote: null,
};

const Adventures = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONST.ADD_AUTOMATIC_EMAIL:
            return { ...state, automaticEmails: [...state.automaticEmails, action.email] };
        case CONST.REMOVE_AUTOMATIC_EMAIL:
            return { ...state, automaticEmails: state.automaticEmails.filter((e) => e.id !== action.id) };
        case CONST.UPDATE_AUTOMATIC_EMAIL:
            return {
                ...state,
                automaticEmails: state.automaticEmails.map((e) => (e.id === action.data.id ? action.data : e)),
            };
        case CONST.SELECT_AUTOMATIC_EMAIL:
            return { ...state, selectedAutomaticEmail: state.automaticEmails.filter((e) => e.id === action.id)[0] };
        case CONST.ADD_AUTOMATIC_NOTE:
            return { ...state, automaticNotes: [...state.automaticNotes, action.note] };
        case CONST.REMOVE_AUTOMATIC_NOTE:
            return { ...state, automaticNotes: state.automaticNotes.filter((e) => e.id !== action.id) };
        case CONST.UPDATE_AUTOMATIC_NOTE:
            return {
                ...state,
                automaticNotes: state.automaticNotes.map((e) => (e.id === action.data.id ? action.data : e)),
            };
        case CONST.SELECT_AUTOMATIC_NOTE:
            return { ...state, selectedAutomaticNote: state.automaticNotes.filter((e) => e.id === action.id)[0] };
        default:
            return state;
    }
};

export default Adventures;
