import { v4 as uuid4 } from 'uuid'

export class EmailAutomation {
    constructor(json = {}) {
        this.id = json.id || uuid4()
        this.adventure_id = json.adventure_id || null
        this.pk_automation = json.pk_automation || null
        this.fk_company = json.fk_company || null
        this.fk_adventure = json.fk_adventure || null
        this.subject = json.subject || ""
        this.content = json.content || ""
        this.is_before_event = json.is_before_event || false
        this.interval = json.interval || 0
        this.target = json.target || ""
    }
}