import { combineReducers } from "redux";
import {
    CREATE_SCHEDULE_RULE,
    UPDATE_SCHEDULE_RULE,
    DELETE_SCHEDULE_RULE
} from '../../constants'
import { LOGOUT_USER } from "../../auth/constants";
import { REFRESH_STORE } from "../../app/constants";

export function scheduleRuleById(state = {}, action) {
    switch (action.type) {
        case CREATE_SCHEDULE_RULE:
            return { ...state, [action.payload.id]: { ...action.payload, new: false } }
        case UPDATE_SCHEDULE_RULE:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_SCHEDULE_RULE:
            return { ...state, [action.payload.id]: undefined }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function scheduleRuleAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_SCHEDULE_RULE:
            return [ ...state, action.payload.id ]
        case DELETE_SCHEDULE_RULE:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId] : prevState, [])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}



export default combineReducers({
    byId: scheduleRuleById,
    allIds: scheduleRuleAllIds
})