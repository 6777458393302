import { Api } from '../../helpers/api';
const API = new Api()

class VouchersService {
    getCompanyPurchases(params){
        return API.call('/v2/reserve/get-purchases', 'get', params)
    }

    saveVoucher(data) {
        return API.call('/v2/reserve/create-voucher', 'post', data)
    }

    consumeVoucher(pk_reserve) {
        return API.call('/v2/reserve/validate-voucher', 'post', { pk_reserve })
    }

    cancelVoucher(pk_reserve) {
        return API.call('/v2/reserve/cancel-voucher', 'post', { pk_reserve })
    }

    saveVoucherInfo(voucher) {
        return API.call('/v2/reserve/update-reserve-info', 'post', voucher)
    }

    toggleVoucher(pk_reserve) {
        return API.call('/v2/reserve/toggle-voucher', 'post', { pk_reserve });
    }

    toggleReserve(data) {
        return API.call('/v2/reserve/change-reserve-to-request', 'post', data)
    }
}

export default new VouchersService()