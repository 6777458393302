import { Api } from '../../helpers/api';
const API = new Api();

class AuthService {
    login(data){
        return API.call('/v1/company/login', 'post', data)
    }

    forgotPassword(email){
        return API.call('/v1/company/forgot-password', 'post', { email })
    }

    registerUser(data){
        return API.call('/v2/company/create-company-account', 'post', data)
    }

    updateUser(data) {
        return API.call('/v2/company/update-company-account', 'post', data);
    }

    updateBusinessAttributes(data){
        return API.call('/v1/company/update-business-attributes', 'post', data)
    }

    updateBankAttributes(data){
        return API.call('/v1/company/update-bank-attributes', 'post', data)
    }

    sendDocument(files, user){
        const payload = new FormData()
        files.forEach(file => payload.append(file.juno_type, file))
        payload.append("owner", user.owner)
        payload.append("document", user.document)
        payload.append("bank_number", user.bank_number)
        payload.append("agency_number", user.agency_number)
        payload.append("agency_check_number", user.agency_check_number)
        payload.append("account_number", user.account_number)
        payload.append("account_check_number", user.account_check_number)
        payload.append("account_complement_number", user.account_complement_number)
        return API.call('/v2/juno/create-account', 'post', payload)
    }

    savePagarmeSettings(data) {
        return API.call('/v2/company/save-pagarme-settings', 'post', data);
    }

    saveMercadoPagoToken(data) {
        return API.call('/v2/company/save-mercado-pago-token', 'post', data);
    }

    savePagSeguroCredentials(data) {
        return API.call('/v2/company/save-pag-seguro-credentials', 'post', data);
    }

    saveGetnetCredentials(data) {
        return API.call('/v2/company/save-getnet-credentials', 'post', data);
    }

    createPixKey() {
        return API.call('/v2/juno/create-pix-key', 'post');
    }

    updateCompanyTags(tags){
        return API.call('/v2/company/update-company-tags', 'post', { tags })
    }

    changePassword(new_password){
        return API.call('/v1/company/change-password', 'post', { new_password })
    }

}

export default new AuthService()