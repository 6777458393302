export function sliceDefaultPackage(state = {}, action) {
    return Object.keys(state).reduce((newState, id) => {
        if (id === action.payload.id) {
            return {
                ...newState,
                [id]: { ...action.payload }
            }
        } else {
            return {
                ...newState,
                [id]: { ...state[id], default: false }
            }
        }
    }, {})
}