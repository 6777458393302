import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Api from '../helpers/api'

export const SessionValidator = () => {
    const { user } = useSelector(state => state.Auth)
    const history = useHistory()

    useEffect(() => {
        if(user){
            const { pts } = user
            Api.call('/v2/company/validate-pts', 'post', { pts })
            .then((res) => {
                if(!res.data.pts){
                    history.push('/account/logout')
                }
            })
        }
    }, [user])

    return <></>
}
