import { combineReducers } from 'redux'
import adventureReducer from './adventure/reducers'
import adventureBonusReducer from './adventureBonus/reducers'
import scriptReducer, { scriptItemReducer } from './script/reducers';
import FAQReducer from './FAQ/reducers';
import categoryReducer from './category/reducers';
import packageReducer from './package/reducers';
import scheduleRuleReducer from './scheduleRule/reducers';
import imageReducer from './image/reducers';
import requestCustomFieldReducer from './requestCustomField/reducers';
import reserveCustomFieldReducer from './reserveCustomField/reducers';
import tagReducer from './tag/reducers';
import requestReducer from './request/reducers';
import requestBonusReducer from './requestBonus/reducers';
import requestMessageReducer from './requestMessage/reducers';
import voucherReducer from './voucher/reducers';
import paymentReducer from './payment/reducers';
import userReducer from './user/reducers';
import reserveReducer from './reserve/reducers';
import emailAutomationReducer from './emailAutomation/reducers'
import noteAutomationReducer from './noteAutomation/reducers'


export default combineReducers({
    script: scriptReducer,
    scriptItem: scriptItemReducer,
    adventure: adventureReducer,
    adventureBonus: adventureBonusReducer,
    FAQ: FAQReducer,
    category: categoryReducer,
    package: packageReducer,
    scheduleRule: scheduleRuleReducer,
    image: imageReducer,
    requestCustomField: requestCustomFieldReducer,
    reserveCustomField: reserveCustomFieldReducer,
    tag: tagReducer,
    request: requestReducer,
    requestBonus: requestBonusReducer,
    requestMessage: requestMessageReducer,
    voucher: voucherReducer,
    payment: paymentReducer,
    user: userReducer,
    reserve: reserveReducer,
    emailAutomation: emailAutomationReducer,
    noteAutomation: noteAutomationReducer
})
