import { all, fork, put, delay, takeEvery, call } from "redux-saga/effects";
import { refreshStore, loadCalendar } from "../actions";
import { CHANGE_OPEN_WIZARD, COMPLETE_WIZARD, REFRESH_STORE, VALIDATE_PASSWORD } from "./constants";
import { history, refreshBlockedRoutes } from '../../routes';
import { fetchCompanyPurchases } from "../vouchers/actions";
import AppService from './service';
import { changeOpenWizard, validatePasswordError, validatePasswordSuccess } from "./actions";
import { WIZARDS_ROUTES } from "../../components/FirstStepsWizards";
import { updateUserFields } from "../auth/actions";

// const REFRESH_TIME = 3600000 // 1hr
const REFRESH_TIME = 1800000 // 30min
const REFRESH_TIME_WHEN_BLOCKED = 300000 // 5min

function* refreshStoreSaga(){
    // Refresh calendar
    // yield call(getCompanyAdventures)
    yield put(loadCalendar())
    yield put(fetchCompanyPurchases())
}

export function* backgroundSyncSaga(){
    while (true) {
        const isBlockedRoute = refreshBlockedRoutes.reduce((isBlocked, route) => history.location.pathname.includes(route) || isBlocked , false)

        if (!isBlockedRoute) {
            yield delay(REFRESH_TIME)
            yield put(refreshStore())
        } else {
            yield delay(REFRESH_TIME_WHEN_BLOCKED)
        }

    }
}

export function* validatePasswordSaga({ payload }){
    const res = yield call(AppService.validatePassword, payload.password)
    
    if (res.status === 200) {
        yield put(validatePasswordSuccess())
    } else {
        yield put(validatePasswordError(res))
    }
}

export function* redirectToOpenWizardUrl({ payload }){
    // const route = WIZARDS_ROUTES[payload.wizard]

    // if(route){
    //     history.push(route)
    // }
}

export function* completeAndCloseWizard({ payload }){
    const res = yield call(AppService.completeWizard, payload.wizard)
    if(res.status === 200){
        yield put(updateUserFields(res.data))
    }
}

function* watchRefreshTime(){
    yield takeEvery(REFRESH_STORE, refreshStoreSaga)
}

export function* watchValidatePassword() {
    yield takeEvery(VALIDATE_PASSWORD, validatePasswordSaga)
}

function* watchChangeOpenWizard(){
    yield takeEvery(CHANGE_OPEN_WIZARD, redirectToOpenWizardUrl)
}

function* watchCompleteWizard(){
    yield takeEvery(COMPLETE_WIZARD, completeAndCloseWizard)
}

function* appSaga() {
    yield all([
        // fork(backgroundSyncSaga),
        fork(watchChangeOpenWizard),
        fork(watchCompleteWizard),
        fork(watchValidatePassword),
        fork(watchRefreshTime)
    ]);
}

export default appSaga;