import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Campaign } from '../../models';
import { 
    addCampaignError, 
    addCampaignSuccess, 
    fetchCampaignsError, 
    fetchCampaignsSuccess, 
    updateCampaignError, 
    updateCampaignSuccess 
} from './actions';

import {
	FETCH_CAMPAIGN,
    ADD_CAMPAIGN,
    UPDATE_CAMPAIGN
} from './constants';

import CampaignService from './service';

function* fetchCampaignsSaga({ payload }) {
        const res = yield call(CampaignService.getCampaign, payload);
        if (res.status === 200) {
            yield put(fetchCampaignsSuccess(res.data.map(obj => new Campaign(obj))));
        } else {
            yield put(fetchCampaignsError(res));
        }
}

function* addCampaignSaga({ payload }) {
        const res = yield call(CampaignService.upsertCampaign, payload);
        if (res.status === 200) {
            yield put(addCampaignSuccess({ ...payload, ...res.data }));
        } else {
            yield put(addCampaignError(res));
        }
}

function* updateCampaignSaga({ payload }) {
        const res = yield call(CampaignService.upsertCampaign, payload);
        if (res.status === 200) {
            yield put(updateCampaignSuccess({ ...payload, ...res.data }));
        } else {
            yield put(updateCampaignError(res));
        }
}

export function* watchFetchCampaign() {
	yield takeLatest(FETCH_CAMPAIGN, fetchCampaignsSaga);
}

export function* watchAddCampaign() {
	yield takeLatest(ADD_CAMPAIGN, addCampaignSaga);
}

export function* watchUpdateCampaign() {
	yield takeLatest(UPDATE_CAMPAIGN, updateCampaignSaga);
}

function* campaignSaga() {
	yield all([
		fork(watchFetchCampaign),
        fork(watchAddCampaign),
        fork(watchUpdateCampaign)
	])
}

export default campaignSaga