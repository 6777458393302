import moment from 'moment'
import Color from 'color';
import { REGEX_HOUR } from '../constants';
import { PAYMENT_LABELS } from '../models';

export function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getRandomColorFromNumber(number = Math.round(Math.random() * 100)) {
    const r = (number*8)%256
    const g = (number*9)%256
    const b = (number*7)%256

    return Color(`rgb(${r},${g},${b})`).hex()
}

export const handlePhoneMask = (v) => {
    if(v.length > 15) return v.substring(0,15)
    v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
    return v
}

export const handleNumberMask = (v) => v.replace(/\D/g,"")

export const isRuleForTheDate = (date, rule) => {
    const start_date = moment(rule.start_date)
    const end_date = moment(rule.end_date)
    const isOnRangeDate = rule.end_date ? moment(date).isBetween(start_date, end_date, 'day', '[]') : moment(date).isSameOrAfter(start_date, 'day')
    const isOnWeekday = rule.weekdays.includes(moment(date).weekday());
    return isOnRangeDate && isOnWeekday
}

/**
 * Função para listar o conteudo da agenda de um dia
 * @param {string} date Dia selecionado, formato YYYY-MM-DD
 * @param {Array} schedule Array com objetos de todos os horarios disponiveis
 * @param {Array} requests Array com objetos de todos os eventos
 * @param {Array} googleEvents Array com objetos de todos os eventos do Google Calendar
 */
export function listScheduleForDate(date, schedule, requests, googleEvents = []){
    // Filtra as listas pegando apenas o conteudo para o dia
    const rulesForDate = schedule.reduce((rulesForDay, curRule) => isRuleForTheDate(date,curRule) ? [ ...rulesForDay, curRule] : rulesForDay, [])
    const blocksForDate = googleEvents.reduce((eventsForDay, curEvent) => {
        if (curEvent.start) {
            const eventDate = moment(curEvent.start.date ? curEvent.start.date : curEvent.start.dateTime)
            if(eventDate.isSame(moment(date), 'day')){
                return [ ...eventsForDay, curEvent]
            }
        }
        return eventsForDay
    }, [])
    // Esse array vai sofrer alterações mais pra frente
    const requestsForDate = requests.reduce((requestsForDay,curReq) => moment(curReq.date).isSame(moment(date), 'day') ? [ ...requestsForDay, curReq] : requestsForDay, [])

    // Faz o merge das 3 listas
    let scheduleList = rulesForDate.map(rule => {
        const ruleDateStart = moment(date).add(rule.hour, 'hours')
        const ruleDateEnd = ruleDateStart.clone().add(rule.adventure.duration, 'hours')

        // Tenta encontrar um evento que sobrepõe o horario atual
        const requestIndex = requestsForDate.findIndex(req => req.fk_adventure === rule.fk_adventure && ruleDateStart.isSame(moment(req.date)))

        // Se encontrou, remove o evento da lista e retorna ele
        if (requestIndex !== -1) {
            return requestsForDate.splice(requestIndex, 1)[0]
        }

        // Tenta encontrar um bloqueio de agenda que sobrepõe o horario atual
        const block = blocksForDate.find(event => {
            // Se não é um bloqueio para a mesma experiencia, ignora
            if (event.adventure.pk_adventure !== rule.fk_adventure)
                return false
            
            // Se for um bloqueio de dia inteiro, bloqueia
            if (event.start.date)
                return true

            // Se o horario de fim do bloqueio for antes da horario de inicio disponivel
            // ou
            // Se o horario de inicio do bloqueio for depois do horario de fim disponivel
            // Ignora o bloqueio
            return !(moment(event.start.dateTime).isSameOrAfter(ruleDateEnd) || moment(event.end.dateTime).isSameOrBefore(ruleDateStart))
            
        })
        if (block) return { ...block, hour: rule.hour }

        // Se não encontrou um evento ou bloqueio, mostra o horário disponível
        return rule
    })
    
    // Insere os eventos faltantes
    requestsForDate.forEach(event => scheduleList.push(event))
    
    // Orderna a lista
    scheduleList = scheduleList.sort((a, b) => {        
        const hourA = a.hour || moment(a.date).format("HH:mm")
        const hourB = b.hour || moment(b.date).format("HH:mm")
        
        return hourA < hourB ? -1 : 1
    })

    return scheduleList
}

export const toggleList = (list, value, toggle) => {
    if(toggle){
        return [ ...list, value ]
    } else {
        return list.filter(item => item.pk_package !== value.pk_package);
    }
}

export const toggleWeekDays = (list, value, toggle) => {
    if(toggle){
        return [ ...list, value ]
    } else {
        return list.reduce((prevValue, currentValue) => currentValue !== value ? [ ...prevValue, currentValue ] : prevValue, [])
    }
}

export const validateAdventureFields = (fields, adventure) => {
    let errors = []
    fields.forEach(field => {
        const value = adventure[field]
        switch (field) {
            case 'title':
                if(!value)
                    errors.push({ message: 'O campo de título é obrigatório' })
                break;
            case 'fk_category':
                if(!value)
                    errors.push({ message: 'O campo de categoria é obrigatório' })
                break;
            case 'long_description':
                if(!value)
                    errors.push({ message: 'O campo de descrição é obrigatório' })
                break;
            case 'lat':
                if(!value)
                    errors.push({ message: 'O campo de endereço é obrigatório' })
                break;
            case 'termsAcceptance':
                if(!value)
                    errors.push({ message: 'É necessário aceitar os termos para continuar' })
                break;
            case 'requestCustomFields':
                if(!value || value.reduce((hasError, cf) => hasError || (!cf.title || !cf.type), false))
                    errors.push({ message: 'Existem campos personalizados para o evento cadastrados incorretamente'})
            case 'reserveCustomFields':
                if(!value || value.reduce((hasError, cf) => hasError || (!cf.title || !cf.type), false))
                    errors.push({ message: 'Existem campos personalizados para o ingresso cadastrados incorretamente'})
            case 'sell_type':
                if (!value) {
                    errors.push({ message: 'É preciso selecionar uma opção' })
                } else if (['event', 'trip', 'daily', 'schedule'].includes(adventure[field]) && !adventure.online_scheduling) {
                    errors.push({ message: 'É necessário utilizar o Offstation para selecionar esta opção' })
                }
                break;
            case 'voucher':
                errors = [ ...errors, ...validateVoucher(adventure)]
                break;
            case 'event':
                errors = [ ...errors, ...validateEvent(adventure)]
                break;
            case 'trip':
                errors = [ ...errors, ...validateTrip(adventure)]
                break;
            case 'daily':
                errors = [ ...errors, ...validateDaily(adventure)]
                break;
            case 'schedule':
                errors = [ ...errors, ...validateSchedule(adventure)]
                break;
            default:
                break;
        }
    })
    return errors
}


export const validateVoucher = (adventure) => {
    const errors = [];
    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }

    if (!adventure.voucher_expiration_days) {
        errors.push({ message: "É necessário configurar a validade dos vouchers" })
    }

    if (!adventure.voucher_terms) {
        errors.push({ message: "É necessário descrever os termos de uso do voucher" })
    }
    return errors
}

export const validateEvent = (adventure) => {
    const errors = []
    if (!adventure.start_date && !adventure.scheduleRules.find(rule => !!rule.start_date)) {
        errors.push({ message: "É necessário informar uma data de inicio" })
    }

    if (!adventure.start_hour && !adventure.scheduleRules.find(rule => !!rule.start_hour)) {
        errors.push({ message: "É necessário informar um horário de inicio" })
    }

    if (!adventure.duration || !(new RegExp(REGEX_HOUR).test(adventure.duration))) {
        errors.push({ message: "É necessário informar a duração da atividade (Formato: HH:mm)" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }
    return errors
}

export const validateTrip = (adventure) => {
    const errors = []
    if (!adventure.start_date && !adventure.scheduleRules.find(rule => !!rule.start_date)) {
        errors.push({ message: "É necessário informar uma data de inicio" })
    }

    if (!adventure.start_hour && !adventure.scheduleRules.find(rule => !!rule.start_hour)) {
        errors.push({ message: "É necessário informar um horário de inicio" })
    }

    if (!adventure.duration || !(new RegExp(REGEX_HOUR).test(adventure.duration))) {
        errors.push({ message: "É necessário informar a duração da atividade (Formato: HH:mm)" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }

    // if (!adventure.scripts.length) {
    //     errors.push({ message: "É necessário configurar pelo menos uma etapa do roteiro"})
    // }
    return errors
}

export const validateDaily = (adventure) => {
    const errors = []
    if (!(adventure.weekdays && adventure.weekdays.length) && !(adventure.scheduleRules.reduce((hasWeekDays, rule) => hasWeekDays || !!rule.weekdays.length, false))) {
        errors.push({ message: "É necessário estar disponível pelo menos em um dia da semana" })
    }

    if (!adventure.start_hour && !adventure.scheduleRules.find(rule => !!rule.start_hour)) {
        errors.push({ message: "É necessário informar um horário de inicio" })
    }

    if (!adventure.duration || !(new RegExp(REGEX_HOUR).test(adventure.duration))) {
        errors.push({ message: "É necessário informar a duração da atividade (Formato: HH:mm)" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }
    return errors
}

export const validateSchedule = (adventure) => {
    const errors = []
    if (!adventure.duration || !(new RegExp(REGEX_HOUR).test(adventure.duration))) {
        errors.push({ message: "É necessário informar a duração da atividade (Formato: HH:mm)" })
    }

    if (!adventure.min || !adventure.max || adventure.min > adventure.max) {
        errors.push({ message: "Número de participantes inválido" })
    }

    if (!adventure.packages.length) {
        errors.push({ message: "É necessário configurar pelo menos um pacote" })
    }

    if (!adventure.scheduleRules.length) {
        errors.push({ message: "É necessário configurar pelo menos um horário" })
    }
    return errors
}

export const formatHours = () => {

}

export const numberToCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);
}

export const paymentStatus = (status) => {
    switch(status) {
        default:
            return 'Não informado';
        case 'CREATED':
            return 'Criado';
        case 'WAITING':
            return 'Aguardando';
        case 'IN_ANALYSIS':
            return 'Em Análise';
        case 'PRE_AUTHORIZED':
            return 'Pré-autorizado';
        case 'AUTHORIZED':
        case 'CONFIRMED':
            return 'Autorizado';
        case 'CANCELED':
            return 'Negado';
        case 'REFUNDED':
            return 'Reembolsado';
        case 'REVERSED':
            return 'Estornado';
        case 'SETTLED':
            return 'Confirmado';
    }
}

export const paymentType = (type) => {
    switch(type) {
        default:
            return 'Transferência';
        case 'CREDIT_CARD':
            return 'Cartão de Crédito';
        case 'BOLETO':
            return 'Boleto';
        case 'LOCAL':
            return 'Outros meios';
    }
}

export const adjustMoipNumber = (number) => {
    if (!number || number === 0) return 0

    const arr = number.toString().split('')
    arr.splice(arr.length - 2, 0, '.')
    const formattedNumber = Number(arr.join(''))
    return formattedNumber
}

/**
 * Remove um item de um array e retorna uma nova instancia do array
 * @param {Array} array Array dos items
 * @param {*} item Item a ser removido
 * @returns {Array} Novo array
 */
export const reduceItemFromArray = (array, item) => {
    return array.reduce((prevItems, curItem) => curItem !== item ? [ ...prevItems, curItem ] : prevItems,[])
}

export const lTrimChar = (char, string) => {
    let pos = 0
    while (string[pos] === char && pos < string.length) {
        pos++
    }
    return string.substr(pos)
}

export const makeFullDateArray = (visits, x = 'date', y = 'value') => {
    if (!visits.length) return visits

    const fullArray = [ { x: visits[0][x], y: Number(visits[0][y]) } ]

    let newTotal = Number(visits[0][y]), lastDay = moment(visits[0][x]).add(1, "day");

    for (let i = 1; i < visits.length; i++) {
        const element = visits[i];
        while (lastDay.isBefore(moment(element[x]),'day')) {
            fullArray.push({ x: lastDay.format("YYYY-MM-DD"), y: 0 })
            lastDay.add(1, "day")
        }
        fullArray.push({ x: element[x], y: Number(element[y]) })
        newTotal += Number(element[y])
        lastDay = moment(element[x]).add(1, 'day')
    }
    return [newTotal, fullArray]
}

export const comparePercentageDifference = (initialValue, newValue) => {
    initialValue = Number(initialValue)
    newValue = Number(newValue)

    if(!initialValue && !newValue){
        return 0
    } else if (!initialValue) {
        return 100
    } else {
        return ((newValue - initialValue)/initialValue)*100
    }
}

export const renderStatus = (item) => {
    if (item.type === "voucher") {
        if (item.is_consumed) {
            return "Consumido"
        } else if (item.is_deleted) {
            return "Cancelado"
        } else if (moment(item.expiration_date).isSameOrBefore(moment())) {
            return "Expirado"
        } else {
            return "Ativo"
        }
    } else {
        if (item.is_consumed) {
            return "Consumido"
        } else if (item.is_deleted) {
            return "Cancelado"
        } else {
            return "Confirmado"
        }
    }
}

export const renderReserveStatus = (item) => {
    if (item.is_consumed) {
        return "consumed"
    } else if (item.is_deleted || (!!item.expiration_date && moment(item.expiration_date).isSameOrBefore(moment()))) {
        return "cancelled"
    } else {
        return "active"
    }
}

export const renderPaymentStatus = (payment) => {
    const confirmedStatus = ["CONFIRMED", "PAID", "SETTLED", "AUTHORIZED"]
    const waitingStatus = ["WAITING", "PENDING", "FREE"]
    const cancelledStatus = ["CANCELLED", "CUSTOMER_PAID_BACK"]

    if (confirmedStatus.includes(payment.payment_status)) {
        return "confirmed"
    } else if (waitingStatus.includes(payment.payment_status)) {
        return "waiting"
    } else if (cancelledStatus.includes(payment.payment_status)) {
        return "cancelled"
    }
}


export const reserveCSVHeaders = () => {
    return [
        'Código',
        'Nome',
        'Telefone',
        'Email',
        'Campos personalizados',
        'Observações',
        'Pacote',
        'Data de criação do ingresso',
        'Experiência',
        'Data atribuida do ingresso',
        'Status',
        'Conta logada',
        'Telefone conta logada',
        'Email conta logada',
        'Valor original do pacote',
        'Valor pago',
        'Código do pagamento',
        'Data de criação do pagamento',
        'Pagante',
        'Telefone do pagante',
        'Método de pagamento',
        'Status do pagamento',
        'Flags'
    ]
}

export const mapReserveToFlatArray = (item) => {
    const row = []
    row.push(item.pk_reserve)
    row.push(item.name)
    row.push(item.phone)
    row.push(item.email)
    row.push(item.custom_fields.map(field => `${field.title}: ${field.value}`).join(" | "))
    row.push(item.observation)
    row.push(!!item.package && item.package.name)
    row.push(moment(item.created_at).format('DD/MM/YYYY HH:mm'))
    row.push(!!item.adventure && item.adventure.title)
    row.push(item.type === "voucher" ? `Ingresso válido até ${moment(item.expiration_date).format('DD/MM/YYYY')}` : `Ingresso para ${!!item.request && moment(item.request.date).format('DD/MM/YYYY HH:mm')}`)
    row.push(renderStatus(item))
    row.push(!!item.user && item.user.name)
    row.push(!!item.user && item.user.phone)
    row.push(!!item.user && item.user.email)
    row.push(!!item.package && Number(item.package.price))
    row.push(!!item.package && (item.package.priceWithDiscount ? Number(item.package.priceWithDiscount) : Number(item.package.price)))
    row.push(!!item.payment && item.payment.payment_id)
    row.push(!!item.payment && moment(item.payment.created_at).format('DD/MM/YYYY HH:mm'))
    row.push(!!item.user && item.user.name)
    row.push(!!item.user && item.user.phone)
    row.push(!!item.payment && PAYMENT_LABELS.METHODS[item.payment.method])
    row.push(!!item.payment && `Pag. ${PAYMENT_LABELS.STATUS[item.payment.payment_status]}`)
    row.push(item.tags.map((flag) => flag.name).join("|"))
    return row
}