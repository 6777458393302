import { Api } from '../../helpers/api';
const API = new Api();

class AppService {
    validatePassword(password){
        return API.call('/v2/company/validate-login', 'post', { password })
    }

    completeWizard(wizard){
        return API.call('/v2/company/complete-wizard', 'post', { wizard })
    }

}

export default new AppService()