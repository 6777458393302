import Api from '../../helpers/api';

class CampaignService {
	getCampaign() {
		return Api.call('/v1/discount/get-discounts', 'get')
	}

    upsertCampaign(discount) {
        return Api.call('/v1/discount/upsert-discount', 'post', { discount })
    }
}

export default new CampaignService()