import { Adventure } from '../../models'
import {
    FETCH_REQUEST_BY_PK,
    FETCH_REQUEST_BY_PK_ERROR,
    FETCH_REQUEST_BY_PK_SUCCESS,
    CHANGE_VIEW_MODE,
    CHANGE_CURRENT_DATE,
    FETCH_REQUESTS_FOR_MONTH,
    FETCH_REQUESTS_FOR_MONTH_SUCCESS,
    FETCH_REQUESTS_FOR_MONTH_ERROR,
    FETCH_REQUESTS_FOR_DAY,
    FETCH_REQUESTS_FOR_DAY_SUCCESS,
    FETCH_REQUESTS_FOR_DAY_ERROR,
    FETCH_SCHEDULE,
    FETCH_SCHEDULE_SUCCESS,
    FETCH_SCHEDULE_ERROR,
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_ERROR,
    CANCEL_REQUEST,
    CANCEL_REQUEST_SUCCESS,
    CANCEL_REQUEST_ERROR,
    CANCEL_RESERVE,
    CANCEL_RESERVE_SUCCESS,
    CANCEL_RESERVE_ERROR,
    CHANGE_REQUEST_DATE,
    CHANGE_REQUEST_DATE_SUCCESS,
    CHANGE_REQUEST_DATE_ERROR,
    SELECT_REQUEST,
    SAVE_REQUEST_BONUSES,
    SAVE_REQUEST_BONUSES_SUCCESS,
    SAVE_REQUEST_BONUSES_ERROR,
    LOAD_CALENDAR
} from '../constants'
import { CALENDAR_LODADED, RELOAD_REQUESTS, CHANGE_REQUEST_INFO, CHANGE_REQUEST_INFO_SUCCESS, CHANGE_REQUEST_INFO_ERROR, CHANGE_RESERVE_INFO, CHANGE_RESERVE_INFO_SUCCESS, CHANGE_RESERVE_INFO_ERROR, SEND_EMAIL, SEND_EMAIL_ERROR, SEND_EMAIL_SUCCESS, UPDATE_REQUEST_TAGS, UPDATE_REQUEST_TAGS_SUCCESS, UPDATE_REQUEST_TAGS_ERROR, UPDATE_RESERVE_TAGS, UPDATE_RESERVE_TAGS_SUCCESS, UPDATE_RESERVE_TAGS_ERROR, CHECK_IN_RESERVE, CHECK_IN_RESERVE_SUCCESS, CHECK_IN_RESERVE_ERROR, FETCH_GOOGLE_EVENTS, FETCH_GOOGLE_EVENTS_SUCCESS, FETCH_GOOGLE_EVENTS_ERROR, CHANGE_RESERVE_REQUEST, CHANGE_RESERVE_REQUEST_SUCCESS, CHANGE_RESERVE_REQUEST_ERROR, SAVE_REQUEST_MESSAGES, SAVE_REQUEST_MESSAGES_SUCCESS, SAVE_REQUEST_MESSAGES_ERROR, FETCH_AVAIABLE_REQUESTS, FETCH_AVAIABLE_REQUESTS_SUCCESS, FETCH_AVAIABLE_REQUESTS_ERROR, LOAD_ADVENTURES_FOR_SCHEDULE, LOAD_ADVENTURES_FOR_SCHEDULE_SUCCESS, LOAD_ADVENTURES_FOR_SCHEDULE_ERROR, CREATE_SINGLE_SCHEDULE_RULE, CREATE_SINGLE_SCHEDULE_RULE_SUCCESS, CREATE_SINGLE_SCHEDULE_RULE_ERROR, UPDATE_SINGLE_SCHEDULE_RULE, UPDATE_SINGLE_SCHEDULE_RULE_SUCCESS, UPDATE_SINGLE_SCHEDULE_RULE_ERROR, DELETE_SINGLE_SCHEDULE_RULE, DELETE_SINGLE_SCHEDULE_RULE_SUCCESS, DELETE_SINGLE_SCHEDULE_RULE_ERROR, SAVE_REQUEST_PACKAGES, SAVE_REQUEST_PACKAGES_SUCCESS, SAVE_REQUEST_PACKAGES_ERROR, LOAD_RESERVES_FOR_REQUESTS, LOAD_RESEREVS_FOR_REQUESTS_SUCCESS, LOAD_RESERVES_FOR_REQUEST_SUCCESS, LOAD_RESERVES_FOR_REQUEST_ERROR, CONFIRM_RESERVE_PAYMENT, CONFIRM_RESERVE_PAYMENT_SUCCESS, CONFIRM_RESERVE_PAYMENT_ERROR, CHANGE_RESERVE_EXPIRATION, CHANGE_RESERVE_EXPIRATION_SUCCESS, CHANGE_RESERVE_EXPIRATION_ERROR } from './constants'

export const fetchRequestsForMonth = (date) => ({
    type: FETCH_REQUESTS_FOR_MONTH,
    payload: { date, preview: true }
})

export const fetchRequestsForMonthSuccess = (ids) => ({
    type: FETCH_REQUESTS_FOR_MONTH_SUCCESS,
    payload: ids
})

export const fetchRequestsForMonthError = (error) => ({
    type: FETCH_REQUESTS_FOR_MONTH_ERROR,
    payload: error
})

export const fetchRequestsForDay = (date) => ({
    type: FETCH_REQUESTS_FOR_DAY,
    payload: { date }
})

export const fetchRequestsForDaySuccess = (ids) => ({
    type: FETCH_REQUESTS_FOR_DAY_SUCCESS,
    payload: ids
})

export const fetchRequestsForDayError = (error) => ({
    type: FETCH_REQUESTS_FOR_DAY_ERROR,
    payload: error
})

export const fetchRequestByPk = (pk_request) => ({
    type: FETCH_REQUEST_BY_PK,
    payload: { pk_request }
})

export const fetchRequestByPkSuccess = (data) => ({
    type: FETCH_REQUEST_BY_PK_SUCCESS,
    payload: data
})

export const fetchRequestByPkError = (error) => ({
    type: FETCH_REQUEST_BY_PK_ERROR,
    payload: error
})

export const fetchSchedule = () => ({
    type: FETCH_SCHEDULE
})

export const fetchScheduleSuccess = (data) => ({
    type: FETCH_SCHEDULE_SUCCESS,
    payload: data
})

export const fetchScheduleError = (error) => ({
    type: FETCH_SCHEDULE_ERROR,
    payload: error
})

export const fetchGoogleEvents = (date) => ({
    type: FETCH_GOOGLE_EVENTS,
    payload: { date }
})

export const fetchGoogleEventsSuccess = (data) => ({
    type: FETCH_GOOGLE_EVENTS_SUCCESS,
    payload: data
})

export const fetchGoogleEventsError = (error) => ({
    type: FETCH_GOOGLE_EVENTS_ERROR,
    payload: error
})

export const changeViewMode = (mode) => ({
    type: CHANGE_VIEW_MODE,
    payload: { mode }
})

export const changeCurrentDate = (date) => ({
    type: CHANGE_CURRENT_DATE,
    payload: { date }
})

export const createRequest = (data) => ({
    type: CREATE_REQUEST,
    payload: data
})

export const createRequestSuccess = (id) => ({
    type: CREATE_REQUEST_SUCCESS,
    payload: { id }
})

export const createRequestError = (err) => ({
    type: CREATE_REQUEST_ERROR,
    payload: err
})

export const cancelRequest = (request, reason) => ({
    type: CANCEL_REQUEST,
    payload: { request, reason }
})

export const cancelRequestSuccess = () => ({
    type: CANCEL_REQUEST_SUCCESS
})

export const cancelRequestError = (err) => ({
    type: CANCEL_REQUEST_ERROR,
    payload: err
})

export const changeReserveInfo = (data) => ({
    type: CHANGE_RESERVE_INFO,
    payload: data
})

export const changeReserveInfoSuccess = () => ({
    type: CHANGE_RESERVE_INFO_SUCCESS,
})

export const changeReserveInfoError = (err) => ({
    type: CHANGE_RESERVE_INFO_ERROR,
    payload: err
})

export const cancelReserve = (reserve) => ({
    type: CANCEL_RESERVE,
    payload: reserve
})

export const cancelReserveSuccess = () => ({
    type: CANCEL_RESERVE_SUCCESS
})

export const cancelReserveError = (err) => ({
    type: CANCEL_RESERVE_ERROR,
    payload: err
})

export const changeRequestInfo = (data) => ({
    type: CHANGE_REQUEST_INFO,
    payload: data
})

export const changeRequestInfoSuccess = () => ({
    type: CHANGE_REQUEST_INFO_SUCCESS
})

export const changeRequestInfoError = (err) => ({
    type: CHANGE_REQUEST_INFO_ERROR,
    payload: err
})

export const changeRequestDate = (request, date) => ({
    type: CHANGE_REQUEST_DATE,
    payload: { request, date }
})

export const changeRequestDateSuccess = () => ({
    type: CHANGE_REQUEST_DATE_SUCCESS
})

export const changeRequestDateError = (err) => ({
    type: CHANGE_REQUEST_DATE_ERROR,
    payload: err
})

export const selectRequest = (id) => ({
    type: SELECT_REQUEST,
    payload: { id }
})

export const loadCalendar = (date = undefined) => ({
    type: LOAD_CALENDAR,
    payload: { date }
})

export const calendarLoaded = () => ({
    type: CALENDAR_LODADED
})

export const reloadRequests = () => ({
    type: RELOAD_REQUESTS
})

export const sendEmail = (data) => ({
    type: SEND_EMAIL,
    payload: data
})

export const sendEmailSuccess = (emailType) => ({
    type: SEND_EMAIL_SUCCESS,
    payload: { emailType }
})

export const sendEmailError = (error, emailType) => ({
    type: SEND_EMAIL_ERROR,
    payload: { error , emailType }
})

export const updateRequestTags = (tags, request) => ({
    type: UPDATE_REQUEST_TAGS,
    payload: { tags, request }
})

export const updateRequestTagsSuccess = () => ({
    type: UPDATE_REQUEST_TAGS_SUCCESS,
})

export const updateRequestTagsError = (err) => ({
    type: UPDATE_REQUEST_TAGS_ERROR,
    payload: err
})

export const updateReserveTags = (tags, reserve) => ({
    type: UPDATE_RESERVE_TAGS,
    payload: { tags, reserve }
})

export const updateReserveTagsSuccess = () => ({
    type: UPDATE_RESERVE_TAGS_SUCCESS,
})

export const updateReserveTagsError = (err) => ({
    type: UPDATE_RESERVE_TAGS_ERROR,
    payload: err
})

export const checkInReserve = (data, checkin) => ({
    type: CHECK_IN_RESERVE,
    payload: { data, checkin }
})

export const checkInReserveSuccess = () => ({
    type: CHECK_IN_RESERVE_SUCCESS
})

export const checkInReserveError = (err) => ({
    type: CHECK_IN_RESERVE_ERROR,
    payload: err
})

export const changeReserveRequest = (pk_reserve, reserve_id, pk_request) => ({
    type: CHANGE_RESERVE_REQUEST,
    payload: { pk_reserve, pk_request, reserve_id }
})

export const changeReserveRequestSuccess = () => ({
    type: CHANGE_RESERVE_REQUEST_SUCCESS
})

export const changeReserveRequestError = (err) => ({
    type: CHANGE_RESERVE_REQUEST_ERROR,
    payload: err
})

export const saveRequestBonuses = (bonuses, pk_request) => ({
    type: SAVE_REQUEST_BONUSES,
    payload: { bonuses, pk_request}
})

export const saveRequestBonusesSuccess = () => ({
    type: SAVE_REQUEST_BONUSES_SUCCESS
})

export const saveRequestBonusesError = (err) => ({
    type: SAVE_REQUEST_BONUSES_ERROR,
    payload: err
})

export const saveRequestMessages = (messages, pk_request) => ({
    type: SAVE_REQUEST_MESSAGES,
    payload: { messages, pk_request}
})

export const saveRequestMessagesSuccess = () => ({
    type: SAVE_REQUEST_MESSAGES_SUCCESS
})

export const saveRequestMessagesError = (err) => ({
    type: SAVE_REQUEST_MESSAGES_ERROR,
    payload: err
})

export const fetchAvaiableRequests = () => ({
    type: FETCH_AVAIABLE_REQUESTS
})

export const fetchAvaiableRequestsSuccess = (data) => ({
    type: FETCH_AVAIABLE_REQUESTS_SUCCESS,
    payload: data
})

export const fetchAvaiableRequestsError = (err) => ({
    type: FETCH_AVAIABLE_REQUESTS_ERROR,
    payload: err
})

export const loadAdventuresForSchedule = () => ({
    type: LOAD_ADVENTURES_FOR_SCHEDULE
})

export const loadAdventuresForScheduleSuccess = (data) => ({
    type: LOAD_ADVENTURES_FOR_SCHEDULE_SUCCESS,
    payload: data.map(adv => new Adventure(adv))
})

export const loadAdventuresForScheduleError = (err) => ({
    type: LOAD_ADVENTURES_FOR_SCHEDULE_ERROR,
    payload: err
})

export const createSingleScheduleRule = (data) => ({
    type: CREATE_SINGLE_SCHEDULE_RULE,
    payload: data
})

export const createSingleScheduleRuleSuccess = (data) => ({
    type: CREATE_SINGLE_SCHEDULE_RULE_SUCCESS,
    payload: data
})

export const createSingleScheduleRuleError = (err) => ({
    type: CREATE_SINGLE_SCHEDULE_RULE_ERROR,
    payload: err
})

export const updateSingleScheduleRule = (data) => ({
    type: UPDATE_SINGLE_SCHEDULE_RULE,
    payload: data
})

export const updateSingleScheduleRuleSuccess = (data) => ({
    type: UPDATE_SINGLE_SCHEDULE_RULE_SUCCESS,
    payload: data
})

export const updateSingleScheduleRuleError = (err) => ({
    type: UPDATE_SINGLE_SCHEDULE_RULE_ERROR,
    payload: err
})

export const deleteSingleScheduleRule = (data) => ({
    type: DELETE_SINGLE_SCHEDULE_RULE,
    payload: data
})

export const deleteSingleScheduleRuleSuccess = (data) => ({
    type: DELETE_SINGLE_SCHEDULE_RULE_SUCCESS,
    payload: data
})

export const deleteSingleScheduleRuleError = (err) => ({
    type: DELETE_SINGLE_SCHEDULE_RULE_ERROR,
    payload: err
})

export const saveRequestPackages = (data) => ({
    type: SAVE_REQUEST_PACKAGES,
    payload: data
})

export const saveRequestPackagesSuccess = (data) => ({
    type: SAVE_REQUEST_PACKAGES_SUCCESS,
    payload: data
})

export const saveRequestPackagesError = (err) => ({
    type: SAVE_REQUEST_PACKAGES_ERROR,
    payload: err
})

export const loadReservesForRequests = (payload) => ({
    type: LOAD_RESERVES_FOR_REQUESTS,
    payload
})

export const loadReservesForRequestsSuccess = (payload) => ({
    type: LOAD_RESEREVS_FOR_REQUESTS_SUCCESS,
    payload
})

export const loadReservesForRequestSuccess = (id) => ({
    type: LOAD_RESERVES_FOR_REQUEST_SUCCESS,
    payload: { id }
})

export const loadReservesForRequestError = (id) => ({
    type: LOAD_RESERVES_FOR_REQUEST_ERROR,
    payload: { id }
})

export const confirmReservePayment = (reserve) => ({
    type: CONFIRM_RESERVE_PAYMENT,
    payload: reserve
})

export const confirmReservePaymentSuccess = () => ({
    type: CONFIRM_RESERVE_PAYMENT_SUCCESS
})

export const confirmReservePaymentError = (err) => ({
    type: CONFIRM_RESERVE_PAYMENT_ERROR,
    payload: err
})

export const changeReserveExpiration = (reserve, date) => ({
    type: CHANGE_RESERVE_EXPIRATION,
    payload: { reserve, date }
})

export const changeReserveExpirationSuccess = () => ({
    type: CHANGE_RESERVE_EXPIRATION_SUCCESS
})

export const changeReserveExpirationError = (err) => ({
    type: CHANGE_RESERVE_EXPIRATION_ERROR,
    payload: err
})