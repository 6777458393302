import { combineReducers } from "redux";
import {
    CREATE_TAG
} from '../../constants'
import { LOGOUT_USER } from "../../auth/constants";
import { REFRESH_STORE } from "../../app/constants";

export function tagById(state = {}, action) {
    switch (action.type) {
        case CREATE_TAG:
            return { ...state, [action.payload.id]: action.payload }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function tagAllIds(state = [], action) {
    switch (action.type) {
        case CREATE_TAG:
            return [ ...state, action.payload.id ]
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}


export default combineReducers({
    byId: tagById,
    allIds: tagAllIds
})