import { LOGOUT_USER } from '../auth/constants';
import { CLEAR_ERROR } from '../constants';
import {
	FETCH_CART_LIST,
	FETCH_CART_LIST_SUCCESS,
	FETCH_CART_LIST_FAILURE,
} from './constants';

const initialState = {
	cartCount: null,
	cartList: [],
	curPage: 1,
	curSearch: "",
	loading: true,
	error: null,
}

const Carts = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CART_LIST:
			return { ...state, curPage: action.payload.page, curSearch: action.payload.search, cartList: action.payload.page === 1 ? [] : state.cartList, loading: true }

		case FETCH_CART_LIST_SUCCESS:
			return { ...state, loading: false, cartList: action.payload.items, total: Number(action.payload.total) }

		case FETCH_CART_LIST_FAILURE:
			return { ...state, loading: false, error: { ...action.payload } }

		case LOGOUT_USER:
			return initialState

		case CLEAR_ERROR:
			return { ...state, error: null }

		default:
			return state;
	}
}

export default Carts
