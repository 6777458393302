import { v4 as uuid4 } from 'uuid'

export class RequestBonus {
    constructor(json = {}) {
        this.id = json.id || uuid4()
        this.pk_request_bonus = json.pk_request_bonus || null
        this.request_id = json.request_id || null
        this.fk_request = json.fk_request || null
        this.created_at = json.created_at || null
        this.updated_at = json.updated_at || null
        this.is_deleted = json.is_deleted || false
        this.minimal = json.minimal || 0
        this.prize = json.prize || ""
        this.description = json.description || ""
    }
}