import {
	FETCH_CART_LIST,
	FETCH_CART_LIST_SUCCESS,
	FETCH_CART_LIST_FAILURE,
} from './constants';

export const fetchCartList = (payload) => ({
	type: FETCH_CART_LIST,
	payload
});

export const fetchCartListSuccess = (payload) => ({
	type: FETCH_CART_LIST_SUCCESS,
	payload
});

export const fetchCartListFailure = (payload) => ({
	type: FETCH_CART_LIST_FAILURE,
	payload
});