import { v4 as uuid4 } from 'uuid'
import moment from 'moment'

export class Voucher {
    constructor(json = {}) {
        this.id = json.id || uuid4()
        this.pk_voucher = json.pk_voucher || null
        this.adventure_id = json.adventure_id || null
        this.fk_adventure = json.fk_adventure || null
        this.payment_id = json.payment_id || null
        this.fk_payment = json.fk_payment || null
        this.package_id = json.package_id || null
        this.fk_package = json.fk_package || null
        this.user_id = json.user_id || null
        this.fk_user = json.fk_user || null
        this.type = "voucher"

        this.code = json.code || ''
        this.expiration_date = json.expiration_date || ''
        this.created_at = json.created_at || ''
        this.is_deleted = json.is_deleted || false
        this.is_consumed = json.is_consumed || false
        this.custom_fields = json.custom_fields ? json.custom_fields.map(field => ({ ...field, id: uuid4() })) : []
        this.name = json.name || ''
        this.phone = json.phone || ''
        this.email = json.email || ''
        this.tags = json.tags || []
    }
}


/**
 * Função para transforma um voucher normalizada em um objeto completo
 * @param {Object} voucher Entity normalizada
 * @param {Object} entities Store com as entitys
 */
export function denormalizeVoucher(voucher, entities){
    voucher.adventure = entities.adventure.byId[voucher.adventure_id]
    voucher.payment = entities.payment.byId[voucher.payment_id]
    voucher.package = entities.package.byId[voucher.package_id]
    voucher.user = entities.user.byId[voucher.user_id]
    return { ...voucher }
}

/**
 * Função para normalizar de volta o voucher antes de enviar pra store
 * @param {Object} voucher Voucher não normalizada
 */
export function normalizeVoucher(voucher){
    voucher.package = undefined
    voucher.payment = undefined
    voucher.adventure = undefined
    voucher.user = undefined
    return { ...voucher }
}

export function searchVouchers(search, vouchers){
    search = search.toLowerCase()
    return vouchers.reduce((filteredVouchers,curVoucher) => {
        const voucherIncludesSearch = curVoucher.code.toLowerCase().includes(search) ||
                                      moment(curVoucher.expiration_date).format('DD/MM/YYYY').toLowerCase().includes(search) ||
                                      curVoucher.name.toLowerCase().includes(search) ||
                                      curVoucher.phone.toLowerCase().includes(search) ||
                                      curVoucher.email.toLowerCase().includes(search) ||
                                      (curVoucher.adventure && curVoucher.adventure.title.toLowerCase().includes(search)) ||
                                      (curVoucher.package && curVoucher.package.name.toLowerCase().includes(search))

        if (voucherIncludesSearch) { 
            return [ ...filteredVouchers, curVoucher]
        } else {
            return filteredVouchers
        }
    },[])
}