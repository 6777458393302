import * as CONST from './constants';

const INITIAL_STATE = {
    campaigns: [],
    selected: null,
    loadingCampaigns: false,
    loadingUpsertCampaigns: false,
    error: null,
};

const Adventures = (state = INITIAL_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case CONST.ADD_CAMPAIGN:
            return { ...state, loadingUpsertCampaigns: true };
        case CONST.ADD_CAMPAIGN_SUCCESS:
            return { ...state, loadingUpsertCampaigns: false, campaigns: [ ...state.campaigns, payload ] };
        case CONST.ADD_CAMPAIGN_ERROR:
            return { ...state, loadingUpsertCampaigns: false, error: payload };
        case CONST.UPDATE_CAMPAIGN:
            return { ...state, loadingUpsertCampaigns: true };
        case CONST.UPDATE_CAMPAIGN_SUCCESS:
            return { ...state, loadingUpsertCampaigns: false, campaigns: state.campaigns.map((e) => (e.id === payload.id ? { ...e, ...payload } : e)) }
        case CONST.UPDATE_CAMPAIGN_ERROR:
            return { ...state, loadingUpsertCampaigns: false, error: payload };
        case CONST.FETCH_CAMPAIGN:
            return { ...state, campaigns: [], loadingCampaigns: true };
        case CONST.FETCH_CAMPAIGN_SUCCESS:
            return { ...state, loadingCampaigns: false, campaigns: payload }
        case CONST.FETCH_CAMPAIGN_ERROR:
            return { ...state, loadingCampaigns: false, error: payload }
        case CONST.SELECT_CAMPAIGN:
            return { ...state, selected: payload.id }
        default:
            return state;
    }
};

export default Adventures;
