import { combineReducers } from "redux";
import {
    CREATE_RESERVE_CUSTOM_FIELD,
    UPDATE_RESERVE_CUSTOM_FIELD,
    DELETE_RESERVE_CUSTOM_FIELD
} from '../../constants'
import { LOGOUT_USER } from "../../auth/constants";
import { REFRESH_STORE } from "../../app/constants";

export function reserveCustomFieldById(state = {}, action){
    switch (action.type) {
        case CREATE_RESERVE_CUSTOM_FIELD:
            return { ...state, [action.payload.id]: action.payload }
        case UPDATE_RESERVE_CUSTOM_FIELD:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
        case DELETE_RESERVE_CUSTOM_FIELD:
            return { ...state, [action.payload.id]: undefined }
        case LOGOUT_USER:
            return {}
        case REFRESH_STORE:
            return {}
        default:
            return state
    }
}

export function reserveCustomFieldAllIds(state = [], action){
    switch (action.type) {
        case CREATE_RESERVE_CUSTOM_FIELD:
            return [ ...state, action.payload.id ]
        case DELETE_RESERVE_CUSTOM_FIELD:
            return state.reduce((prevState, curId) => curId !== action.payload.id ? [ ...prevState, curId ] : prevState, [])
        case LOGOUT_USER:
            return []
        case REFRESH_STORE:
            return []
        default:
            return state
    }
}


export default combineReducers({
    byId: reserveCustomFieldById,
    allIds: reserveCustomFieldAllIds
})