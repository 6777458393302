import React from 'react'
import { CARD_ACTION_COLORS } from '.'

export const Vertical = ({ onClick, color = CARD_ACTION_COLORS.DEFAULT, children  }) => {
    return (
        <div className="shadow p-1 mb-2 bg-white rounded" style={{ overflow: 'hidden', borderLeft:`6px solid ${color}`,  cursor: "pointer" }} onClick={onClick} >
            {children}
        </div>
    )
}
