import { CLEAR_ERROR } from './constants';

export * from './adventures/actions';
export * from './app/actions';
export * from './appMenu/actions';
export * from './auth/actions';
export * from './clients/actions';
export * from './carts/actions';
export * from './activities/actions';
export * from './entities/adventure/actions';
export * from './entities/adventureBonus/actions';
export * from './entities/category/actions';
export * from './entities/FAQ/actions';
export * from './entities/image/actions';
export * from './entities/package/actions';
export * from './entities/payment/actions';
export * from './entities/request/actions';
export * from './entities/requestBonus/actions';
export * from './entities/requestMessage/actions';
export * from './entities/requestCustomField/actions';
export * from './entities/reserve/actions';
export * from './entities/reserveCustomField/actions';
export * from './entities/scheduleRule/actions';
export * from './entities/script/actions';
export * from './entities/tag/actions';
export * from './entities/user/actions';
export * from './entities/voucher/actions';
export * from './finances/actions';
export * from './layout/actions';
export * from './reservation/actions';
export * from './vouchers/actions';
export * from './notes/actions';

export const clearError = () => ({
	type: CLEAR_ERROR
})