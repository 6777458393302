import React from 'react'
import { PlayCircle, Check } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { WIZARDS } from '.'
import { changeOpenWizard, completeWizard } from '../../redux/actions'
import { CardHorizontal, CARD_ACTION_COLORS } from '../Cards'
import { Wizard } from './Wizard'

export const ConfirmEmailWizardCard = ({ onClick, completed = false, CTA = false }) => {
    return (
        <CardHorizontal color={!!completed ? CARD_ACTION_COLORS.SUCCESS : CARD_ACTION_COLORS.DEFAULT} onClick={onClick}>
            <FormGroup className="position-relative">
                <Label>Confirmar email da empresa</Label>
                <p>Precisamos garantir o recebimento das notificações na sua caixa de emails.</p>
                {!!completed && <Check style={{ position: 'absolute', top: 0, right: 0 }} color={CARD_ACTION_COLORS.SUCCESS} />}
            </FormGroup>
            <FormGroup>
                <Button color="primary" outline={!CTA}><PlayCircle /> Iniciar</Button>
            </FormGroup>
        </CardHorizontal>
    )
}


export const ConfirmEmailWizard = ({}) => { 
    const { openWizard } = useSelector(state => state.app)
    const dispatch = useDispatch()

    return (
        <Wizard title="Confirmar email da empresa" open={openWizard === WIZARDS.CONFIRM_EMAL} onClose={() => dispatch(changeOpenWizard())}>
            <Row>
                <Col>
                    Conteudo
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={() => dispatch(completeWizard(WIZARDS.CONFIRM_EMAL))} outline><Check /> Concluir</Button>
                </Col>
            </Row>
        </Wizard>
    )
}