import {
    CREATE_RESERVE_CUSTOM_FIELD,
    UPDATE_RESERVE_CUSTOM_FIELD,
    DELETE_RESERVE_CUSTOM_FIELD
} from '../../constants'
import { ReserveCustomField } from '../../../models'

export const createReserveCustomField = (data) => ({
    type: CREATE_RESERVE_CUSTOM_FIELD,
    payload: new ReserveCustomField(data)
})

export const updateReserveCustomField = (data) => ({
    type: UPDATE_RESERVE_CUSTOM_FIELD,
    payload: data
})

export const deletereserveCustomField = id => ({
    type: DELETE_RESERVE_CUSTOM_FIELD,
    payload: { id }
})